import { createApp } from 'vue';
import App from './App';
import { defineCustomElements } from '@stripe-elements/stripe-elements/loader';
import { Capacitor } from '@capacitor/core';
// import './assets/tailwind.css';
import './index.scss';
// import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import VueTheMask from 'vue-the-mask';
import { i18n } from './i18n';

// Define stripe for web
if (!Capacitor.isNativePlatform()) {
  defineCustomElements();
}

import {
  GButton,
  GGoBack,
  GInput,
  GInputGroup,
  GLoadBox,
  GSlider,
  GScrollBar,
  GInputArea,
  GSwitch,
} from '@/components/ui/global';

import { apolloProvider } from './apollo';
import { init as initRemoteConfig } from './remoteConfig';
import sentryInit from './sentry';
import initUrlHandler from './urlHandler';
import FontAwesomeIcon from './icons';
// import { initNotifications } from './notifications';
import { preload } from './audio.js';
import router from './router';
import store from './store';

const app = createApp(App);

sentryInit(app, router);
initUrlHandler(router);
initRemoteConfig();
preload();

store.dispatch('setDeviceLang');

// https://github.com/vuejs/vue-next/blob/master/packages/runtime-core/src/componentOptions.ts#L853-L857
app.config.unwrapInjectedRef = true;

app
  .use(store)
  .use(router)
  .use(apolloProvider)
  .use(VueTheMask)
  .use(i18n)
  .component('FaIcon', FontAwesomeIcon)
  .component('GBtn', GButton)
  .component('GGoBack', GGoBack)
  .component('GInput', GInput)
  .component('GInputGroup', GInputGroup)
  .component('GLoadBox', GLoadBox)
  .component('GSlider', GSlider)
  .component('GScrollBar', GScrollBar)
  .component('GInputArea', GInputArea)
  .component('GSwitch', GSwitch)
  .mount('#app');
