<template>
  <div class="h-full w-full flex flex-col justify-stretch bg-gray-50">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true" ghost>
      <template #default
        >{{ $t('ride.your-ride') }} {{ reservation.id ? '#' + reservation.id : '' }}</template
      >
    </TopNav>
    <div class="grow shrink overflow-hidden">
      <GScrollBar class="bg-transparent">
        <div class="flex flex-col justify-between p-4">
          <!-- <RideStatus class="my-2 py-12 shadow rounded-lg bg-white" />
          <RidePause class="p-4 w-full shadow rounded-lg bg-white my-2" />
          <RidePredictedCost class="p-4 w-full shadow rounded-lg bg-white my-2" /> -->
          <RideActive
            :showCounters="false"
            :showRate="false"
            :reservation="reservation"
            class="w-full shadow rounded-lg bg-white my-2"
          />
          <RideRate class="my-2 py-4 shadow rounded-lg bg-white w-full" />
          <div class="w-full shadow rounded-lg bg-white">
            <RideCounters class="p-4" />
            <RideSummary />
          </div>
          <OfferCard :offer="offer" class="my-2 py-4 shadow rounded-lg bg-white w-full" />
          <RideMap class="shadow rounded-lg bg-white my-2" />
          <RideHistory
            v-if="
              reservation.historyOfStatusChanges?.length ||
              reservation.historyOfStatusChanges?.length
            "
            class="my-2 p-4 shadow rounded-lg bg-white w-full"
          />
          <QuestionButton />
        </div>
      </GScrollBar>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/ui/partials/TopNav';
import gql from 'graphql-tag';
import RideSummary from '@/components/ride/RideSummary';
// import RideStatus from '@/components/ride/RideStatus';
import RideCounters from '@/components/ride/RideCounters';
import { computed } from 'vue';
import RideMap from '@/components/ride/RideMap';
import QuestionButton from '@/components/QuestionButton';
import RideRate from '@/components/ride/RideRate';
// import RidePause from '@/components/ride/RidePause';
// import RidePredictedCost from '@/components/ride/RidePredictedCost';
import RideActive from '@/components/ride/RideActive';
import OfferCard from '@/components/OfferCard';
import RideHistory from '../components/ride/RideHistory';

export default {
  data() {
    return {
      reservation: {},
    };
  },
  provide() {
    return {
      reservation: computed(() => this.reservation),
    };
  },
  computed: {
    offer() {
      return (
        this.reservation.activatedOffer?.offer || {
          id: null,
          name: 'standard',
          startCost: this.reservation.costPerStart,
          costPerMinute: this.reservation.costPerMinuteInCents,
          costPer1km: this.reservation.costPer1000MetersInCents,
        }
      );
    },
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    reservation: {
      query: gql`
        query scooterReservationOverview($id: Int!) {
          reservation: scooterReservation(id: $id) {
            id
            startDate
            endDate
            status
            totalCostInCents
            costPerMinuteInCents
            costPerStart
            costPer1000MetersInCents
            totalDistanceOfReservationInMeters
            totalReservationTimeInSeconds
            scooterParamsAtEndOfReservation
            scooterParamsAtStartOfReservation
            historyOfReservationCosts
            historyOfStatusChanges
            statusErrorCountScooterAtBeginningRemainsInStand
            reservationTrackHistoryJson
            predictedTotalCostInCents
            userRating
            activatedOffer {
              id
              offer {
                id
                name
                description
                dailyRepeatingEndTimeForThisOffer
                dailyRepeatingStartTimeForThisOffer
                weeklyRepeatingEndDateForThisOffer
                isActive
                isEnabled
                costPer1km
                costPerMinute
                costPerMinuteWhilePausedInCents
                startCost
                refundableDepositAmount
              }
            }
            scooter {
              id
              unique_id_for_qr
              flespi_id
            }
            startingScooterParkingSlot {
              scooterParkingStation {
                address
                lat
                lng
              }
            }
            endingScooterParkingSlot {
              scooterParkingStation {
                address
                lat
                lng
              }
            }
          }
        }
      `,
      skip: true,
      variables() {
        return {
          id: Number(this.$route.params.id),
        };
      },
      pollInterval: 1 * 1000,
    },
  },

  components: {
    TopNav,
    RideSummary,
    // RideStatus,
    RideCounters,
    RideMap,
    QuestionButton,
    RideActive,
    RideRate,
    OfferCard,
    RideHistory,
  },
  mounted() {
    this.$apollo.queries.reservation.skip = false;
  },
};
</script>
