<template>
  <div
    class="relative cursor-pointer"
    @click="$emit('update:modelValue', sortedIcons[1].value)"
    :style="{ 'margin-left': `${Math.sqrt(icons.length) * 3}px` }"
  >
    <div
      v-for="(icon, idx) in sortedIcons"
      :key="icon.value"
      class="absolute rounded-full flex justify-center items-center h-full w-full keep-square right-0 bottom-0"
      :class="idx === 0 ? 'border-primary border-2 bg-white text-primary' : 'bg-primary text-white'"
      :style="{
        'z-index': 100 - idx,
        transform: `scale(${1 - idx / 8}) translate(${-Math.sqrt(idx) * 8}px, ${-idx * 6}px)`,
      }"
    >
      <FaIcon :icon="icon.icon" class="w-full h-full" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: null,
    },
    icons: {
      type: Array,
      default: () => [
        { icon: 'plus', value: 1 },
        { icon: 'minus', value: -1 },
      ],
    },
  },
  methods: {
    arrayRotate(array, count) {
      const arr = array.slice();
      count -= arr.length * Math.floor(count / arr.length);
      arr.push.apply(arr, arr.splice(0, count));
      return arr;
    },
  },
  computed: {
    sortedIcons() {
      const idx = this.icons.findIndex(e => e.value === this.modelValue);
      return this.arrayRotate(this.icons, idx).slice(0, 2);
    },
  },
  beforeMount() {
    if (!this.modelValue) {
      this.$emit('update:modelValue', this.icons[0].value);
    }
  },
};
</script>
