<template>
  <div
    v-if="open"
    class="top-0 left-0 h-screen w-screen rounded-lg z-40 flex justify-center items-center text-primary py-12 px-4"
    :class="[
      solid ? 'bg-white' : ['bg-primary shadow-md', cover ? 'bg-opacity-60' : 'bg-opacity-10'],
      fixed ? 'fixed' : 'absolute',
    ]"
    @click.self="$emit('close')"
  >
    <div
      class="flex justify-center items-center flex-col relative bg-white rounded-lg overflow-hidden"
      :class="{ 'shadow-md max-w-[85vw] min-w-[50vw] w-fit': !solid, ' w-full h-full': solid }"
    >
      <slot></slot>
      <button v-if="closable" class="absolute top-0 right-0 p-2" @click="$emit('close')">
        <FaIcon icon="xmark" class="block h-4 w-4" :class="textClass" fixed-width />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    solid: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    textClass: {
      type: String,
      default: '',
    },
  },
};
</script>
