<template>
  <div class="h-full w-full bg-gray-50 flex flex-col justify-stretch overflow-hidden">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <p class="text-primary text-lg">Użytkownik #{{ user.id }}</p>
    </TopNav>
    <div class="h-full overflow-hidden relative flex flex-col p-4">
      <UserOverview :user="user" class="" />
      <TabsView
        class="h-full mt-4"
        :tabs="[
          {
            title: 'Przejazdy',
            selector: 'przejazdy',
          },
          {
            title: 'Portfel',
            selector: 'portfel',
          },
        ]"
      >
        <template #przejazdy>
          <div class="h-full flex">
            <RidesList :user-id="user.id" admin />
          </div>
        </template>
        <template #portfel>
          <div class="h-full p-2">
            <GScrollBar>
              <div class="flex flex-col items-center overflow-hidden">
                <BalanceChart
                  v-if="user.userBalanceHistoryRecords.records"
                  :records="user.userBalanceHistoryRecords || []"
                  class="w-full"
                />
                <RechargeUserAccount :user-id="Number(user.id)" class="my-2" />
                <BalanceList :records="user.userBalanceHistoryRecords || []" class="w-full" />
              </div>
            </GScrollBar>
          </div>
        </template>
      </TabsView>
    </div>
  </div>
</template>
<script>
import TopNav from '@/components/ui/partials/TopNav';
import gql from 'graphql-tag';
import UserOverview from '@/components/UserOverview';
import RidesList from '@/components/ride/RidesList';
import TabsView from '@/components/ui/TabsView';
import BalanceChart from '@/components/BalanceChart';
import BalanceList from '@/components/BalanceList';
import RechargeUserAccount from '@/components/RechargeUserAccount';

export default {
  name: 'UserPage',
  components: {
    TopNav,
    UserOverview,
    RidesList,
    TabsView,
    BalanceChart,
    BalanceList,
    RechargeUserAccount,
  },
  data() {
    return {
      user: {},
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    user: {
      query: gql`
        query user($email: String!) {
          user(email: $email) {
            id
            name
            email
            accountBalanceInCents
            socialAccounts {
              providerType
            }
            role
            userBalanceHistoryRecords {
              type
              source
              amountInCents
              additional_info
              createdAt
              accountBalanceAfterInCents
              title
            }
          }
        }
      `,
      variables() {
        return {
          email: this.$route.params.email,
        };
      },
      pollInterval: 10 * 1000,
    },
  },
};
</script>
