import store from '@/store';

window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

export default async function authGuard(to, from, next) {
  const IsItABackButton = window.popStateDetected;
  window.popStateDetected = false;
  if (!from.matched.length) {
    store.commit('ui/setTransition', 'fade');
  } else if (IsItABackButton) {
    store.commit('ui/setTransition', 'slide-right');
  } else {
    store.commit('ui/setTransition', 'slide-left');
  }
  next();
}
