<template>
  <div v-if="cost" class="text-sm text-center text-gray-600">
    <p class="text-md text-primary">{{ cost / 100 }}</p>
    <p class="text-xs text-gray-600">{{ $t('ride.current-cost') }}</p>
    <p v-if="!hideInfo" class="mt-2 text-xs text-gray-400">
      {{ $t('ride.cost-is-predicted') }}
    </p>
  </div>
</template>
<script>
import { getState } from '@/constants/rideStatuses';

export default {
  computed: {
    cost() {
      return (
        getState(this.reservation.status) === 'active' && this.reservation.predictedTotalCostInCents
      );
    },
  },
  inject: ['reservation'],
  props: {
    hideInfo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
