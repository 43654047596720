import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';
import store from '@/store';
import router from '@/router';
import refreshToken from './refreshToken';
let refreshing = false;

const redirectToLogin = () => {
  if (router.currentRoute.value.meta.requiresAuth) {
    store.commit('clearTokens');
    router.push('/login');
  }
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.log('Api error', err);
      switch (err.message) {
        case 'Unauthorized': {
          const getRefreshToken = store.getters['getRefreshToken'];
          if (getRefreshToken) {
            if (!refreshing) {
              refreshing = true;
              console.log('refreshing token');
              return fromPromise(
                refreshToken(getRefreshToken).catch(error => {
                  // Handle token refresh errors e.g clear stored tokens, redirect to login
                  console.log('error while refreshing token, redirecting to login');
                  console.log(error);
                  refreshing = false;
                  redirectToLogin();
                  return;
                })
              )
                .filter(value => Boolean(value))
                .flatMap(accessToken => {
                  const oldHeaders = operation.getContext().headers;
                  // modify the operation context with a new token
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${accessToken}`,
                    },
                  });
                  refreshing = false;
                  // retry the request, returning the new observable
                  return forward(operation);
                });
            } else {
              console.log('token already being refreshing');
            }
          } else {
            console.log('no refresh token, redirecting to login');
            redirectToLogin();
          }
        }
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export default errorLink;
