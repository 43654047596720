<template>
  <Component
    :is="hasChildren ? 'div' : 'button'"
    v-if="
      !(item.needRole && item.needRole.length) ||
      (item.needRole && item.needRole.includes(userRole))
    "
    class="block grow-0 cursor-pointer py-2 px-3 text-lg text-left"
    :class="[
      item.alert ? 'text-red-500' : 'text-gray-700',
      // item.light ? 'bg-gray-200 text-gray-400' : 'bg-gray-400',
    ]"
    @click="item.callback"
  >
    <div @click="open = !open">
      <FaIcon :icon="item.icon" class="mr-2" />
      <span class="capitalize">{{ item.text }}</span>
    </div>
    <div v-if="hasChildren" v-show="open">
      <MenuItem v-for="(childItem, idx) in item.children" :item="childItem" :key="idx" />
    </div>
  </Component>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userRole: 'getUserRole',
    }),
    hasChildren() {
      return this.item.children && this.item.children.length;
    },
  },
};
</script>
