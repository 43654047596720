<template lang="">
  <div
    class="border border-gray-200 flex justify-between rounded-full overflow-hidden shadow-sm"
    :class="{ 'animate-shake-once': shaked }"
    :key="shaked"
  >
    <div v-if="label" class="px-2 font-thin text-sm bg-gray-50 shadow text-gray-500">
      <span class="">{{ label }}:</span>
    </div>
    <input
      :type="type"
      v-bind="$attrs"
      @change="updated"
      :value="modelValue"
      class="bg-transparent px-4 border-none border-0 shadow-none appearance-none outline-0 text-gray-600 flex-grow"
      :disabled="disabled"
      :autocomplete="autocomplete"
      ref="input"
    />
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shaked: 0,
      alert: '',
    };
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    updated(val) {
      this.$emit('update:modelValue', val.target.value);
    },
    shake(msg) {
      this.alert = msg;
      this.shaked++;
    },
  },
  props: {
    modelValue: {
      type: null,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: 'text',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
