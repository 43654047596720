<template>
  <div class="h-full w-full flex flex-col justify-stretch bg-gray-50">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <template #default>{{ $t('menu.settings') }}</template>
    </TopNav>
    <div class="grow shrink overflow-hidden">
      <GScrollBar
        class="h-full w-full overflow-hidden relative bg-transparent"
        :options="{ suppressScrollX: true }"
      >
        <div class="flex flex-col justify-between p-4">
          <div class="mb-4">
            <div class="flex justify-between my-2">
              <span>{{ $t('settings.language') }}</span>
              <select @change="setLanguage" :value="lang">
                <option
                  v-for="locale in $i18n.availableLocales"
                  :key="`locale-${locale}`"
                  :value="locale"
                >
                  {{ locale }}
                </option>
              </select>
            </div>
          </div>
          <button
            type="button"
            class="relative shadow rounded-lg my-2 p-4 text-gray-700 text-center bg-gray-100"
            @click="$router.push('/about')"
          >
            <span> <FaIcon icon="info-circle" /> legal info</span>
          </button>
          <button
            v-if="['admin', 'superadmin'].includes(userRole)"
            type="button"
            class="relative shadow rounded-lg my-2 p-4 text-gray-700 text-center bg-gray-100"
            @click="$router.push('/settings/test')"
          >
            <span> <FaIcon icon="info-circle" /> strona testowa admin</span>
          </button>
        </div>
      </GScrollBar>
    </div>
  </div>
</template>
<script>
import TopNav from '@/components/ui/partials/TopNav';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'SettingsPage',
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ userRole: 'getUserRole', lang: 'getLang' }),
  },
  methods: {
    ...mapMutations({ setLang: 'setLang' }),
    alert(text) {
      alert(text);
    },
    setLanguage(e) {
      this.setLang(e.target.value);
    },
  },
  components: {
    TopNav,
  },
};
</script>
