module.exports = {
  purge: [
    './index.html',
    './src/**/*.{vue,js,ts,jsx,tsx}',
    './node_modules/tw-elements/dist/js/**/*.js',
  ],
  darkMode: false,
  important: true,
  // mode: 'jit',
  theme: {
    extend: {
      fontSize: {
        '2xs': '.65rem',
      },
      colors: {
        primary: '#06b6d4',
        medium: '#449CE6',
        secondary: '#3b82f6',
        yellow: '#FABC44',
        alert: '#FA0000',
      },
      fontFamily: {
        // sans: ['RobotoMono', 'Helvetica', 'Arial', 'sans-serif'],
        sans: ['Ubuntu'],
      },
      transitionProperty: {
        'max-size': 'max-height, max-width',
        size: 'height, width',
      },
      animation: {
        'bounce-once': 'bounce .5s',
        'bounce-right-once': 'bounce-right .9s',
        'ping-once': 'ping 1s cubic-bezier(0, 0, 0.2, 1)',
        'shake-once': 'shake .3s ease-in-out ',
      },
      boxShadow: {
        upper: '0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 -4px 6px -4px rgb(0 0 0 / 0.1)',
      },
      keyframes: {
        shake: {
          '0%, 100%': { transform: 'translateX(0)' },
          '25%': { transform: 'translateX(-10px)' },
          '75%': { transform: 'translateX(10px)' },
        },
      },
    },
    screens: {
      sm: '330px',
      md: '500px',
      lg: '1230px',
    },
  },
  variants: {
    extend: {
      // backgroundColor: ['disabled'],
    },
  },
  plugins: [require('tw-elements/dist/plugin')],
};
