<template>
  <button
    type="button"
    :class="[
      'text-gray-700 text-center ',
      text ? 'shadow bg-gray-100 my-2 p-4 rounded-lg' : 'inline rounded-full',
    ]"
    @click="$router.push('/help/center')"
  >
    <span>
      <FaIcon :icon="['far', 'circle-question']" />
      <span v-if="text" class="ml-2">{{ $t('help.need-help') }}</span>
    </span>
  </button>
</template>
<script>
export default {
  props: {
    text: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
