import { FirebaseRemoteConfig } from '@joinflux/firebase-remote-config';
import { Capacitor } from '@capacitor/core';
import config from '@config';

let initialized = false;

const init = async () => {
  if (Capacitor.isNativePlatform() && !initialized) {
    await FirebaseRemoteConfig.initialize({
      minimumFetchIntervalInSeconds: config.firebase.rcInterval,
    });

    await FirebaseRemoteConfig.fetchAndActivate();
    initialized = true;
  }
};

const getString = Capacitor.isNativePlatform() ? FirebaseRemoteConfig.getString : () => {};
const getBoolean = Capacitor.isNativePlatform() ? FirebaseRemoteConfig.getBoolean : () => {};

export { init, getString, getBoolean, initialized };
