<template>
  <component
    :is="submit && !loading && !loadingOverlay ? 'input' : href ? 'a' : 'button'"
    :type="submit ? 'submit' : 'button'"
    :href="href"
    data-mdb-ripple="true"
    data-mdb-ripple-color="primary"
    class="p-3 items-center inline-flex disabled:grayscale disabled:opacity-30 disabled:cursor-not-allowed"
    :class="[
      {
        'bg-primary text-white': !ghost,
        'rounded-lg': !circle && !sharp,
        'rounded-full': circle,
        'from-primary to-secondary': gradientTo && !ghost,
        'text-sm p-2': small,
      },
      `bg-gradient-to-${gradientTo} `,
    ]"
    :disabled="loading || loadingOverlay"
  >
    <div class="relative">
      <div class="inline" :class="{ invisible: loadingOverlay }">
        <slot></slot>
      </div>
      <div
        class="inline-flex justify-center items-center align-text-bottom"
        :class="{
          hidden: !(loadingOverlay || loading),
          'absolute top-0 left-0 h-full w-full': loadingOverlay,
          'ml-1': loading,
        }"
      >
        <FaIcon icon="circle-notch" class="animate-spin" :class="{ 'h-8 w-8': large }" />
      </div>
    </div>
  </component>
</template>
<script>
export default {
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingOverlay: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    sharp: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    gradientTo: {
      type: String,
      default: 'b',
    },
    href: {
      type: String,
      default: '',
    },
  },
};
</script>
