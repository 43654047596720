<template>
  <div class="h-full w-full flex flex-col justify-stretch bg-gray-50">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      {{ $t('wallet.transactions-history') }}
    </TopNav>
    <GScrollbar>
      <div class="p-4">
        <div
          v-if="records.length"
          class="flex justify-center items-center flex-col text-center my-2 p-6 shadow rounded-lg bg-white"
        >
          <BalanceChart :records="recordsFiltered" class="w-full h-full" />
        </div>
        <div
          v-if="records.length"
          class="flex justify-center items-center flex-col text-center my-2 p-6 shadow rounded-lg bg-white"
        >
          <BalanceList class="w-full" :records="recordsFiltered" />
        </div>
      </div>
    </GScrollbar>
  </div>
</template>
<script>
import BalanceChart from '@/components/BalanceChart';
import BalanceList from '@/components/BalanceList';
import gql from 'graphql-tag';
import TopNav from '@/components/ui/partials/TopNav';

export default {
  data() {
    return {
      records: [],
    };
  },
  apollo: {
    records: {
      query: gql`
        query {
          whoAmI {
            user {
              userBalanceHistoryRecords {
                type
                source
                amountInCents
                additional_info
                createdAt
                accountBalanceAfterInCents
                title
              }
            }
          }
        }
      `,
      update: data => data.whoAmI.user.userBalanceHistoryRecords,
      pollInterval: 5 * 1000,
    },
  },
  computed: {
    recordsFiltered() {
      return this.records.filter(e => e.amountInCents);
    },
  },
  components: {
    BalanceChart,
    BalanceList,
    TopNav,
  },
};
</script>
<style lang=""></style>
