import { Stripe } from '@capacitor-community/stripe';
import { apolloClient } from '@/apollo';
import gql from 'graphql-tag';
import config from '@config';
import { t } from '@/i18n';

let stripeInitialized = false;

async function initialize() {
  if (!process.env.VUE_APP_STRIPE_PUBLIC_KEY) {
    console.log('Stripe public key not set');
    return false;
  }
  Stripe.initialize({
    publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
  });
  stripeInitialized = true;
  return true;
}

async function getAppleAndGooglePayEnables() {
  const obj = {};
  let isApplePayAvailable = true;
  let isGooglePayAvailable = true;
  await Stripe.isApplePayAvailable().catch(() => {
    isApplePayAvailable = false;
  });
  await Stripe.isGooglePayAvailable().catch(() => {
    isGooglePayAvailable = false;
  });
  if (
    isApplePayAvailable &&
    config.stripe?.applePay?.enabled &&
    config.stripe?.applePay?.merchantId
  ) {
    obj.enableApplePay = true;
    obj.applePayMerchantId = config.stripe.applePay.merchantId;
  }
  if (isGooglePayAvailable && config.stripe?.googlePay?.enabled) {
    obj.enableGooglePay = true;
    obj.GooglePayIsTesting = config.stripe.googlePay.isTesting || false;
  }
  return obj;
}

// async function getStripeCustomerId() {
//   const response = await apolloClient.query({
//     query: gql`
//       query {
//         whoAmI {
//           user {
//             stripeCustomer {
//               stripe_id
//             }
//           }
//         }
//       }
//     `,
//   });
//   return response.data.whoAmI.user.stripeCustomer.stripe_id;
// }

async function getEphemeralKeySecret() {
  const response = await apolloClient.query({
    query: gql`
      query {
        getStripeEphemeralKey
      }
    `,
  });
  return response.data.getStripeEphemeralKey.secret;
}
async function createStripeSetupIntent() {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation {
        createStripeSetupIntentAndReturnSecret
      }
    `,
  });
  return response.data.createStripeSetupIntentAndReturnSecret;
}

async function createStripePaymentIntent(amountInCents) {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation {
        createStripePaymentIntentAndReturnSecret(amountInCents: ${amountInCents}) 
      }
    `,
    variables: {
      amountInCents,
    },
  });
  return response.data.createStripePaymentIntentAndReturnSecret;
}

async function getStripeObject() {
  const customerEphemeralKeySecret = await getEphemeralKeySecret();
  const stripeObj = {
    customerEphemeralKeySecret,
    countryCode: 'PL',
    style: 'alwaysLight',
    merchantDisplayName: config.product.appName,
    ...(await getAppleAndGooglePayEnables()),
  };
  return stripeObj;
}

async function createAndPresentFlow(stripeObj) {
  // const listener = Stripe.addListener(PaymentSheetEventsEnum.Completed,
  console.log('creating payment flow', stripeObj);
  const paymentFlow = await Stripe.createPaymentFlow(stripeObj);
  console.log('payment flow created', paymentFlow);
  const presentResult = await Stripe.presentPaymentFlow();
  console.log('presented payment flow', presentResult);
  const confirmResult = await Stripe.confirmPaymentFlow();
  console.log('confirm result', confirmResult);
  if (confirmResult.paymentResult === 'paymentFlowCompleted') {
    return true;
  } else {
    alert(t('wallet.payment-failed'));
    return false;
  }
}

async function addPaymentMethod() {
  try {
    console.log('adding payment method');
    if (!stripeInitialized) {
      if (!(await initialize())) return false;
    }
    const setupIntentClientSecret = await createStripeSetupIntent();
    const stripeObj = { setupIntentClientSecret, ...(await getStripeObject()) };
    return await createAndPresentFlow(stripeObj);
  } catch (err) {
    if (err.message) {
      alert(err.message);
    } else {
      console.error('unknown stripe error', err);
    }
  }
  return false;
}

async function makePayment(amountInCents) {
  try {
    console.log('making payment');
    if (!stripeInitialized) {
      if (!(await initialize())) return false;
    }
    // const customerId = await getStripeCustomerId();

    const paymentIntentClientSecret = await createStripePaymentIntent(amountInCents);
    const stripeObj = {
      paymentIntentClientSecret,
      // customerId,
      ...(await getStripeObject()),
      returnURL: `${config.product.bundleId}.local://stripe-callback`,
    };
    return await createAndPresentFlow(stripeObj);
  } catch (err) {
    if (err.message) {
      alert(err.message);
    } else {
      console.error('unknown stripe error', err);
    }
  }
  return false;
}

export { addPaymentMethod, makePayment };
