import * as SentryVue from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/capacitor';
import config from '@config';

function sentryInit(app, router) {
  if (config.sentry?.enable) {
    Sentry.init(
      {
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: `${config.product.environment}-${config.product.version}-${config.product.versionNumber}`,
        environment: config.product.environment,
      },
      SentryVue.init({
        app,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', 'kickfleet.com', /^\//],
          }),
        ],
        tracesSampleRate: config.sentry.sampleRate ? Number(config.sentry.sampleRate) : 0.2,
        logErrors: config.sentry.logErrors,
      })
    );
  }
}
export default sentryInit;
