<template>
  <div v-if="hasFlespi && (isPausable || isResumable || isReturnable)">
    <div class="flex justify-center flex-wrap">
      <LabeledButton
        v-if="isPausable"
        class="mx-2"
        :icon="['far', 'circle-stop']"
        :text="$t('ride.stop')"
        @clicked="pauseOpen = true"
        :loadingOverlay="loading"
        :small="small"
      />
      <LabeledButton
        v-if="isResumable"
        class="mx-2"
        icon="play"
        :text="$t('ride.resume')"
        @clicked="resume"
        :loadingOverlay="loading"
        :small="small"
      />
    </div>
    <SimpleModal v-if="pauseOpen" open @close="pauseOpen = false" fixed>
      <div class="p-4 text-center">
        <p class="text-gray-700 text-lg mb-1">{{ $t('ride.ask-pause') }}</p>
        <p class="text-gray-500 text-md">
          {{ $t('ride.we-can-pause') }}
        </p>
        <div class="flex justify-around mt-4">
          <GBtn
            class="bg-white border border-red-500 text-red-500"
            small
            ghost
            @click="pauseOpen = false"
            ><FaIcon icon="xmark" /> {{ $t('global.cancel') }}</GBtn
          >
          <GBtn @click="pause" small>{{ $t('ride.ok-pause') }}</GBtn>
        </div>
        <div class="mt-4 text-gray-400 underline" @click="showEnd()">
          {{ $t('ride.how-to-end') }}
        </div>
      </div>
    </SimpleModal>
    <SimpleModal v-if="endOpen" open @close="endOpen = false" fixed>
      <RideEnd class="p-4" @ended="endOpen = false" />
    </SimpleModal>
  </div>
</template>
<script>
import { isPausable, isResumable, isReturnable } from '@/constants/rideStatuses';
import LabeledButton from '@/components/ui/LabeledButton';
import SimpleModal from '../ui/SimpleModal';
import RideEnd from './RideEnd';
import gql from 'graphql-tag';

export default {
  inject: ['reservation'],
  data() {
    return {
      pauseOpen: false,
      endOpen: false,
      loading: false,
    };
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.reservation.status;
    },
    hasFlespi() {
      return this.reservation && this.reservation.scooter && this.reservation.scooter.flespi_id;
    },
    isPausable() {
      return isPausable(this.status);
    },
    isResumable() {
      return isResumable(this.status);
    },
    isReturnable() {
      return isReturnable(this.status);
    },
  },
  methods: {
    showEnd() {
      this.pauseOpen = false;
      this.endOpen = true;
    },
    mutatePause(command) {
      console.log('pausing ' + command);
      this.loading = true;
      (this.pauseOpen = false),
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: Int!) {
                ${command}(input: { reservationId: $id }) {
                  id
                }
              }
            `,
            variables: {
              id: Number(this.reservation.id),
            },
          })
          .catch(error => {
            setTimeout(() => {
              alert(error);
              this.loading = false;
            }, 1500);
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
            }, 1500);
          });
    },
    pause() {
      return this.mutatePause('pauseScooterReservation');
    },
    resume() {
      return this.mutatePause('resumeScooterReservation');
    },
  },
  components: { LabeledButton, SimpleModal, RideEnd },
};
</script>
