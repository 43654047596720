<template>
  <div class="text-center">
    <div class="mt-2 px-2 grid gap-4">
      <template v-for="(reservation, idx) in userActiveReservations" :key="'ride-' + idx">
        <RideActive :reservation="reservation" class="col-span-2 rounded-lg shadow text-sm" />
      </template>
      <template v-for="(reservation, idx) in userEndedReservations" :key="'ride-' + idx">
        <RideActive :reservation="reservation" class="col-span-2 rounded-lg shadow text-sm" />
      </template>
    </div>
    <!-- <p v-if="userActiveReservations.length" class="mt-2 text-xs text-gray-400 w-full">
      {{ $t('ride.cost-is-predicted') }}
    </p> -->
  </div>
</template>
<script>
import RideActive from './RideActive';
export default {
  inject: ['userActiveReservations', 'userEndedReservations'],
  components: { RideActive },
};
</script>
