<template>
  <div :style="{ 'margin-top': safeArea.top }">
    <div
      class="min-h-10 overflow-hidden relative shadow-lg z-10"
      :class="{ 'rounded-lg shadow-md m-4 mt-0': !full, 'px-4 py-1': full }"
    >
      <div
        class="text-center text-gray-500 relative py-3 grid grid-cols-[minmax(0,1fr)_minmax(0,_10fr)_minmax(0,1fr)] items-center"
        :class="{ 'shadow-md': !!this.$slots.bottom, 'bg-white px-3': !full }"
      >
        <div class="flex justify-center items-center h-full">
          <button
            @click="goBack ? $router.back() : $emit('clicked')"
            class="h-8 w-8 flex shrink-0 justify-center items-center rounded-full relative"
            :class="{
              'bg-gray-100 shadow': !goBack,
            }"
          >
            <FaIcon
              :icon="goBack ? 'arrow-left' : 'user'"
              class="relative block w-4 h-4"
              :class="{ 'text-gray-500 mb-[0.1rem]': !goBack }"
              fixed-width
            />
            <span
              v-if="!goBack && userActiveReservations.length"
              class="absolute -top-2 -right-2 bg-primary text-white rounded-full w-4 h-4 text-xs transform-[translate(100%,100%)]"
              >{{ userActiveReservations.length }}</span
            >
          </button>
        </div>
        <div>
          <slot></slot>
        </div>
        <div class="flex justify-end items-center h-full">
          <slot name="right"></slot>
        </div>
      </div>
      <div v-if="!!this.$slots.bottom" class="bg-gray-100 p-3">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['safeArea', 'userActiveReservations'],
  props: {
    goBack: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
};
</script>
