<template>
  <div
    class="relative rounded-full flex justify-center items-center w-fit h-fit keep-square overflow-hidden"
  >
    <div class="bg-gray-300">
      <FaIcon :icon="['far', 'user']" class="block h-10 w-10 m-6 text-primary" fixed-width />
    </div>
    <div v-if="hasAccount('spaceos')" class="w-full h-[27%] absolute bottom-0 bg-yellow pt-1 px-5">
      <img src="/assets/img/officeme.png" class="w-full" alt="" />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';

export default {
  data() {
    return {
      socialAccounts: [],
    };
  },
  methods: {
    hasAccount(string) {
      if (!string) {
        return !!this.socialAccounts.length;
      }
      return !!this.socialAccounts.filter(e => e.providerType === string).length;
    },
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    socialAccounts: {
      query: gql`
        query {
          whoAmI {
            user {
              socialAccounts {
                id
                providerType
                providerId
              }
            }
          }
        }
      `,
      update: data => {
        return data.whoAmI.user.socialAccounts;
      },
    },
  },
};
</script>
