<template>
  <div class="grow shrink overflow-hidden z-0">
    <GScrollBar @reach-end="loadMore">
      <div
        v-if="reservations.data && !reservations.data.length"
        class="text-center my-8 flex justify-center"
      >
        <div class="p-4 border border-primary rounded">
          <p class="text-primary">{{ $t('ride.no-rides') }}</p>
          <router-link to="/stations" class="text-gray-500 text-sm underline">{{
            $t('ride.no-rides-go-ahead')
          }}</router-link>
        </div>
      </div>
      <div class="py-4">
        <div
          v-for="(reservation, idx) in reservations.data"
          class="border-b cursor-pointer p-4"
          @click="$router.push(`/ride/${reservation.id}`)"
          :key="idx"
        >
          <RideOverviewContent :reservation="reservation" :admin="admin" />
        </div>
        <div
          v-if="$apollo.queries.reservations.loading"
          class="p-4 w-full flex justify-stretch items-center pr-9"
        >
          <GLoadBox class="h-12 w-10 rounded" />
          <div class="ml-4 grow shrink">
            <div class="mt-2 flex justify-between">
              <GLoadBox class="h-5 w-32 rounded" />
              <GLoadBox class="h-5 w-16 rounded" />
            </div>
            <div class="mt-2 flex justify-between">
              <GLoadBox class="h-4 w-20 rounded" />
              <GLoadBox class="h-4 w-20 rounded" />
            </div>
          </div>
        </div>
        <div v-else class="h-20 w-full"></div>
      </div>
    </GScrollBar>
  </div>
</template>
<script>
import RideOverviewContent from '@/components/ride/RideOverview/RideOverviewContent';
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';

export default {
  data() {
    return {
      reservations: [],
      manualLoading: false,
      take: 8,
    };
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({ currentUserId: 'getUserId' }),
    loading() {
      return this.$apollo.queries.reservations.loading || this.manualLoading;
    },
  },
  components: {
    RideOverviewContent,
  },
  methods: {
    async reload() {
      console.log('reloading');
      await this.$apollo.queries.reservations.refetch();

      for (let i = 0; i < this.reservations.length; i++) {
        await this.$refs[`rides-${i}`][0].reload();
      }
    },
    loadMore() {
      console.log('reached');
      if (
        !this.$apollo.queries.reservations.loading &&
        this.reservations.data.length < this.reservations.count
      ) {
        this.$apollo.queries.reservations.fetchMore({
          variables: {
            skip: this.reservations.data.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            console.log('previousResult', previousResult);
            console.log('fetchMoreResult', fetchMoreResult);
            const data = [
              ...previousResult.reservations.data,
              ...fetchMoreResult.reservations.data,
            ];
            this.take = data.length;
            return {
              reservations: {
                data,
                count: fetchMoreResult.reservations.count,
              },
            };
          },
        });
      }
    },
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    reservations: {
      query: gql`
        query userScooterReservations($id: Int, $take: Float!, $skip: Float!) {
          reservations: scooterReservations(
            userId: $id
            skip: $skip
            take: $take
            orderBy: "idDesc"
          ) {
            data {
              id
              startDate
              endDate
              status
              totalCostInCents
              userRating
              scooter {
                id
                unique_id_for_qr
              }
              user {
                email
                id
              }
            }
            count
          }
        }
      `,
      variables() {
        return {
          id: this.userId ? Number(this.userId) : this.admin ? null : Number(this.currentUserId),
          take: this.take,
          skip: 0,
        };
      },
      pollInterval: 3 * 1000,
    },
  },
};
</script>
