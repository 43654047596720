<template>
  <div class="overflow-hidden">
    <LineChart
      :chart-data="recordsMap"
      :chart-options="chartOptions"
      chart-id="cart"
      :height="150"
      class=""
      ref="chart"
    />
  </div>
</template>
<script>
import { Line as LineChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels, LineElement, LinearScale, CategoryScale, PointElement, Filler);
export default {
  inject: ['globalColors'],
  data() {
    return {
      chartOptions: {
        responsive: true,
        legend: {
          display: false,
        },
        elements: {
          line: {
            tension: 0.2,
          },
          point: {
            backgroundColor: this.globalColors.primary,
            opacity: 0.6,
            radius: function (context) {
              return context.dataIndex === 0 ||
                context.dataIndex === context.dataset.data.length - 1
                ? 3
                : 0;
            },
          },
        },
        datasets: {
          line: {
            fill: true,
            borderColor: this.globalColors.primary,
          },
        },
        plugins: {
          datalabels: {
            clamp: true,
            padding: 6,
            offset: 15,
            align: context => {
              return context.dataIndex === 0 ? -45 : 225;
            },
            backgroundColor: this.globalColors.primary,
            color: '#fff',
            borderRadius: 12,
            opacity: 0.9,
            font: {
              size: 9,
            },
            display: function (context) {
              return (
                context.dataIndex === 0 || context.dataIndex === context.dataset.data.length - 1
              );
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            title: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
            title: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      },
    };
  },
  computed: {
    recordsMap() {
      return this.records
        .slice()
        .reverse()
        .reduce(
          (acc, current) => {
            acc.labels.push(current.createdAt);
            acc.datasets[0].data.push(current.accountBalanceAfterInCents / 100);
            return acc;
          },
          {
            labels: [],
            datasets: [
              {
                data: [],
              },
            ],
          }
        );
    },
  },
  components: {
    LineChart,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
