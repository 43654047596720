import tailwind from '@root/tailwind.config.js';
import polyline from '@mapbox/polyline';

const color = tailwind.theme.extend.colors.primary.replace('#', '');
const getMapUrl = function (start, stop, geojson) {
  const { lng: lng1, lat: lat1 } = start;
  const { lng: lng2, lat: lat2 } = stop;
  if (!(lng1 && lat1) && !(lng2 && lat2)) {
    return false;
  }
  const single = !(lng1 && lat1) || (lng2 && lat2) || lng1 === lng2 || lat1 === lat2;
  const prefix = 'https://api.mapbox.com/styles/v1/gelucid/ckyxjebmp001815o31o9wfltz/static/';
  const elements = [];
  if (lng1 && lat1) {
    elements.push(`pin-s+${color}(${lng1},${lat1})`);
  }
  if (lng2 && lat2) {
    elements.push(`pin-s+${color}(${lng2},${lat2})`);
  }
  if (lng1 && lat1 && lng2 && lat2 && !single && !(geojson && geojson.length)) {
    elements.push(`path-1+${color}-1+${color}-1([[${lng1},${lat1}],[${lng2},${lat2}]])`);
  }
  if (geojson && geojson.length) {
    const maxPoints = 350;
    const getEvery = Math.floor(geojson.length / maxPoints);
    const path = geojson.reduce(
      (acc, curr, idx, arr) => {
        if (arr.length <= maxPoints || idx === arr.length - 1 || idx % getEvery === 0)
          acc.push([
            Number(curr['position.latitude'].toFixed(5)),
            Number(curr['position.longitude'].toFixed(5)),
          ]);
        return acc;
      },
      [[Number(lat1), Number(lng1)]]
    );
    if (lat2 && lng2) path.push([Number(lat2), Number(lng2)]);
    const encoded = polyline.encode(path);
    elements.push(encodeURIComponent(`path-2+${color}(${encoded})`));
  }
  const suffix = `/${
    single && !(geojson && geojson.length) ? `${lng1 || lng2},${lat1 || lat2},17,0,0` : 'auto'
  }/500x250@2x`;
  const args = [`access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`];

  const url = prefix + elements.join(',') + suffix + '?' + args.join('&');
  return url;
};
export default getMapUrl;
