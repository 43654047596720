<template>
  <div>
    <span class="grow-0 text-lg text-primary bg-white relative">Fajnie, że do nas trafiłeś!</span>
    <p class="grow-0 my-4 text-gray-500">
      Dzięki <strong class="text-primary">Kickfleet</strong> możesz wygodnie i szybko poruszać się
      po mieście.
    </p>
    <GBtn class="pl-6 pr-4 my-4" :circle="true" @click="$router.push('/login/select')"
      >Zaczynamy<FaIcon icon="caret-right" class="ml-4"
    /></GBtn>
  </div>
</template>
<script>
export default {
  name: 'WelcomePage',
};
</script>
