<template>
  <div
    class="disablePageDrag relative rounded-full flex items-center shadow-inner overflow-hidden bg-medium/10 p-4 text-lg overlow-fix"
    ref="wrapper"
    @click="bounceIt"
  >
    <div
      class="text-primary mr-6 ml-16"
      :style="{
        transform: `translateX(${-draggerWidth * progress}px)`,
        opacity: 1 - progress / 2,
      }"
    >
      <slot></slot>
    </div>
    <div
      :class="[
        'absolute bg-gray-200/50 rounded-full shadow-lg flex justify-center items-center p-[2px] h-full aspect-square left-0 top-0',
        ,
        { 'animate-bounce-right-once': bounce && animate && !left },
      ]"
      @animationend="animate = false"
      ref="dragger"
      :style="{
        transform: `translateX(${left}px)`,
      }"
    >
      <div
        class="relative h-full w-full bg-primary rounded-full shadow-lg flex justify-center items-center"
      >
        <FaIcon icon="chevron-right" class="font-thin text-white h-full" />
        <FaIcon icon="chevron-right" class="font-thin text-white h-full -ml-1" />
      </div>
    </div>
  </div>
</template>
<script>
import Hammer from 'hammerjs';

export default {
  props: {
    bounce: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      left: 0,
      wrapperWidth: 0,
      draggerWidth: 0,
      animate: true,
    };
  },
  computed: {
    sliderWidth() {
      return this.wrapperWidth - this.draggerWidth;
    },
    progress() {
      return this.left / this.sliderWidth;
    },
  },
  methods: {
    onMove(e) {
      const delta = e.deltaX;
      this.left = delta > this.sliderWidth ? this.sliderWidth : delta < 0 ? 0 : delta;
    },
    onEnd(e) {
      const delta = e.deltaX;
      if (delta >= this.sliderWidth - 10) {
        this.$emit('slided');
        this.left = this.sliderWidth;
      } else {
        this.left = 0;
      }
    },
    bounceIt() {
      console.log('bouncing');
      this.animate = true;
    },
  },
  mounted() {
    this.wrapperWidth = this.$refs.wrapper.offsetWidth;
    this.draggerWidth = this.$refs.dragger.offsetWidth;
    const stage = this.$refs.dragger;
    this.mc = new Hammer.Manager(stage);
    // create a recognizer
    const Pan = new Hammer.Pan();
    // add the recognizer
    this.mc.add(Pan);
    // subscribe to events
    // this.mc.on('panmove', e => {
    //   // do something cool
    //   const delta = e.deltaX;
    //   const sliderWidth = this.$refs.wrapper.offsetWidth - this.$refs.dragger.offsetWidth;
    //   this.left = delta > sliderWidth ? sliderWidth : delta < 0 ? 0 : delta;
    //   // stage.style.transform = 'rotate(' + rotation + 'deg)';
    // });
    this.mc.on('panmove', this.onMove);
    this.mc.on('panend', this.onEnd);
  },
  beforeUnmount() {
    this.mc.off('panmove', this.offMove);
    this.mc.off('panend', this.onEnd);
  },
};
</script>
