<template lang="">
  <div class="text-left">
    <div class="flex items-center relative max-w-[100vw]" :class="{ 'hover:bg-gray-200': forList }">
      <div
        class="bg-cover bg-bottom rounded-lg shrink-0 grow-0"
        :class="{
          ' w-14 h-14': small,
          ' h-20 w-20': !small,
        }"
        :style="{
          'background-image': `url(${photoUrl})`,
        }"
      ></div>
      <div class="grow flex flex-col justify-stretch pl-4">
        <div class="whitespace-normal">
          <p class="">
            <span v-if="!forList">{{ $t('station.station') }}</span> {{ station.address }}
          </p>
          <p class="text-gray-700 text-sm">
            {{ station.address2 }}
          </p>
          <p class="text-gray-700 text-sm">
            {{ station.description }}
          </p>
        </div>
      </div>
      <div v-if="forList" class="h-full absolute right-0 top-0 pr-4 flex items-center">
        <FaIcon icon="chevron-right" class="font-thin text-gray-500" />
      </div>
    </div>
    <div class="pt-4" v-if="icons">
      <div class="flex justify-around">
        <div class="flex flex-col text-center items-center p-1 text-xs">
          <FaIcon icon="route" class="h-6 w-6 opacity-70" fixed-width />
          <span class="text-xs mt-1"
            >{{ distance.value }} {{ distance.unit }} {{ $t('global.from-you') }}</span
          >
        </div>
        <div class="flex flex-col text-center items-center p-1 text-xs text-primary">
          <FaIcon icon="list-ol" class="h-6 w-6 opacity-70" fixed-width />
          <span class="text-xs mt-1"> {{ $tc('station.avaliable-scooters', scootersCount) }}</span>
        </div>
        <div class="flex flex-col text-center items-center p-1 text-xs">
          <FaIcon icon="charging-station" class="h-6 w-6 opacity-70" fixed-width />
          <span class="text-xs mt-1"> {{ $tc('station.avaliable-slots', emptySlotsCount) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import { calcDistance } from '@/utils/calcDistance';
import config from '@config';

export default {
  data() {
    return {
      station: {},
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    station: {
      query: gql`
        query scooterParkingStation($id: Int!) {
          station: scooterParkingStation(id: $id) {
            id
            lat
            lng
            address
            address2
            photosOfStation
            description
            slots: scooterParkingSlots {
              status
              currentlyDockedScooter {
                currentScooterReservation {
                  id
                }
                id
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: Number(this.stationId),
        };
      },
    },
  },
  computed: {
    ...mapGetters({ currentPosition: 'getPosition' }),
    photoUrl() {
      return this.station.photosOfStation &&
        this.station.photosOfStation[0] &&
        this.station.photosOfStation[0].photoPath
        ? config.api.baseUrl + '/' + this.station.photosOfStation[0].photoPath
        : '';
    },
    distance() {
      const km = calcDistance(this.station, this.currentPosition);
      if (isNaN(km)) return {};
      const m = Math.round(km * 1000);
      return m >= 1000 ? { value: (m / 1000).toPrecision(2), unit: 'km' } : { value: m, unit: 'm' };
    },
    scootersCount() {
      return !this.station.slots
        ? 0
        : this.station.slots.filter(
            e => e.currentlyDockedScooter?.id && !e.currentlyDockedScooter.currentScooterReservation
          ).length;
    },
    emptySlotsCount() {
      return !this.station.slots ? 0 : this.station.slots.filter(e => e.status === 'empty').length;
    },
  },
  props: {
    stationId: {
      type: Number,
      default: null,
    },
    forList: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToStation() {
      this.$router.push(`/station/${this.stationId}`);
    },
  },
  created() {},
};
</script>
