<template>
  <div class="home h-full w-full bg-white">
    <MenuTransition class="h-full w-full" ref="menu">
      <template #left>
        <MenuContent @close="$refs.menu.close()" class="" />
      </template>
      <template #right>
        <div class="h-full w-full">
          <div class="relative w-full h-full flex flex-col justify-stretch">
            <div class="w-full h-full shrink relative">
              <StationsMap
                class="w-full h-full scale-105"
                ref="map"
                @loaded="onMapReady"
                @error="onMapError"
                @showStation="showStation"
                @hideStation="hideStation"
              />
              <div class="absolute left-0 bottom-0 pointer-events-none w-full">
                <BottomNav
                  :items="menuItems"
                  @clicked="menuClicked"
                  button-class="bg-white"
                  between
                  class="px-4"
                  :class="{ 'p-4': openedDrawer }"
                  :no-safe-area="openedDrawer"
                >
                  <QrButton cta ref="qrButton" />
                </BottomNav>
              </div>
            </div>

            <div class="flex items-end text-gray-700">
              <BottomDrawer
                :open="stationId !== null"
                :class="['w-screen shrink-0 grow-0 ', { 'mb-16': reservationCount }]"
              >
                <template #header>
                  <div class="overflow-hidden h-full" v-if="stationId !== null">
                    <div class="w-full text-center pt-6 pb-4">
                      <StationOverview
                        v-if="stationId !== null"
                        :stationId="Number(stationId)"
                        class="px-4"
                        icons
                      />
                    </div>
                  </div>
                </template>
                <template #default>
                  <div class="px-4" v-if="stationId">
                    <span>Dostępne pojazdy:</span>
                    <SlotsList v-if="stationId" :stationId="stationId" />
                  </div>
                </template>
              </BottomDrawer>
              <BottomDrawer
                :open="!!reservationCount"
                class="w-screen shrink-0 grow-0 -ml-[100%] shadow-primary/10 border-2 border-b-0 border-primary border-dashed"
                ref="ridesDrawer"
              >
                <template #header>
                  <div class="overflow-hidden h-full text-primary" v-if="reservationCount">
                    <div class="w-full text-center pt-6 pb-4">
                      <span v-if="reservationCount"
                        >{{
                          reservationCount === 1 ? $t('ride.active-ride') : $t('ride.active-rides')
                        }}:</span
                      >
                    </div>
                  </div>
                </template>
                <template #default>
                  <RidesActive v-if="reservationCount" class="pb-4" />
                </template>
              </BottomDrawer>
            </div>
          </div>
          <div
            class="h-full w-full absolute top-0 left-0 flex flex-col justify-between pointer-events-none z-20"
          >
            <div class="px-4" :style="{ 'padding-top': safeArea.top || '1rem' }">
              <NotificationBox
                v-if="userActiveReservations.length"
                class="text-sm"
                :title="$t('global.attention')"
                :body="$t('alerts.return-warning')"
                icon="triangle-exclamation"
                permanent
              />
            </div>
          </div>
        </div>
      </template>
    </MenuTransition>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuContent from '@/components/ui/partials/MenuContent';
import MenuTransition from '@/components/MenuTransition';
// import TopNav from '@/components/ui/partials/TopNav';
import BottomNav from '@/components/ui/partials/BottomNav';
import StationsMap from '@/components/StationsMap';
import QrButton from '@/components/QrButton';
import { SplashScreen } from '@capacitor/splash-screen';
import StationOverview from '@/components/StationOverview';
import BottomDrawer from '@/components/ui/BottomDrawer';
import RidesActive from '@/components/ride/RidesActive';
import NotificationBox from '@/components/ui/notifications/NotificationBox';
import SlotsList from '@/components/SlotsList';

export default {
  name: 'MapPage',
  inject: ['userActiveReservations', 'userEndedReservations', 'safeArea'],
  data() {
    return {
      routeActions: {
        reserve: payload => {
          console.log('triggered reserve action');
          if (!payload?.qr) {
            throw new Error('Missing qr');
          }
          this.$refs.qrButton.startRide(payload.qr, true);
        },
        'show-reservations': () => {
          this.$refs.ridesDrawer.openDrawer();
        },
      },
      stationId: null,
      menuOpened: false,
      left: 0,
      menuItems: [
        {
          icon: 'bars',
          loading: false,
          callback: async () => {
            await this.$refs.menu.open();
          },
        },
        {
          icon: 'location-arrow',
          loading: false,
          callback: async () => {
            await this.$refs.map.goToLocation();
          },
        },
      ],
    };
  },
  methods: {
    async menuClicked(idx) {
      this.menuItems[idx].loading = true;
      await this.menuItems[idx].callback();
      this.menuItems[idx].loading = false;
    },
    showStation(id) {
      this.$refs.ridesDrawer.closeDrawer();
      this.stationId = id;
      this.$nextTick(() => {
        // this.$refs.map.map.resize();
      });
    },
    hideStation() {
      this.stationId = null;
      this.$nextTick(() => {
        // this.$refs.map.map.resize();
      });
    },
    onMapReady() {
      console.log('hiding splash');
      SplashScreen.hide();
    },
    onMapError() {
      console.log('hiding splash');
      SplashScreen.hide();
    },
  },
  computed: {
    reservationCount() {
      return this.userActiveReservations.length + this.userEndedReservations.length;
    },
    openedDrawer() {
      return !!(this.reservationCount || this.stationId);
    },
  },
  watch: {
    reservationCount(to, from) {
      if (to > from) {
        this.$refs.ridesDrawer.openDrawer();
      }
    },
  },
  components: {
    // TopNav,
    BottomNav,
    StationsMap,
    QrButton,
    MenuContent,
    MenuTransition,
    StationOverview,
    BottomDrawer,
    RidesActive,
    NotificationBox,
    SlotsList,
  },
};
</script>
