import { createI18n } from 'vue-i18n';
import plLocale from '../locales/pl.yml';
import enLocale from '../locales/en.yml';
const i18n = createI18n({
  locale: 'pl',
  messages: {
    pl: plLocale,
    en: enLocale,
  },
});
async function setLang(lang) {
  i18n.changeLanguage(lang);
}
const t = i18n.global.t;
export { i18n, t, setLang };
