import { Camera, CameraResultType, CameraSource, CameraDirection } from '@capacitor/camera';

async function requestPermissions() {
  const permission = await Camera.requestPermissions();
  return await checkAndRequestPermissions(permission);
}

async function checkAndRequestPermissions(permiss) {
  const permission = permiss || (await Camera.checkPermissions());
  switch (permission.camera) {
    case 'denied':
      return false;
    case 'granted':
      return true;
    case 'prompt-with-rationale':
      return await requestPermissions();
    case 'prompt':
      return await requestPermissions();
    default:
      return false;
  }
}

function getBlob(base64) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpg' });
  return blob;
}
async function takePhoto() {
  const permission = await checkAndRequestPermissions();
  if (permission) {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      direction: CameraDirection.Rear,
    });
    return image.base64String;
  }
  return false;
}

export { takePhoto, checkAndRequestPermissions, getBlob };
