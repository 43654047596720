<template>
  <div class="h-full w-full bg-gray-50 flex flex-col justify-stretch">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      {{ $t('station.select-station') }}
    </TopNav>
    <StationsList :callback="selectStation" />
    <BottomNav
      class="pointer-events-auto bg-white w-full grow-0 shrink-0 z-10"
      :dummy-end="true"
      solid
    >
      <QrButton tooltip />
    </BottomNav>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from '@/components/ui/partials/TopNav';
import BottomNav from '@/components/ui/partials/BottomNav';
import QrButton from '@/components/QrButton';
import StationsList from '@/components/StationsList';

export default {
  methods: {
    async menuClicked(idx) {
      this.menuItems[idx].loading = true;
      await this.menuItems[idx].callback();
      this.menuItems[idx].loading = false;
    },
    selectStation(id) {
      this.$router.push('/station/' + id);
    },
  },
  mounted() {},
  components: {
    TopNav,
    BottomNav,
    QrButton,
    StationsList,
  },
};
</script>
