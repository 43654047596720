<template>
  <div class="form-check form-switch">
    <input
      :class="[
        'form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain focus:outline-none cursor-pointer shadow-sm',
        val ? 'bg-primary' : 'bg-gray-300',
      ]"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckDefault56"
      :checked="val"
      :disabled="disabled"
    />
  </div>
</template>
<script>
export default {
  props: {
    val: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
