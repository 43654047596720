import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { apolloClient } from '@/apollo';
import gql from 'graphql-tag';
import store from '@/store';

function initNotifications() {
  console.log('Initializing notifications test');
  if (Capacitor.isNativePlatform()) {
    console.log('adding listeners');

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', token => {
      // alert('Push registration success, token: ' + token.value);
      console.log('Push registration success, token: ' + token.value);
      apolloClient
        .mutate({
          mutation: gql`
            mutation ($token: String!) {
              registerFirebaseFcmToken(token: $token) {
                id
                fcmToken
                createdAt
                user {
                  id
                }
              }
            }
          `,
          variables: {
            token: token.value,
          },
        })
        .catch(error => {
          // alert(error);
          throw new Error(error);
        })
        .then(result => {
          console.log('registered FCM token in api');
          console.log(result);
        });
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', error => {
      // alert('Error on registration: ' + JSON.stringify(error));
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', notification => {
      store.dispatch('ui/notifications/addNotification', { ...notification, timeout: 5 * 1000 });
      console.log('Push received: ' + JSON.stringify(notification));
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      // alert('Push action performed: ' + JSON.stringify(notification));
      console.log('Push action performed: ' + JSON.stringify(notification));
    });
  } else {
    console.log('notifications initializing aborted. we are in web');
  }
}

function promptForNotifications() {
  return new Promise(resolve => {
    if (Capacitor.isNativePlatform()) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      console.log('prompting');
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          resolve(true);
        } else {
          // Show some error
          console.log('hmm, cannot request notification', result);
          resolve(result);
        }
      });
    } else {
      console.log('skipping notifications promt for web');
      resolve('we are in web');
    }
  });
}

export { initNotifications, promptForNotifications };
