<template>
  <div class="relative flex flex-col drop-shadow-md overflow-hidden">
    <div class="flex">
      <template v-for="tab in tabs" :key="tab.selector">
        <div
          @click="select(tab)"
          class="cursor-pointer px-2 pt-1 pb-2 -mb-1 mr-1 rounded-t text-sm w-fit bg-white"
          :class="{ 'opacity-50 shadow': !isActive(tab), 'z-10 text-primary': isActive(tab) }"
        >
          {{ tab.title }}
        </div>
      </template>
    </div>
    <div class="bg-white rounded h-full relative overflow-hidden">
      <template v-for="tab in tabs" :key="tab.selector">
        <slot :name="tab.selector || tab.title" v-if="isActive(tab)"></slot>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: null,
    };
  },
  methods: {
    isActive(tab) {
      return tab.selector === this.active;
    },
    select(tab) {
      this.active = tab.selector;
    },
  },
  props: {
    tabs: {
      type: Array,
      default: () => [{ title: '', selector: 'default' }],
    },
  },
  mounted() {
    this.active = this.tabs[0].selector;
  },
};
</script>
