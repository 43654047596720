<template>
  <div class="relative">
    <div class="absolute h-full w-full animate-pulse left-0 top-0">
      <div class="h-full w-full bg-grid opacity-20"></div>
    </div>
    <div id="map" class="w-full h-full" ref="map"></div>
  </div>
</template>
<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import createGeoJSONCircle from '@/utils/createGeoJSONCircle';
import { faStreetView } from '@fortawesome/free-solid-svg-icons';
import gql from 'graphql-tag';
import { mapGetters, mapActions } from 'vuex';
import { calcDistance } from '@/utils/calcDistance';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { debounce } from 'debounce';

const clusterOptions = {
  cluster: true,
  clusterMaxZoom: 14, // Max zoom to cluster points on
  clusterRadius: 80, // Radius of each cluster when clustering points (defaults to 50)
};

export default {
  name: 'StationsMap',
  inject: ['globalColors', 'userActiveReservations', 'userEndedReservations'],
  data() {
    return {
      map: null,
      locationMarker: null,
      loadingStations: false,
      stations: [],
      loadedImgs: [],
      resizeObserver: null,
      selectedStation: null,
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    stations: {
      query: gql`
        query {
          stations: scooterParkingStations {
            id
            lat
            lng
            slots: scooterParkingSlots {
              status
              currentlyDockedScooter {
                id
                currentScooterReservation {
                  id
                }
              }
            }
          }
        }
      `,
      skip: true,
      pollInterval: 5 * 1000,
      update: data =>
        data.stations.map(station => ({
          ...station,
          id: Number(station.id),
          lat: Number(station.lat),
          lng: Number(station.lng),
        })),
      result() {
        // console.log('this', this);
        this.paintStations();
        this.showNearestStation();
      },
    },
  },
  computed: {
    ...mapGetters({ currentPosition: 'getPosition' }),
  },
  methods: {
    ...mapActions(['startLocating', 'stopLocating']),
    async showStation(station) {
      this.selectedStation = station;
      console.log('showing station', station);
      await this.prepareImages();
      this.$emit('showStation', station.id);
      const source = this.map.getSource('selectMarker');
      source.setData(this.stationToFeature(station));
      this.paintSelectedLine();
      setTimeout(() => {
        this.zoomToLine();
      }, 200);
    },
    zoomToLine() {
      if (this.selectedStation) {
        if (this.currentPosition.lat && this.currentPosition.lng) {
          if (this.map) {
            const p0 = [this.currentPosition.lng, this.currentPosition.lat];
            const p1 = [this.selectedStation.lng, this.selectedStation.lat];
            console.log('navigationg to bounds', p0, p1);
            this.map.fitBounds([p0, p1], { padding: 80 });
          }
        } else {
          if (this.map)
            this.map.easeTo({
              zoom: 15,
              center: [this.selectedStation.lng, this.selectedStation.lat],
            });
        }
      }
    },
    async paintSelectedLine() {
      if (this.selectedStation && this.currentPosition.lat && this.currentPosition.lng) {
        const data = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: [
                  [this.selectedStation.lng, this.selectedStation.lat],
                  [this.currentPosition.lng, this.currentPosition.lat],
                ],
              },
            },
          ],
        };
        console.log('paint selected line', data);
        const source = this.map.getSource('selectLine');
        source.setData(data);
      }
    },
    async menuClicked(idx) {
      this.menuItems[idx].loading = true;
      await this.menuItems[idx].callback();
      this.menuItems[idx].loading = false;
    },
    hideStation(id) {
      this.selectedStation = null;
      this.$emit('hideStation', id);
      const source = this.map.getSource('selectMarker');
      const lineSource = this.map.getSource('selectLine');

      lineSource.setData({
        type: 'FeatureCollection',
        features: [],
      });
      source.setData({
        type: 'Feature',
      });
    },
    showNearestStation() {
      if (this.userActiveReservations.length || this.userEndedReservations.length) {
        this.goToLocation();
        return;
      }
      const nearest = this.stations.slice().sort((a, b) => {
        return calcDistance(this.currentPosition, a) - calcDistance(this.currentPosition, b);
      })[0];
      console.log('nearest', nearest);
      if (nearest) {
        this.showStation(nearest);
      }
    },
    async reload() {
      await this.$apollo.queries.stations.refetch();
      return true;
    },
    async paintLocation() {
      if (!this.map) {
        console.log('waiting for map');
        return false;
      }
      if (!this.currentPosition) {
        console.log('waiting for coordinates');
        return false;
      }
      const points = createGeoJSONCircle(
        {
          lat: this.currentPosition.lat,
          lng: this.currentPosition.lng,
        },
        this.currentPosition.accuracy / 1000
      );
      const circleSource = this.map.getSource('locationCircle');
      circleSource.setData({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [points],
        },
      });
      this.locationMarker
        .remove()
        .setLngLat([this.currentPosition.lng, this.currentPosition.lat])
        .addTo(this.map);
      return true;
    },
    goToLocation() {
      if (!this.map) {
        console.log('waiting for map');
        return false;
      }
      if (!(this.currentPosition && this.currentPosition.lat && this.currentPosition.lng)) {
        console.log('waiting for coordinates');
        return false;
      }
      this.map.easeTo({ zoom: 15, center: [this.currentPosition.lng, this.currentPosition.lat] });
    },
    prepareLocationLayer() {
      this.map.addSource('locationCircle', {
        type: 'geojson',
        data: { type: 'Feature' },
      });
      this.map.addLayer({
        id: 'locationCircle',
        type: 'fill',
        source: 'locationCircle',
        paint: {
          'fill-color': this.globalColors.primary,
          'fill-opacity': 0.2,
        },
      });
      const el = document.createElement('div');
      el.innerHTML = `<svg
        class="svg-inline--fa fa-location-arrow fa-w-32 fa-fw fa-2x block h-auto text-secondary drop-shadow-xl "
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="location-arrow"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path class="" fill="currentColor" d="${faStreetView.icon[4]}"></path>
      </svg>`;
      this.locationMarker = new mapboxgl.Marker({
        element: el,
        anchor: 'bottom',
        offset: [0, 7],
      });
    },
    prepareSelectLayer() {
      this.map.addSource('selectMarker', {
        type: 'geojson',
        data: { type: 'Feature' },
      });
      this.map.addSource('selectLine', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
      this.map.addLayer({
        id: 'selectLine',
        type: 'line',
        source: 'selectLine',
        paint: {
          'line-color': this.globalColors.primary,
          'line-width': 2,
          'line-dasharray': [2, 1],
        },
      });
      this.map.addLayer({
        id: 'selectMarkerCircle',
        type: 'circle',
        source: 'selectMarker',

        paint: {
          'circle-color': '#fff',
          'circle-stroke-color': this.globalColors.primary,
          'circle-stroke-width': 1.5,
          'circle-radius': 27,
        },
      });
      this.map.addLayer({
        id: 'selectMarker',
        type: 'symbol',
        source: 'selectMarker',
        layout: {
          'icon-image': ['get', 'type'],
          'icon-size': 0.03,
          'text-field': ['concat', ['get', 'count'], '/', ['get', 'max']],
          'text-size': 12,
          'text-anchor': 'bottom-right',
          'text-padding': 4,
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
        },
        paint: {
          'text-halo-color': this.globalColors.primary,
          'text-halo-width': 40,
          'text-halo-blur': 40,
          'text-color': '#ffffff',
        },
      });
    },
    loadImage(imageName) {
      return new Promise((resolve, reject) => {
        this.loadedImgs.push(imageName);
        this.map.loadImage(`/assets/img/devices/${imageName}.png`, (error, image) => {
          if (error) reject(error);
          this.map.addImage(imageName, image);
          resolve(true);
        });
      });
    },
    async prepareImages() {
      for (let i = 0; i < this.stations.length; i++) {
        const station = this.stations[i];
        const type = station.type || 'es4';
        if (!this.loadedImgs.includes(type)) {
          await this.loadImage(type);
        }
      }
    },
    stationToFeature(station) {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [station.lng, station.lat],
        },
        properties: {
          ...station,
          station,
          count: !station.slots
            ? 0
            : station.slots.filter(
                e =>
                  e.currentlyDockedScooter?.id &&
                  !e.currentlyDockedScooter.currentScooterReservation
              ).length,
          max: !station.slots ? 0 : station.slots.filter(e => e.status === 'empty').length,
          type: station.type || 'es4',
        },
      };
    },
    async paintStations() {
      console.log('painting stations', this.stations[0].slots.slice());
      if (!this.map) {
        console.log('waiting for map');
        return false;
      }
      if (this.loadingStations) return;
      this.loadingStations = true;
      await this.prepareImages();
      const features = this.stations.map(this.stationToFeature);
      const stationsSource = this.map.getSource('stations');
      stationsSource.setData({
        type: 'FeatureCollection',
        features,
      });
      console.log('stationsSource', stationsSource);
      this.loadingStations = false;
    },
    prepareStationsLayer() {
      this.map.addSource('stations', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        ...clusterOptions,
        clusterProperties: {
          count: ['+', ['get', 'count']],
          max: ['+', ['get', 'max']],
        },
      });
      this.map.addLayer({
        id: 'stations',
        source: 'stations',
        type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': ['get', 'type'],
          'icon-size': 0.03,
          'text-field': ['concat', ['get', 'count'], '/', ['get', 'max']],
          'text-size': 12,
          'text-anchor': 'bottom-right',
          'text-padding': 4,
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
        },
        paint: {
          'text-halo-color': this.globalColors.primary,
          'text-halo-width': 40,
          'text-halo-blur': 40,
          'text-color': '#ffffff',
        },
      });
      this.map.addLayer({
        id: 'stations_clusters',
        type: 'circle',
        source: 'stations',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': this.globalColors.primary,
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        },
      });
      this.map.addLayer({
        id: 'stations_cluster-count',
        type: 'symbol',
        source: 'stations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['concat', ['get', 'count'], '/', ['get', 'max']],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      });
      console.log('layer', this.map.getLayer('stations'));
    },
    hookEvents() {
      this.map.on('mouseenter', ['stations_clusters', 'stations'], () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', ['stations_clusters', 'stations'], () => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('click', 'stations_clusters', e => {
        e.preventDefault();
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: ['stations_clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        this.map.getSource('stations').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
      });
      this.map.on('click', 'stations', e => {
        e.preventDefault();
        this.showStation(JSON.parse(e.features[0].properties.station));
      });
      this.map.on('click', e => {
        if (e.defaultPrevented === false) {
          this.hideStation();
        }
      });
    },
    renderMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
      try {
        this.map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/gelucid/ckyxjebmp001815o31o9wfltz?optimize=true',
          touchPitch: false,
        });
        this.map.on('error', err => {
          console.log('map error', err);
          this.$emit('error');
          SplashScreen.hide();
        });
        this.map.on('load', async () => {
          console.log('map loaded');
          await this.$emit('loaded');
          await this.map.resize();
          SplashScreen.hide();
          await this.prepareLocationLayer();
          await this.prepareStationsLayer();
          await this.prepareSelectLayer();
          await this.hookEvents();
          await this.startLocating();
          this.$apollo.queries.stations.skip = false;
        });
      } catch (err) {
        console.log('error loading map', err);
        this.$emit('error');
      }
    },
    resizeMap() {
      this.map.resize();
      this.zoomToLine();
    },
  },
  watch: {
    currentPosition: {
      handler(newPos, oldPos) {
        if (newPos.lng && newPos.lat) {
          const distance = calcDistance(newPos, oldPos);
          if (this.selectedStation) {
            if (!oldPos.lat) {
              this.showStation(this.selectedStation);
            } else if (distance > 100) {
              this.paintselectLayer();
            }
          }
          this.paintLocation();
        }
      },
      depp: true,
    },
  },
  mounted() {
    this.renderMap();
    App.addListener('appStateChange', state => {
      if (state.isActive) {
        this.showNearestStation();
      }
    });

    this.resizeObserver = new ResizeObserver(debounce(this.resizeMap, 10));

    this.resizeObserver.observe(this.$refs.map);
  },
  beforeUnmount() {
    this.stopLocating();
    this.resizeObserver.disconnect();
  },
  created() {},
};
</script>
