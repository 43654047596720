<template>
  <div
    class="main-wrapper h-screen w-screen relative transition-[clip-path] bg-white"
    :style="{ 'clip-path': 'inset(' + offset + '% 0 0 0 )' }"
    id="main-wrapper"
  >
    <div class="w-full h-full bg-white">
      <router-view></router-view>
    </div>
    <NotificationList class="absolute top-10 w-full left-0 z-10" />
    <AcceptTOS class="z-50" />
    <CheckVersion class="z-50" />
  </div>
</template>

<script>
import { disablePageScroll, addFillGapSelector, addLockableSelector } from 'scroll-lock';
import tailwind from '@root/tailwind.config.js';
import { mapActions, mapGetters } from 'vuex';
import { Keyboard } from '@capacitor/keyboard';
import { initNotifications } from './notifications';
import NotificationList from './components/ui/notifications/NotificationList';
import { Capacitor } from '@capacitor/core';
import CheckVersion from '@/components/CheckVersion';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { computed } from 'vue';
import AcceptTOS from '@/components/AcceptTOS';
import gql from 'graphql-tag';
import { formatDateForAPI } from '@/utils/formatDate';
import { FULL_RESERVATION_PARAMS } from '@/apollo/queries';

export default {
  data() {
    return {
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      safeArea: { top: 0, left: 0, right: 0, bottom: 0 },
      userActiveReservations: [],
      userEndedReservations: [],
      now: Date.now(),
      interval: null,
    };
  },
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      getAppOffset: 'ui/getAppOffset',
    }),
    safeAreas() {
      const obj = { ...this.safeArea };
      for (const key in obj) {
        obj[key] = obj[key] > 0 ? obj[key] + 'px' : '0';
      }
      return obj;
    },
    offset() {
      return this.getAppOffset > 0 ? 100 - (this.getAppOffset / this.screenHeight) * 100 : 0;
    },
  },
  watch: {
    getAppOffset(val) {
      if (val > 0) {
        this.watchHeight();
      } else {
        this.unwatchHeight();
      }
    },
  },
  mounted() {
    initNotifications();
    addLockableSelector('html');
    addFillGapSelector('html');
    disablePageScroll();

    this.interval = setInterval(() => {
      this.now = Date.now();
    }, 1000);

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this.safeArea = insets;
    });

    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.addListener('keyboardDidShow', info => {
        this.setKbHeight(info.keyboardHeight);
        // console.log('keyboard did show with height:', info.keyboardHeight);
      });
      Keyboard.addListener('keyboardDidHide', () => {
        this.setKbHeight(0);
      });
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    userActiveReservations: {
      query: gql`
        query userActiveScooterReservations($id: Int!) {
          userActiveReservations: scooterReservations(
            userId: $id
            orderBy: "idDesc"
            whitelistStatus: [
              "beginning"
              "error_beginning_scooter_remaining_in_stand"
              "active"
              "ending"
              "reserved"
              "paused"
              "error_ending_incorrectly_placed_scooter"
            ]
          ) {
            count
            data {
              ${FULL_RESERVATION_PARAMS}
            }
          }
        }
      `,
      update: data => data.userActiveReservations.data,
      variables() {
        return {
          id: Number(this.userId),
        };
      },
      fetchPolicy: 'network-only',
      pollInterval: 3 * 1000,
    },
    userEndedReservations: {
      // get ended reservations from past 5 minutes
      query: gql`
        query userEndedScooterReservations($id: Int!, $startDate: DateTime!, $endDate: DateTime!) {
          userEndedReservations: scooterReservations(
            userId: $id
            startDate: $startDate
            endDate: $endDate
            orderBy: "idDesc"
            whitelistStatus: ["end"]
          ) {
            count
            data {
              ${FULL_RESERVATION_PARAMS}
            }
          }
        }
      `,
      update: data => data.userEndedReservations.data,
      variables() {
        return {
          id: Number(this.userId),
          startDate: formatDateForAPI(this.now - 5 * 60 * 1000),
          endDate: formatDateForAPI(this.now),
        };
      },
      fetchPolicy: 'network-only',
      pollInterval: 3 * 1000,
    },
  },
  methods: {
    ...mapActions({ setKbHeight: 'ui/setKbHeight' }),
    setScreenHeight() {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    },
    watchHeight() {
      window.addEventListener('resize', this.setScreenHeight);
      this.setScreenHeight();
    },
    unwatchHeight() {
      window.removeEventListener('resize', this.setScreenHeight);
    },
  },
  async beforeUnmount() {
    clearInterval(this.interval);

    this.unwatchHeight();
    if (Capacitor.isNativePlatform()) await Keyboard.removeAllListeners();
  },
  provide() {
    return {
      globalColors: tailwind.theme.extend.colors,
      safeArea: computed(() => this.safeAreas),
      screenHeight: computed(() => this.screenHeight),
      screenWidth: computed(() => this.screenWidth),
      now: computed(() => this.now),
      userActiveReservations: computed(() => this.userActiveReservations),
      //  user ended reservations from past 5 minutes with no user rate
      userEndedReservations: computed(() =>
        this.userEndedReservations.slice().filter(e => e.userRating === null)
      ),
    };
  },
  components: { NotificationList, CheckVersion, AcceptTOS },
};
</script>
