<template>
  <div>
    <RideOverviewContent
      :reservation="reservation"
      :hide-arrow="true"
      class="shadow bg-gray-100 pt-2 pb-2 px-4"
      showQr
      showMilage
    />
    <div class="">
      <RideStatus
        v-if="reservation.status !== 'active' && reservation.status !== 'end'"
        class="text-sm p-2 my-2"
        small
      />
      <div class="flex flex-wrap justify-around items-center text-center">
        <RideCounters v-if="showCounters" class="p-2 my-2" small />
        <RidePredictedCost hideInfo class="text-sm p-2 my-2" />
        <RidePause class="p-2 my-2" small />
      </div>
      <RideRate
        v-if="showRate && reservation.status === 'end' && !reservation.userRating"
        class="w-full my-2"
      />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import RideOverviewContent from './RideOverview/RideOverviewContent';
import RideCounters from './RideCounters';
import RidePause from './RidePause';
import RidePredictedCost from './RidePredictedCost';
import RideRate from './RideRate';
import RideStatus from './RideStatus';
export default {
  data() {},
  components: {
    RideOverviewContent,
    RideCounters,
    RidePause,
    RidePredictedCost,
    RideRate,
    RideStatus,
  },
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    showCounters: {
      type: Boolean,
      default: true,
    },
    showRate: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      reservation: computed(() => this.reservation),
    };
  },
};
</script>
