<template>
  <div class="flex grow shrink overflow-hidden z-0">
    <GScrollBar class="h-auto w-full relative" :options="{ suppressScrollX: true }">
      <div class="py-4 h-full">
        <StationOverview
          v-for="(station, idx) in stations"
          :stationId="Number(station.id)"
          :for-list="true"
          :key="idx"
          :small="small"
          class="p-4 cursor-pointer last:border-0 border-b"
          @click="callback(station.id)"
        />
      </div>
    </GScrollBar>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import StationOverview from './StationOverview';

export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stations: [],
    };
  },
  components: {
    StationOverview,
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    stations: {
      query: gql`
        query {
          stations: scooterParkingStations {
            id
          }
        }
      `,
      pollInterval: 5 * 1000,
    },
  },
};
</script>
