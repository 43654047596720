import { format as timeFormat, fromUnixTime } from 'date-fns';
import { pl } from 'date-fns/locale';

function formatDate(timestamp, format = 'do MMMM yo, HH:mm') {
  if (!timestamp) return '';
  const time = Number(timestamp) ? fromUnixTime(timestamp / 1000) : new Date(timestamp);
  return timeFormat(time, format, {
    locale: pl,
  });
}

function formatDateForAPI(timestamp) {
  return formatDate(timestamp, 'yyyy-MM-dd HH:mm:ss');
}

function formatOfferHours(timestamp) {
  return formatDate(timestamp, 'HH:mm');
}
function formatOfferDays(timestamp) {
  return formatDate(timestamp, 'dd HH:mm');
}

function formatDuration(seconds) {
  const h = Math.floor(seconds / (60 * 60));
  const m = Math.floor((seconds % (60 * 60)) / 60);
  const s = Math.floor(seconds % 60);
  let ret = [];
  if (h) ret.push(`<span class="text-sm">${h}</span>h`);
  if (m) ret.push(`<span class="text-sm">${m}</span>m`);
  if (s) ret.push(`<span class="text-sm">${s}</span>s`);
  return ret.join('  ');
}

export { formatDate, formatDuration, formatDateForAPI, formatOfferHours, formatOfferDays };
