<template>
  <div>
    <TransitionGroup name="fade">
      <NotificationBox
        v-for="(notification, idx) in notifications"
        class="m-2"
        @close="close(idx)"
        :title="notification.title"
        :body="notification.body"
        :key="idx"
      />
    </TransitionGroup>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import NotificationBox from './NotificationBox';
export default {
  computed: {
    ...mapGetters({ notifications: 'ui/notifications/getNotifications' }),
  },
  methods: {
    ...mapMutations({ close: 'ui/notifications/removeNotification' }),
  },
  components: {
    NotificationBox,
  },
};
</script>
