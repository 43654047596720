<template>
  <form @submit.self.prevent="login">
    <div class="flex flex-col justify-center items-centertext-center relative">
      <div class="my-6">
        <GInputGroup
          label="email"
          v-model="email"
          class="mb-2 rounded w-full"
          autocomplete="email"
          :disabled="loading"
          :button="false"
        />
        <GInputGroup
          label="password"
          v-model="password"
          type="password"
          class="rounded w-full"
          autocomplete="current-password"
          :disabled="loading"
          :button="false"
        />
      </div>
      <div class="flex justify-between items-center mb-3">
        <GGoBack class="pointer-events-auto text-sm text-gray-500" :small="true" ghost />
        <GBtn
          class="mb-2 text-sm shadow text-light rounded-full px-3"
          submit
          small
          value="Zaloguj się"
          :loading="loading"
        >
          Zaloguj się</GBtn
        >
      </div>
    </div>
  </form>
</template>
<script>
import gql from 'graphql-tag';
import { mapMutations, mapActions } from 'vuex';
export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    ...mapMutations(['setUserId', 'setUserRole']),
    ...mapActions(['setTokens']),
    login() {
      this.loading = true;
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($email: String!, $password: String!) {
              login(input: { email: $email, password: $password }) {
                access_token
                refresh_token
                user {
                  id
                  role
                }
              }
            }
          `,
          // Parameters
          variables: {
            email: this.email.toLowerCase(),
            password: this.password,
          },
        })
        .then(async ({ data }) => {
          // Result
          await this.setTokens(data.login);
          this.setUserId(data.login.user.id);
          this.setUserRole(data.login.user.role);
          this.$apollo.provider.defaultClient.resetStore();
          this.loading = false;
          this.$router.replace('/');
        })
        .catch(error => {
          // Error
          alert(error);
          this.loading = false;
          throw new Error(error);
          // We restore the initial user input
        });
    },
  },
};
</script>
