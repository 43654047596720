<template>
  <div class="relative">
    <textarea
      class="w-full h-24 border text-gray-500 shadow-inner rounded bg-gray-100/10 p-2"
      @change="$emit('update:modelValue', $event.target.value)"
      v-bind="$props"
    ></textarea>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: { type: String, default: '' },
  },
};
</script>
