import { NativeAudio } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';
const files = ['error', 'ping', 'success'];

function preload() {
  if (Capacitor.isNativePlatform()) {
    files.forEach(file => {
      NativeAudio.preload({
        assetId: file,
        assetPath: 'public/assets/audio/' + file + '.wav',
        audioChannelNum: 1,
        isUrl: false,
      });
    });
  }
}

function play(sound) {
  if (Capacitor.isNativePlatform()) {
    NativeAudio.play({
      assetId: sound,
    });
  }
}

export { preload, play };
