<template>
  <div class="bg-white h-full">
    <TopNav goBack> W czym możemy pomóc?</TopNav>
    <div class="h-full w-full bg-primary pt-24">
      <div class="grid gap-2 grid-flow-row w-fit justify-center items-center mx-auto">
        <GBtn
          v-for="(tab, idx) in tabs"
          class="text-white text-center border-2 border-gray-100/50 justify-center"
          :class="{ 'border-0 bg-gray-100/50': idx === 0 }"
          @click="openBrowser(tab.url)"
          ghost
          large
          :key="tab.selector"
          >{{ tab.text }}</GBtn
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import TopNav from '@/components/ui/partials/TopNav';

const tabs = [
  {
    text: 'Baza wiedzy',
    selector: 'knowledge',
    url: 'https://kickfleet.tawk.help',
  },
  {
    text: 'Kontakt',
    selector: 'contact',
    url: 'https://1008.3cx.cloud/callus/#unifiedapi',
  },
];

export default {
  data() {
    return {
      selected: tabs[0],
      tabs,
    };
  },
  methods: {
    async openBrowser(url) {
      if (Capacitor.isNativePlatform()) {
        await Browser.open({
          presentationStyle: 'popover',
          url,
        });
      } else {
        window.location.href = url;
      }
      return true;
    },
  },
  components: { TopNav },
};
</script>
