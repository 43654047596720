<template>
  <span v-if="station.slots?.length <= 0" class="p-3 text-gray-500 uppercase block">{{
    $t('slots.no-devices')
  }}</span>
  <template v-for="slot in station.slots" :key="slot.id">
    <div
      v-if="showEmptySlots || slot.scooter"
      class="p-2 border-b last:border-none"
      :class="{
        'cursor-pointer': slot.scooter,
      }"
      @click="select(slot.id)"
    >
      <SlotRow :scooterSlot="slot" :selected="selected === slot.id" />
    </div>
  </template>
</template>
<script>
import SlotRow from './SlotRow.vue';
import gql from 'graphql-tag';
export default {
  data() {
    return {
      selected: null,
      manualLoading: false,
      station: {},
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    station: {
      query: gql`
        query scooterParkingStation($id: Int!) {
          station: scooterParkingStation(id: $id) {
            slots: scooterParkingSlots {
              id
              isTemporary
              scooter: currentlyDockedScooter {
                isEnabled
                flespi_id
                id
                unique_id_for_qr
                device_remaining_mileage
                currentScooterReservation {
                  id
                  user {
                    id
                  }
                }
              }
              status
            }
          }
        }
      `,
      variables() {
        return {
          id: Number(this.stationId),
        };
      },
      pollInterval: 3000,
    },
  },
  computed: {},
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    stationId: {
      type: Number,
      required: true,
    },
    showEmptySlots: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    select(idx) {
      this.selected === idx ? (this.selected = null) : (this.selected = idx);
    },
  },
  components: { SlotRow },
};
</script>
