<template>
  <div class="relative" :style="{ transform: `translateX(${step * elWidth}px)` }">
    <div
      class="z-20 h-full w-full overflow-hidden shadow-2xl origin-left"
      :style="{ transform: `scale(${1 - step * 0.3}) translateX(${step * 30}px)` }"
    >
      <slot name="right"></slot>
      <div
        ref="dragger"
        class="absolute left-0 top-0 h-full z-40"
        :class="step >= 1 ? 'w-full' : 'w-5'"
        @click="draggerClicked"
      ></div>
    </div>
    <div
      class="-translate-x-full absolute left-0 top-0 w-[280px] md:w-[350px] max-w-screen h-full z-10 py-6 overflow-hidden"
      :style="{ opacity: step }"
      ref="drawer"
    >
      <slot name="left"></slot>
    </div>
  </div>
</template>
<script>
import Hammer from 'hammerjs';

const animationTime = 0.25 * 1000;

export default {
  data() {
    return {
      step: 0,
      elWidth: 0,
      direction: 'right',
      interval: null,
    };
  },
  methods: {
    draggerClicked() {
      if (this.step >= 1) {
        this.close();
      }
    },
    animate() {
      const time = performance.now() - this.animationStart;
      let step = time / animationTime;
      step = this.startStep + (this.direction === 'right' ? step : -step);
      if (
        (this.direction === 'right' && this.step < 1) ||
        (this.direction === 'left' && this.step > 0)
      ) {
        this.step = Math.max(Math.min(step, 1), 0);
      } else {
        console.log('finished animation');
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    startAnimation() {
      if (!this.interval) {
        this.animationStart = performance.now();
        this.startStep = this.step;
        this.interval = setInterval(this.animate, 1000 / 60);
      }
    },
    open() {
      this.calcSize();
      this.direction = 'right';
      console.log('started animation');

      this.startAnimation();
    },
    close() {
      this.calcSize();
      this.direction = 'left';
      this.startAnimation();
    },
    calcSize() {
      this.elWidth = this.$refs.drawer.offsetWidth;
    },
  },
  mounted() {
    this.calcSize();

    const stage = this.$refs.dragger;
    // console.log(stage);
    this.mc = new Hammer.Manager(stage);
    // create a recognizer
    const Pan = new Hammer.Pan();
    // add the recognizer
    this.mc.add(Pan);
    this.mc.on('panstart', () => {
      this.calcSize();
      if (this.step >= 1) {
        this.direction = 'left';
      } else {
        this.direction = 'right';
      }
    });
    this.mc.on('panmove', e => {
      let delta;
      if (this.direction === 'right') {
        delta = e.deltaX;
      } else {
        delta = this.elWidth + e.deltaX;
      }
      this.step = Math.max(Math.min(delta / this.elWidth, 1), 0);
    });
    this.mc.on('panend', () => {
      if (this.direction === 'right' && this.step < 0.3) {
        this.direction = 'left';
      } else if (this.direction === 'left' && this.step > 0.7) {
        this.direction = 'right';
      }
      this.startAnimation();
    });
  },
};
</script>
