import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faBars,
  faLocationArrow,
  faQrcode,
  faListUl,
  faCircleNotch,
  faXmark,
  faPen,
  faCaretRight,
  faMapMarkerAlt,
  faMapLocationDot,
  faBell,
  faClock,
  faRoad,
  faWallet,
  faPowerOff,
  faSyncAlt,
  faArrowLeft,
  faChevronRight,
  faExclamationTriangle,
  faUser,
  faEnvelope,
  faTerminal,
  faRoute,
  faStopwatch,
  faPlus,
  faMinus,
  faWrench,
  faInfoCircle,
  faArrowTrendDown,
  faArrowTrendUp,
  faStar,
  faPause,
  faArrowRightToBracket,
  faPlay,
  faPaperPlane,
  faCamera,
  faMagnifyingGlass,
  faClockRotateLeft,
  faChargingStation,
  faListOl,
  faAsterisk,
  faCheck,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircleQuestion,
  faCreditCard,
  faUser as faUser2,
  faCircleStop,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faBars,
  faLocationArrow,
  faQrcode,
  faListUl,
  faCircleNotch,
  faXmark,
  faPen,
  faCaretRight,
  faMapMarkerAlt,
  faMapLocationDot,
  faBell,
  faClock,
  faUser,
  faUser2,
  faRoad,
  faWallet,
  faPowerOff,
  faSyncAlt,
  faArrowLeft,
  faChevronRight,
  faExclamationTriangle,
  faEnvelope,
  faTerminal,
  faCircleQuestion,
  faRoute,
  faStopwatch,
  faPlus,
  faMinus,
  faWrench,
  faInfoCircle,
  faArrowTrendDown,
  faArrowTrendUp,
  faStar,
  faPause,
  faArrowRightToBracket,
  faPlay,
  faPaperPlane,
  faCamera,
  faMagnifyingGlass,
  faClockRotateLeft,
  faCreditCard,
  faChargingStation,
  faListOl,
  faAsterisk,
  faCheck,
  faTrash,
  faCircleStop
);

export default FontAwesomeIcon;
