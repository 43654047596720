<template>
  <div class="h-full w-full flex flex-col justify-stretch bg-gray-50">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      {{ $t('wallet.your-wallet') }}
    </TopNav>
    <div class="grow shrink overflow-hidden">
      <GScrollBar>
        <div class="flex flex-col justify-between p-4">
          <div class="grid gap-4">
            <CreditCard v-if="loading" class="bg-gray-200 shadow-lg" disabled>
              <template #brand>
                <GLoadBox class="w-12 h-6 rounded shadow" />
              </template>
              <template #default>
                <div>
                  <GLoadBox class="w-full h-4 rounded mb-2 shadow" />
                  <GLoadBox class="w-14 h-4 rounded shadow" />
                </div>
              </template>
            </CreditCard>
            <template v-for="method in paymentMethods" :key="method.id">
              <CreditCard v-if="method.type === 'card'" class="card-gradient shadow-lg">
                <template #addon>
                  <span v-if="method.id === defaultPayment">
                    <FaIcon icon="check" /> {{ $t('wallet.default') }}
                  </span>
                </template>
                <template #brand>
                  <div class="drop-shadow">{{ method.card.brand }}</div>
                </template>
                <template #default>
                  <div class="drop-shadow">
                    <div
                      v-if="method.billing_details.name"
                      class="tracking-wider uppercase text-sm"
                    >
                      {{ method.billing_details.name }}
                    </div>
                    <div>
                      <span class="mr-1" v-for="idx in 3" :key="idx">
                        <FaIcon
                          class="h-3 w-3 align-baseline"
                          icon="asterisk"
                          v-for="idx in 4"
                          :key="idx"
                        />
                      </span>
                      <span>{{ method.card.last4 }}</span>
                    </div>
                    <div>{{ method.card.exp_month }}/{{ method.card.exp_year }}</div>
                  </div>
                </template>
                <template #options>
                  <LabeledButton
                    v-if="method.id !== defaultPayment"
                    icon="check"
                    :text="$t('wallet.set-as-default')"
                    :loading="editLoading"
                    :disable="editLoading"
                    @click="setDefaultCard(method.id)"
                    ghost
                  />
                  <LabeledButton
                    icon="trash"
                    :text="$t('wallet.delete')"
                    :loading="editLoading"
                    :disable="editLoading"
                    @click="deleteCard(method.id)"
                    ghost
                  />
                </template>
              </CreditCard>
              <div v-else>{{ method }}</div>
            </template>
            <CreditCard
              v-if="config.stripe?.userCanAddCards && !loading"
              class="border border-primary shadow-inner"
              @clicked="addPaymentMethod"
              :disabled="addLoading"
            >
              <div
                class="h-full w-full flex flex-col justify-center items-center text-primary drop-shadow-none"
              >
                <FaIcon
                  :icon="!addLoading ? 'plus' : 'circle-notch'"
                  class="block mb-2"
                  :class="{ 'animate-spin': addLoading }"
                />
                <span v-if="!addLoading">{{ $t('wallet.add-payment-method') }}</span>
              </div>
            </CreditCard>
            <CreditCard class="bg-primary/20 border-2 border-primary shadow-lg my-8">
              <template #addon>
                <span class="text-primary"> Środki Kickfleet </span>
              </template>
              <template #brand>
                <div class="text-primary">
                  <img class="h-8" src="/assets/img/transparent_logo.svg" alt="" />
                </div>
              </template>
              <template #default>
                <div class="text-primary flex justify-between">
                  <div>
                    <div>{{ $t('wallet.account-balance') }}:</div>
                    <div><UserBalance class="font-bold" /></div>
                  </div>
                  <GBtn
                    v-if="config.stripe?.userCanRechargeAccount"
                    type="button"
                    class="relative shadow shadow-primary/50 rounded-lg p-4 text-primary text-center border border-primary"
                    @click="$refs.charge.openModal()"
                    ghost
                  >
                    <span> {{ $t('wallet.add') }} <FaIcon icon="plus" class="ml-1" /></span>
                  </GBtn>
                </div>
              </template>
            </CreditCard>

            <!-- <button
              type="button"
              class="relative shadow rounded-lg p-4 text-gray-700 text-center bg-gray-100"
              @click="$router.push('/wallet/transactions')"
            >
              <span>
                <FaIcon icon="clock-rotate-left" /> {{ $t('wallet.transactions-history') }}
              </span>
            </button> -->
            <button
              type="button"
              class="relative shadow rounded-lg p-4 text-gray-700 text-center bg-gray-100"
              @click="$router.push('/help/center')"
            >
              <span>
                <FaIcon :icon="['far', 'circle-question']" /> {{ $t('help.need-help') }}
              </span>
            </button>
          </div>
        </div>
      </GScrollBar>
    </div>
    <ChargeAccount ref="charge" @finished="refetch()" />
  </div>
</template>
<script>
import TopNav from '@/components/ui/partials/TopNav';
import UserBalance from '@/components/ui/partials/UserBalance';
import gql from 'graphql-tag';
import CreditCard from '@/components/CreditCard';
import { addPaymentMethod } from '@/stripe';
import LabeledButton from '@/components/ui/LabeledButton';
import ChargeAccount from '@/components/ChargeAccount';
import config from '@config';

export default {
  name: 'WalletPage',
  data() {
    return {
      paymentMethods: [],
      icons: {},
      addLoading: false,
      editLoading: false,
      defaultPayment: null,
      config,
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.paymentMethods.loading && !this.paymentMethods.length;
    },
  },
  apollo: {
    paymentMethods: {
      query: gql`
        query {
          paymentMethods: getPaymentsMethodsFromStripe
        }
      `,
      update: data => data.paymentMethods.data,
    },
    defaultPayment: {
      query: gql`
        query {
          whoAmI {
            user {
              stripeCustomer {
                defaultPayment: default_stripe_payment_id
              }
            }
          }
        }
      `,
      update: data => data.whoAmI.user.stripeCustomer.defaultPayment,
    },
  },
  methods: {
    alert(text) {
      alert(text);
    },
    async addPaymentMethod() {
      this.addLoading = true;
      await addPaymentMethod();
      this.addLoading = false;
      this.refetch();
    },
    refetch() {
      this.$apollo.queries.paymentMethods.refetch();
      this.$apollo.queries.defaultPayment.refetch();
    },
    async setDefaultCard(id) {
      this.editLoading = true;
      await this.$apollo
        .mutate({
          mutation: gql`
          mutation {
            setDefaultStripePaymentId(default_stripe_payment_id: "${id}")
          }
          `,
        })
        .catch(err => {
          alert(err.message || err);
        });
      this.refetch();
      this.editLoading = false;
    },
  },
  activated() {
    this.refetch();
  },

  components: {
    UserBalance,
    TopNav,
    CreditCard,
    LabeledButton,
    ChargeAccount,
  },
};
</script>
