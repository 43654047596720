<template>
  <router-view v-slot="{ Component }">
    <div ref="dragger">
      <transition
        :name="cancel ? 'cancel-slide' : swiping ? '' : getTransition"
        @leave="onLeave"
        @enter="onEnter"
        @before-leave="onBeforeLeave"
        @after-leave="onAfterLeave"
        @before-enter="onBeforeEnter"
        @after-enter="onAfterEnter"
      >
        <keep-alive include="MapPage">
          <component
            :is="Component"
            ref="child"
            class="bg-white absolute left-0 top-0 w-full"
            :class="{ 'pointer-events-none': swiping }"
          />
        </keep-alive>
      </transition>
    </div>

    <!-- <div
      
      class="absolute left-0 top-0 h-full w-full z-20 bg-red-500/30 pointer-events-none"
      :class="{ hidden: !canGoBack }"
    ></div> -->
  </router-view>
</template>
<script>
import Hammer from 'hammerjs';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      swiping: false,
      leaveEl: null,
      enterEl: null,
      lastRoute: null,
      cancel: false,
      doneHooks: [],
      left: 0,
    };
  },
  computed: {
    ...mapGetters({
      getTransition: 'ui/getTransition',
    }),
    canGoBack() {
      return !this.$route.meta.noBack;
    },
  },
  inject: ['screenHeight', 'screenWidth'],
  methods: {
    checkQuery(route) {
      if (route.query.action) {
        console.log('query action detected');
        const parsed = JSON.parse(decodeURIComponent(route.query.action));
        const child = this.$refs.child;
        const actions = child?.routeActions;
        if (actions && actions[parsed.type]) {
          actions[parsed.type](parsed);
        }
        this.$router.replace({ query: { ...route.query, action: undefined } });
      }
    },
    onLeave(el, done) {
      if (this.cancel) {
        // console.log(performance.now(), 'is cancel');
        setTimeout(() => {
          // console.log(performance.now(), 'done cancel');
          done();
          this.cancel = false;
        }, 600);
      } else if (this.swiping) {
        this.doneHooks.push(done);
      } else {
        // console.log('onLeave', 'done');
        setTimeout(() => {
          done();
        }, 600);
      }
    },
    onEnter(el, done) {
      if (this.cancel) {
        // console.log(performance.now(), 'is cancel');
        setTimeout(() => {
          // console.log(performance.now(), 'done cancel');
          done();
          this.cancel = false;
        }, 600);
      } else if (this.swiping) {
        this.enterEl = el;
        this.doneHooks.push(done);
      } else {
        // console.log('onEnter', 'done');
        setTimeout(() => {
          done();
        }, 600);
      }
    },
    onBeforeLeave(el) {
      if (this.swiping) {
        // console.log('onBeforeLeave', el);
        this.leaveEl = el;
        el.classList.add('finish-slide-leave-active');
        // el.classList.add('pointer-events-none');
      }
    },
    onBeforeEnter(el) {
      if (this.swiping) {
        // console.log('onBeforeEnter', el);
        this.enterEl = el;
        el.classList.add('finish-slide-enter-active');
        // el.classList.add('pointer-events-none');
      }
    },
    onAfterLeave(el) {
      // console.log('onAfterLeave', el);
      el.classList.remove('finish-slide-leave-active');
      el.classList.remove('finish-slide-leave-to');
      el.style.transform = null;
    },
    onAfterEnter(el) {
      // console.log('onAfterEnter', el);
      el.classList.remove('finish-slide-enter-active');
      el.classList.remove('finish-slide-enter-to');
      el.style.transform = null;
    },
  },
  beforeRouteUpdate(to) {
    this.checkQuery(to);
  },
  mounted() {
    this.checkQuery(this.$route, this.$router.replace);

    const stage = this.$refs.dragger;
    // console.log(stage);
    this.mc = new Hammer.Manager(stage, { touchAction: 'pan-x', domEvents: true });
    // create a recognizer
    const Pan = new Hammer.Pan({ direction: Hammer.DIRECTION_RIGHT });
    // add the recognizer
    this.mc.add(Pan);
    // subscribe to events
    // this.mc.on('panstart', e => {
    //   if (e.target.classList.contains('disablePageDrag') || !this.canGoBack) return;

    //   if (!this.swiping) {
    //     console.log('panstart');
    //     this.lastRoute = this.$route;
    //     this.swiping = true;
    //     // this.$nextTick(() => {
    //     this.$router.back();
    //     // });
    //   }
    // });
    // this.mc.on('panmove', e => {
    //   if (e.target.classList.contains('disablePageDrag')) return;

    //   if (this.swiping) {
    //     // do something cool
    //     const delta = Math.max(e.deltaX, 0);
    //     // const sliderWidth = this.$refs.wrapper.offsetWidth - this.$refs.dragger.offsetWidth;
    //     const percent = Math.ceil((delta / this.screenWidth) * 100);
    //     // console.log('percent', percent);
    //     this.left = percent;
    //     // if (this.leaveEl) {
    //     this.leaveEl && (this.leaveEl.style.transform = `translateX(${percent}%)`);
    //     const scale = Math.max(1 + (100 - percent * 3) / 1000, 1);
    //     this.enterEl && (this.enterEl.style.transform = `scale(${scale})`);
    //   }
    //   // }
    //   // stage.style.transform = 'rotate(' + rotation + 'deg)';}
    // });
    // this.mc.on('panend', e => {
    //   if (e.target.classList.contains('disablePageDrag')) return;
    //   console.log('panend');
    //   if (this.left > 30) {
    //     // this.$router.back();
    //     if (this.leaveEl) {
    //       this.leaveEl.classList.add('finish-slide-leave-to');
    //       this.leaveEl.style.transform = 'translateX(100%)';
    //     }
    //     if (this.enterEl) {
    //       this.enterEl.classList.add('finish-slide-enter-to');
    //       this.enterEl.style.transform = 'scale(1)';
    //     }

    //     const doneHooks = this.doneHooks.slice();
    //     setTimeout(() => {
    //       // console.log('ddone', doneHooks.length);

    //       doneHooks.forEach(done => {
    //         // console.log('done', done);
    //         done();
    //       });
    //     }, 300);
    //   } else {
    //     this.swiping = false;
    //     this.cancel = true;
    //     this.$nextTick(() => {
    //       this.$router.push(this.lastRoute);
    //     });
    //   }

    //   this.doneHooks = [];
    //   this.leaveEl = null;
    //   this.enterEl = null;
    //   this.swiping = false;
    // });
  },
};
</script>
