<template>
  <span> {{ user.name || user.email }} </span>
</template>
<script>
import gql from 'graphql-tag';
import * as Sentry from '@sentry/capacitor';
export default {
  data() {
    return {
      user: {},
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    user: {
      query: gql`
        query {
          whoAmI {
            user {
              name
              email
            }
          }
        }
      `,
      update: data => {
        Sentry.setUser({ email: data.whoAmI.user.email });
        return data.whoAmI.user;
      },
    },
  },
};
</script>
