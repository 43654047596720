<template>
  <div v-if="getSrc" class="overflow-hidden">
    <img :src="getSrc" />
  </div>
</template>
<script>
import getMapUrl from '@/utils/getMapUrl';

export default {
  inject: ['reservation'],
  computed: {
    getSrc() {
      return getMapUrl(
        this.reservation.startingScooterParkingSlot
          ? this.reservation.startingScooterParkingSlot.scooterParkingStation
          : {},
        this.reservation.endingScooterParkingSlot
          ? this.reservation.endingScooterParkingSlot.scooterParkingStation
          : {},
        this.reservation.reservationTrackHistoryJson &&
          this.reservation.reservationTrackHistoryJson.result
          ? this.reservation.reservationTrackHistoryJson.result
          : null
      );
    },
  },
};
</script>
