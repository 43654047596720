<template lang="">
  <div class="w-full flex justify-stretch items-center">
    <img src="/assets/img/devices/es4.png" class="w-10 object-contain mr-4" alt="" />
    <div class="grow shrink">
      <div class="flex justify-between">
        <span>
          <span v-if="admin" class="text-bold text-sm bg-gray-500 rounded p-1 text-white"
            >#{{ reservation.id }}</span
          >
          {{ format(reservation.startDate) }}</span
        >
        <span v-if="reservation.totalCostInCents && !hidePrice" class="text-right text-primary"
          >PLN {{ reservation.totalCostInCents / 100 }}</span
        >
      </div>
      <div class="flex justify-between items-start text-sm">
        <div class="flex flex-col text-left">
          <span class="text-gray-500 text-left"
            >{{ $t('scooter.electric-scooter') }}
            <span
              v-if="
                (admin || showQr) && reservation.scooter && reservation.scooter.unique_id_for_qr
              "
              class="text-primary"
            >
              #{{ reservation.scooter.unique_id_for_qr }}
            </span></span
          >
          <span
            v-if="showMilage && reservation.scooter?.device_remaining_mileage"
            class=""
            :class="{
              'text-red-400': reservation.scooter.device_remaining_mileage <= 10,
              'text-yellow-400': reservation.scooter.device_remaining_mileage > 10,
              'text-primary': reservation.scooter.device_remaining_mileage > 25,
            }"
          >
            {{ $t('scooter.range') }}:
            {{ Math.floor(reservation.scooter.device_remaining_mileage) }} km
          </span>
        </div>
        <span
          v-if="getState(reservation.status) !== 'error'"
          class="text-right first-letter:uppercase p-1 rounded"
          :class="{
            'bg-primary text-white': getState(reservation.status) === 'active',
            'bg-red-500 text-white': getState(reservation.status) === 'error',
          }"
        >
          <FaIcon
            v-if="getState(reservation.status) === 'error'"
            icon="exclamation-triangle"
            class="mr-1"
          />
          {{ getStateName(reservation.status) }}</span
        >
      </div>
      <div class="flex justify-start text-xs my-1" v-if="reservation.userRating">
        <FaIcon v-for="idx in reservation.userRating" class="text-yellow" icon="star" :key="idx" />
      </div>
      <div
        class="flex justify-between text-xs my-1"
        v-if="admin && reservation.user && reservation.user.email"
      >
        <span class="bg-gray-300 p-1 rounded text-white">{{ reservation.user.email }} </span>
      </div>
    </div>
    <div v-if="!hideArrow" class="grow-0 shrink-0 ml-3">
      <FaIcon icon="chevron-right" class="font-thin text-gray-500" />
    </div>
  </div>
</template>
<script>
import { formatDate as format } from '@/utils/formatDate';
import { getState, getStateName, getAudio } from '@/constants/rideStatuses';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import { play } from '@/audio';

export default {
  methods: {
    getState,
    getStateName,
    format,
  },
  computed: {
    currentStatus() {
      return this.reservation.status;
    },
  },
  watch: {
    currentStatus(to, from) {
      if (from) {
        console.log('status changed');
        if (getState(to) === 'error') {
          Haptics.notification({ type: NotificationType.Error });
        } else {
          Haptics.impact({ style: ImpactStyle.Heavy });
        }
        play(getAudio(to));
      }
    },
  },
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    showQr: {
      type: Boolean,
      default: false,
    },
    showMilage: {
      type: Boolean,
      default: false,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
