<template>
  <div class="rounded-lg overflow-hidden shadow-lg flex flex-col bg-white p-4">
    <slot></slot>
    <span class="capitalize text-md">{{ offer.name }}</span>
    <span class="text-gray-600 text-xs">{{ offer.description || '&nbsp;' }}</span>
    <span class="text-gray-400 text-xs">{{ getTimes || '&nbsp;' }}</span>
    <div class="flex flex-col justify-between mt-4 text-gray-700 text-xs">
      <template v-for="item in items" :key="item.key">
        <div class="flex justify-between items-end" v-if="offer[item.key] || item.showIfEmpty">
          <span class="mr-2 first-letter:uppercase text-left">
            {{ item.label }}
          </span>
          <span class="shrink-0">
            {{ item.calc ? item.calc(offer[item.key] || 0) : offer[item.key] || 0 }}
            {{ item.suffix }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { parsePrice } from '@/utils/price';
import { formatOfferDays, formatOfferHours } from '@/utils/formatDate';

export default {
  props: ['offer'],
  computed: {
    getTimes() {
      return this.offer.weeklyRepeatingEndDateForThisOffer &&
        this.offer.weeklyRepeatingStartDateForThisOffer
        ? `${formatOfferDays(this.offer.weeklyRepeatingStartDateForThisOffer)} - ${formatOfferDays(
            this.offer.weeklyRepeatingEndDateForThisOffer
          )}`
        : this.offer.dailyRepeatingEndTimeForThisOffer &&
          this.offer.dailyRepeatingStartTimeForThisOffer
        ? `${this.$t('global.everyday')}  ${formatOfferHours(
            this.offer.dailyRepeatingStartTimeForThisOffer
          )} - ${formatOfferHours(this.offer.dailyRepeatingEndTimeForThisOffer)}`
        : '';
    },
  },
  data() {
    return {
      items: [
        {
          key: 'startCost',
          label: this.$t('offer.price.perStart'),
          suffix: 'zł',
          calc: parsePrice,
          showIfEmpty: true,
        },
        {
          key: 'costPer1km',
          label: this.$t('offer.price.per1km'),
          suffix: 'zł',
          calc: parsePrice,
          showIfEmpty: true,
        },
        {
          key: 'costPerMinute',
          label: this.$t('offer.price.perMinute'),
          suffix: 'zł',
          calc: parsePrice,
          showIfEmpty: true,
        },
        {
          key: 'refundableDepositAmount',
          label: this.$t('offer.price.refundableDeposit'),
          suffix: 'zł',
          calc: parsePrice,
        },
        {
          key: 'costPerMinuteWhilePausedInCents',
          label: this.$t('offer.price.perMinutePaused'),
          suffix: 'zł',
          calc: parsePrice,
        },
      ],
    };
  },
};
</script>
