<template>
  <button
    class="w-full relative aspect-video cursor-pointer rounded-xl bg-opacity-70 text-left overflow-hidden min-h-[140px]"
  >
    <div
      class="absolute w-full h-full top-0 left-0 p-6 pt-4 text-white/60 text-md flex flex-col justify-between"
      @click="onClick"
    >
      <div class="w-full flex justify-between">
        <div class="capitalize"><slot name="addon"> </slot></div>
        <span class="capitalize"><slot name="brand"> </slot></span>
      </div>
      <slot name="default"></slot>
    </div>
    <transition
      enter-active-class="transition-transform ease-out "
      leave-active-class="transition-transform ease-out "
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="!!$slots.options && open"
        class="bg-secondary/70 w-full h-full absolute top-0 left-0 flex justify-around items-center text-white/90 text-md shadow-inner"
        @click.self="open = false"
      >
        <FaIcon @click="open = false" icon="xmark" class="absolute top-4 right-4" />
        <slot name="options"></slot>
      </div>
    </transition>
  </button>
</template>
<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    onClick() {
      if (this.$slots.options) {
        this.open = true;
      } else {
        this.$emit('clicked');
      }
    },
  },
};
</script>
