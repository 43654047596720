<template>
  <div class="flex flex-col">
    <div
      v-for="(record, idx) in history"
      class="flex justify-start items-center py-2 relative"
      :key="idx"
    >
      <div
        v-if="idx > 0"
        :class="['absolute left-1 w-1 ml-1 h-[50%] top-0', getColor(record.lastState)]"
      ></div>
      <div
        v-if="idx < history.length - 1"
        :class="['absolute left-1 w-1 ml-1 h-[50%] bottom-0', getColor(record.nextState)]"
      ></div>
      <div
        :class="[
          'shrink-0 z-10',
          getColor(record.nextState),
          record.status
            ? ' w-5 h-5 border-2 border-white rounded-full relative flex justify-center items-center'
            : 'w-2 h-1 ml-2',
        ]"
      >
        <FaIcon
          v-if="record.status"
          :icon="record.status === 'paused' ? 'pause' : 'play'"
          class="h-2 w-2 m-1 text-gray-200/60"
        />
      </div>
      <div class="flex flex-col ml-2 text-xs text-gray-600">
        <span class="text-2xs text-gray-400">{{ formatDate(record.date || record.start) }}</span>
        <span>
          <span v-if="!record.status">{{ parsePrice(record.amountInCents) }} zł - </span>
          <span class="text-2xs first-letter:uppercase">
            {{
              record.status
                ? `${getStateStatusChangedName(record.status)} (${parsePrice(
                    record.costPerMinuteInCents
                  )}zł /min)`
                : record.title
            }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from '@/utils/formatDate';
import { parsePrice } from '@/utils/price';
import { getStateStatusChangedName } from '@/constants/rideStatuses';

export default {
  inject: ['reservation'],
  computed: {
    history() {
      // We are merging the date ranges that overlaps with the same statuses
      // console.log([...(this.reservation.historyOfStatusChanges || [])]);
      // const statusHistorySorted = [...(this.reservation.historyOfStatusChanges || [])].sort(
      //   (a, b) => {
      //     return new Date(a.start) - new Date(b.start);
      //   }
      // );
      // // console.log(statusHistorySorted);
      // const statusHistory = statusHistorySorted.reduce((acc, cur) => {
      //   let last = acc[acc.length - 1];
      //   // console.log('checking: ', cur, ', against: ', last);
      //   if (last && last.status === cur.status) {
      //     last.start = Math.min(new Date(last.start), new Date(cur.start));
      //     last.end = Math.max(new Date(last.end), new Date(cur.end));
      //     // console.log('same: ', last);
      //   } else {
      //     // console.log('diff');
      //     acc.push({ ...cur });
      //   }
      //   return acc;
      // }, []);
      const merged = [
        ...(this.reservation.historyOfStatusChanges || []),
        ...(this.reservation.historyOfReservationCosts || []),
      ].sort((a, b) => {
        return new Date(a.status ? a.start : a.date) - new Date(b.status ? b.start : b.date);
      });
      let lastState = null;
      return merged.map(el => {
        const newEl = { ...el };
        newEl.lastState = lastState;
        if (newEl.status) {
          lastState = newEl.status;
        }
        newEl.nextState = lastState;
        return newEl;
      });
    },
  },
  methods: {
    formatDate,
    parsePrice,
    getStateStatusChangedName,
    getColor(state) {
      return state ? (state === 'active' ? 'bg-green-500' : 'bg-gray-500') : 'bg-primary';
    },
  },
};
</script>
