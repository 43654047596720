<template>
  <span> {{ balance / 100 }} zł</span>
</template>
<script>
import gql from 'graphql-tag';
export default {
  data() {
    return {
      balance: '',
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    balance: {
      query: gql`
        query {
          whoAmI {
            user {
              accountBalanceInCents
            }
          }
        }
      `,
      update: data => data.whoAmI.user.accountBalanceInCents,
      pollInterval: 5 * 1000,
    },
  },
};
</script>
