import * as Sentry from '@sentry/capacitor';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import config from '@config';

function getGeolocationForBrowser() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        geo => {
          resolve(geo);
        },
        err => {
          reject(err);
        }
      );
    } else {
      console.log('Geolocation not avaliable in browser');

      reject();
    }
  });
}

async function getGeolocation(notify = false, callback = null) {
  let coordinates;
  try {
    if (Capacitor.isNativePlatform()) {
      let permissions = await Geolocation.checkPermissions();
      if (permissions.location !== 'granted') {
        permissions = await Geolocation.requestPermissions();
        if (permissions.location !== 'granted') {
          console.log('User denied usage of geolocation');
          return false;
        }
      }
      // console.log('getting coords');
      coordinates = await Geolocation.getCurrentPosition();
    } else {
      coordinates = await getGeolocationForBrowser();
    }
    // console.log('coords', coordinates);

    coordinates = {
      lat: coordinates.coords.latitude,
      lng: coordinates.coords.longitude,
      accuracy: coordinates.coords.accuracy,
    };
  } catch (err) {
    if (config.dev?.mockLocation) {
      coordinates = {
        accuracy: 35,
        lat: 52.22636753730927,
        lng: 21.065318552521124,
      };
    } else {
      console.log(err);
      Sentry.captureException(err);
      if (notify) {
        console.log(err);
      }
      return false;
    }
  }
  if (callback) {
    callback(coordinates);
  }
  return coordinates;
}

async function startLocating(callback) {
  if (await getGeolocation(false, callback)) {
    console.log('starting interval');

    const interval = setInterval(() => {
      getGeolocation(false, callback);
    }, 15 * 1000);
    return interval;
  }
  return false;
}

export { getGeolocation, startLocating };
