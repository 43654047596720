<template>
  <GBtn
    v-bind="$attrs"
    :class="{ 'w-8 h-8': small, 'w-12 h-12': !small }"
    :ghost="small || ghost"
    @click="$emit('clicked')"
    circle
    ><span class="flex flex-col justify-center items-center">
      <FaIcon :icon="icon" :class="{ 'w-10 h-10': !small }" /><span :class="{ 'text-xs': small }">{{
        text
      }}</span>
    </span></GBtn
  >
</template>
<script>
export default {
  props: {
    icon: {
      // type: String,
      default: 'plus',
    },
    text: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
