<template>
  <div>
    <TopNav goBack />
    <GScrollBar>
      <div class="p-4 grid gap-2 grid-flow-row">
        <GBtn
          v-for="(button, idx) in buttons"
          :key="idx"
          @click="run(button.action)"
          :loading="loading"
          :disabled="loading"
        >
          {{ button.text }}
        </GBtn>
      </div>
      <div class="p-4">
        <img v-if="img" :src="`data:image/jpg;base64, ${img}`" alt="" />
        <p
          v-if="text"
          class="truncate whitespace-pre-wrap max-w-full overflow-wr bg-gray-100 shadow-inner p-4"
        >
          {{ text }}
        </p>
      </div>
    </GScrollBar>
    <SimpleModal
      :open="openModal"
      @close="openModal = false"
      class=""
      :closable="true"
      solid
      openModal
    >
      <div class="p-4">siemano</div>
    </SimpleModal>
  </div>
</template>
<script>
import { takePhoto } from '@/utils/takePhoto';
import TopNav from '@/components/ui/partials/TopNav';
import { addPaymentMethod } from '@/stripe';
import SimpleModal from '@/components/ui/SimpleModal';

export default {
  data() {
    return {
      img: null,
      text: '',
      loading: false,
      openModal: false,
      buttons: [
        {
          text: 'test camera',
          action: async () => {
            this.img = await takePhoto();
            console.log(this.img);
          },
        },
        {
          text: 'add payment method',
          action: async () => {
            this.text = await addPaymentMethod();
          },
        },
        {
          text: 'open modal',
          action: () => {
            this.openModal = true;
          },
        },
      ],
    };
  },
  methods: {
    async run(action) {
      this.loading = true;
      await action();
      this.loading = false;
    },
  },
  components: {
    TopNav,
    SimpleModal,
  },
};
</script>
