<template>
  <div>
    <GBtn @click="show = true" small circle>
      <span class="mx-1"> <FaIcon icon="plus" /> {{ $t('wallet.add') }} </span>
    </GBtn>
    <SimpleModal :open="show" @close="show = false" fixed>
      <div class="px-4 py-6 flex flex-col items-center">
        <IconSwitcher v-model="operator" class="h-8 w-8 mb-2" />
        <GInput v-model="title" />
        <GInputGroup
          @submit="recharge"
          v-model="amount"
          class="ml-1"
          type="number"
          min="0"
          step="0.1"
          :loading="loading"
        ></GInputGroup>
      </div>
    </SimpleModal>
  </div>
</template>
<script>
import SimpleModal from '@/components/ui/SimpleModal';
import IconSwitcher from './ui/IconSwitcher';
import gql from 'graphql-tag';

export default {
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      title: '',
      show: false,
      operator: null,
      amount: 0,
      loading: false,
    };
  },
  methods: {
    async recharge() {
      this.loading = true;

      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation{ modifyUserAccountBalance(input: {userId: ${this.userId}, amountInCents: ${
            this.operator * this.amount * 100
          }, title: "${this.title}"}){
            id
            email
            accountBalanceInCents
          }}
          `,
        });
        this.show = false;
        alert(
          `${this.$t('wallet.accound-charged')} ${
            response.data.modifyUserAccountBalance.email
          }. ${this.$t('wallet.account-balance')} ${
            response.data.modifyUserAccountBalance.accountBalanceInCents / 100
          }`
        );
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
  },
  components: { SimpleModal, IconSwitcher },
};
</script>
