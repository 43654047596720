import { apolloClient } from '@/apollo';
import gql from 'graphql-tag';
import store from '@/store';

const refreshToken = token => {
  return new Promise((resolve, reject) => {
    // set refresh token as access token
    store.commit('setTokens', { access_token: token, refresh_token: token });
    apolloClient
      .mutate({
        mutation: gql`
          mutation {
            refresh {
              access_token
              refresh_token
            }
          }
        `,
      })
      .catch(error => {
        reject(error);
      })
      .then(result => {
        console.log('received tokens', result);
        store.commit('setTokens', result.data.refresh);
        resolve(result.data.refresh.access_token);
      });
  });
};

export default refreshToken;
