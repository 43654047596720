<template>
  <GInput
    @update:modelValue="updated"
    :modelValue="modelValue"
    :loading="loading"
    :label="label"
    v-bind="$attrs"
    ref="input"
  >
    <GButton
      @click="$emit('submit')"
      gradient-to="l"
      class="w-12 m-0 rounded-none shadow-md z-10 justify-center"
      :submit="submit"
      :sharp="true"
      :loadingOverlay="loading"
      v-if="button"
    >
      <FaIcon icon="caret-right" />
    </GButton>
  </GInput>
</template>
<script>
import { GButton, GInput } from '.';
export default {
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    updated(val) {
      this.$emit('update:modelValue', val);
    },
  },
  components: {
    GInput,
    GButton,
  },
  props: {
    modelValue: {
      type: null,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: true,
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
