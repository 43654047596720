<template>
  <div @mouseleave="hovered = -1" class="w-fit flex">
    <div
      v-for="idx in 5"
      :key="`${idx}-${clicks}`"
      @mouseover="clickable ? (hovered = idx) : null"
      @click="clickable && setStars(idx)"
      class="text-gray-300 m-1 relative flex justify-center items-center"
      :class="{
        'text-yellow': idx <= hovered || idx <= modelValue,
        'cursor-pointer': clickable,
      }"
    >
      <FaIcon class="relative" :class="{ 'animate-ping-once': idx <= modelValue }" icon="star" />
      <div class="absolute flex justify-center items-center w-full h-full top-0 left-0">
        <FaIcon class="" icon="star" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hovered: -1,
      clicks: 0,
    };
  },
  methods: {
    setStars(idx) {
      this.clicks += 1;
      this.hovered = -1;
      this.$emit('set', idx);
      this.$emit('update:modelValue', idx);
    },
  },
  props: {
    modelValue: {
      type: Number,
      default: -1,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
