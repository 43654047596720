function calcDistance(coords1, coords2) {
  let lat1 = coords1.lat || coords1.latitude;
  let lat2 = coords2.lat || coords2.latitude;
  const lng1 = coords1.lng || coords1.longitude;
  const lng2 = coords2.lng || coords2.longitude;
  // console.log(lat1, lat2, lng1, lng2);
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

// return distance in km
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

// format distance from km
function formatDistance(km) {
  if (isNaN(km)) return false;
  const m = Math.round(km * 1000);
  return m >= 1000 ? (m / 1000).toPrecision(2) + ' km' : m + ' m';
}
export { calcDistance, formatDistance };
