<template>
  <div
    class="overflow-x-scroll overflow-y-visible w-full hide-scrollbar disablePageDrag"
    data-scroll-lock-scrollable
  >
    <div class="flex items-stretch w-fit z-10 pl-[50%]">
      <OfferCard
        v-for="(offer, idx) in allOffers"
        :offer="offer"
        @click.prevent="selectOffer(offer.id)"
        :key="offer.id"
        :ref="`offer-${offer.id}`"
        :class="[
          'border-2 border-dashed border-transparent w-48 shrink-0 grow transition-transform translate-x-[-50%]',
          {
            'scale-110 border-primary  text-primary': offer.id === modelValue,
            'mr-4': idx < allOffers.length - 1,
          },
        ]"
      />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import OfferCard from '@/components/OfferCard.vue';

export default {
  data() {
    return {
      offers: [],
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    offers: {
      query: gql`
        query {
          offers(input: {}) {
            id
            name
            description
            dailyRepeatingEndTimeForThisOffer
            dailyRepeatingStartTimeForThisOffer
            weeklyRepeatingEndDateForThisOffer
            weeklyRepeatingStartDateForThisOffer
            isActive
            isEnabled
            costPer1km
            costPerMinute
            costPerMinuteWhilePausedInCents
            startCost
            refundableDepositAmount
          }
        }
      `,
      fetchPolicy: 'network-only',
      pollInterval: 60 * 1000,
    },
  },
  components: { OfferCard },
  computed: {
    allOffers() {
      const mainOffer = this.scooter
        ? [
            {
              id: null,
              name: 'standard',
              startCost: this.scooter.costPerStart,
              costPerMinute: this.scooter.costPerMinuteInCents,
              costPer1km: this.scooter.costPer1000MetersInCents,
            },
          ]
        : [];
      return [...mainOffer, ...this.offers];
    },
  },
  props: ['modelValue', 'scooter'],
  methods: {
    selectOffer(id) {
      this.$refs[`offer-${id}`][0].$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      this.$nextTick(() => {
        this.$emit('update:modelValue', this.modelValue === id ? undefined : id);
      });
    },
  },
};
</script>
