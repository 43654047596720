<template>
  <div class="flex justify-between items-center">
    <div class="flex items-center">
      <span
        class="relative rounded p-3 bg-gray-500 text-white w-12 h-12 text-center mr-2 overflow-hidden"
      >
        <div
          v-if="hasAccount('spaceos')"
          class="w-full h-[27%] absolute bottom-0 left-0 bg-yellow p-1 flex justify-center"
        >
          <img src="/assets/img/officeme.png" class="" alt="" />
        </div>
        {{ user.id }}
      </span>
      <div class="flex flex-col">
        <div>
          <span class="text-gray-700">{{ user.name }} </span>
          <span
            class="ml-2 p-1 rounded text-white"
            :class="{
              'bg-gray-400': user.role === 'user',
              'bg-primary': user.role === 'admin',
              'bg-yellow': user.role === 'superadmin',
            }"
            >{{ user.role }}</span
          >
        </div>
        <div>
          <span class="text-gray-500">{{ user.email }}</span>
        </div>
      </div>
    </div>
    <span>{{ user.accountBalanceInCents / 100 }} zł</span>
  </div>
</template>
<script>
export default {
  methods: {
    hasAccount(string) {
      if (!this.user.socialAccounts) return false;
      if (!string) {
        return !!this.user.socialAccounts.length;
      }
      return !!this.user.socialAccounts.filter(e => e.providerType === string).length;
    },
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
