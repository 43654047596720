<template>
  <div class="h-full w-full bg-gray-100 flex flex-col justify-stretch">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <p class="text-primary text-lg">Sloty</p>
    </TopNav>
    <div class="grow shrink overflow-hidden z-0">
      <GScrollBar>
        <div class="py-4 h-full">
          <div
            v-for="(station, stationIdx) in stations"
            :key="`stations-${stationIdx}`"
            class="m-4 bg-white rounded shadow p-2"
          >
            <SimpleDropdown>
              <template #header>
                <div class="text-2xl font-black">
                  {{ station.address }}
                </div>
              </template>
              <template #default>
                <div
                  v-for="(slot, idx) in station.scooterParkingSlots"
                  class="p-4 relative flex flex-col"
                  :key="`slot-${stationIdx}-${idx}`"
                >
                  <SimpleDropdown class="relative mx-2 px-4 py-2 rounded shadow bg-white">
                    <template #header>
                      <div class="flex items-center">
                        <span class="text-md font-black text-primary"
                          >{{ slot.id_of_slot }} ({{ slot.id }})</span
                        >
                        <div
                          class="text-sm text-white bg-gray-500 mx-2 p-0 flex flex-wrap rounded overflow-hidden"
                        >
                          <div class="bg-gray-200 text-gray-600 px-2 h-full">status:</div>
                          <div class="px-2">{{ slot.status }}</div>
                        </div>
                        <span
                          class="text-sm px-2 text-white bg-green-500 mx-2 rounded"
                          v-if="slot.currentlyDockedScooter"
                          >scooter docked {{ slot.currentlyDockedScooter.unique_id_for_qr }}</span
                        >
                      </div>
                    </template>
                    <template #default>
                      <span class="my-2">sim_iccid: {{ slot.sim_iccid }}</span>
                      <div class="">
                        <SimpleDropdown
                          v-if="slot.currentlyDockedScooter"
                          class="m-2 p-4 rounded shadow"
                        >
                          <template #header> SCOOTER: </template>
                          <template #default>
                            <span
                              class="block my-2"
                              v-for="(val, key) in slot.currentlyDockedScooter"
                              :key="`val-${slot.id}-${key}`"
                            >
                              {{ key.replaceAll('_', ' ') }}:
                              <span
                                class="text-white p-1 rounded bg-gray-500 whitespace-nowrap"
                                :class="val === true && 'bg-green-500'"
                                >{{
                                  key === 'device_last_sync_at' ? format(val) : val || 'false'
                                }}</span
                              >
                            </span>
                          </template>
                        </SimpleDropdown>
                        <div class="my-4">
                          <GBtn @click="openSlot(slot.id)" :disabled="loading" small class="mx-4"
                            >Open</GBtn
                          >
                          <GBtn @click="closeSlot(slot.id)" :disabled="loading" small class="mx-4"
                            >Charging on/off</GBtn
                          >
                        </div>
                      </div>
                    </template>
                  </SimpleDropdown>
                  <!-- {{ slot }} -->
                </div>
              </template>
            </SimpleDropdown>
          </div>
        </div>
      </GScrollBar>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import TopNav from '@/components/ui/partials/TopNav';
import gql from 'graphql-tag';
import { formatDate as format } from '@/utils/formatDate';
import { mapGetters } from 'vuex';
import SimpleDropdown from '../../components/ui/SimpleDropdown';

export default {
  data() {
    return {
      stations: [],
      manualLoading: false,
    };
  },
  components: {
    TopNav,
    SimpleDropdown,
  },
  computed: {
    ...mapGetters({ userId: 'getUserId' }),
    loading() {
      return this.manualLoading;
    },
  },
  methods: {
    format,
    async reload() {
      console.log('reloading');
      await this.$apollo.queries.slots.refetch();
    },
    openSlot(id) {
      this.manualLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int!) {
              openScooterParkingSlot(scooterParkingSlotId: $id) {
                id
              }
            }
          `,
          variables: {
            id: Number(id),
          },
        })
        .then(() => {
          alert('command sent');
          this.manualLoading = false;
        })
        .catch(error => {
          alert(error);
          this.manualLoading = false;

          throw new Error(error);
        });
    },
    closeSlot(id) {
      this.manualLoading = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int!) {
              closeScooterParkingSlot(scooterParkingSlotId: $id) {
                id
              }
            }
          `,
          variables: {
            id: Number(id),
          },
        })
        .then(() => {
          alert('command sent');
          this.manualLoading = false;
        })
        .catch(error => {
          alert(error);
          this.manualLoading = false;
          throw new Error(error);
        });
    },
  },
  apollo: {
    stations: {
      query: gql`
        query scooterParkingStations {
          stations: scooterParkingStations {
            id
            lat
            lng
            address
            scooterParkingSlots {
              id
              sim_iccid
              sim_msisdn
              status
              id_of_slot
              scooterParkingStation {
                address
              }
              currentlyDockedScooter {
                id
                unique_id_for_qr
                serial_number
                device_is_charing
                device_battery_level
                device_remaining_mileage
                # device_all_params
                device_last_sync_at
                currentScooterReservation {
                  status
                  startDate
                  user {
                    name
                    id
                  }
                }
              }
            }
          }
        }
      `,
      pollInterval: 1 * 1000,
    },
  },
};
</script>
