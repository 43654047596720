<template>
  <div
    class="h-full w-full overflow-y-scroll overflow-x-hidden relative"
    @scroll.passive="onScroll"
    data-scroll-lock-scrollable
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    onScroll(event) {
      const element = event.target;
      this.$emit('scrolled');
      if (element.scrollTop === 0) {
        this.$emit('reachTop');
      }
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.$emit('reachEnd');
      }
    },
  },
};
</script>
