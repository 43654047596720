<template>
  <div>
    <div class="flex justify-between items-center w-full" @click="open = !open">
      <slot name="header"></slot>
      <FaIcon
        icon="chevron-right"
        class="font-thin text-gray-500"
        :class="open ? '-rotate-90' : 'rotate-90'"
      />
    </div>
    <slot v-if="open"></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      open: false,
    };
  },
};
</script>
