<template>
  <div class="bg-medium/20 backdrop-blur-sm rounded-lg shadow flex items-center justify-between">
    <div class="py-2 px-4">
      <span class="block"> <FaIcon v-if="icon" :icon="icon" /> {{ title }} </span>
      <span class="block text-gray-500">{{ body }}</span>
    </div>
    <button
      v-if="!permanent"
      class="flex justify-center items-center p-2 m-2 rounded-full"
      @click="$emit('close')"
    >
      <FaIcon icon="xmark" class="block h-4 w-4 text-gray-500" fixed-width />
    </button>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    permanent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
