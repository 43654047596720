<template>
  <div
    :class="{
      'fixed bottom-0 left-0 z-50 flex justify-center items-center w-full': active,
      relative: !active,
    }"
  >
    <div
      :class="[
        'fixed bottom-0 left-0  transition-opacity overflow-hidden duration-500  z-20  h-[100vh] w-[100vw] opacity-100 bg-primary/20',
        manual || startingRide ? 'pointer-events-auto' : 'pointer-events-none opacity-0',
      ]"
      @click.self="cancel()"
    >
      <div
        :class="[
          'before:bg-white before:absolute before:left-0 before:top-0 before:h-full before:w-full before:rounded-xl before:overflow-hidden before:-z-10 shadow-2xl h-[75%] w-full absolute left-0 bottom-0 z-0 duration-300 delay-100 transition-transform',
          { 'translate-y-full': !(manual || startingRide) },
        ]"
      >
        <div
          :class="[
            'h-full w-full flex flex-col items-center text-center transition-opacity opacity-100',
            { 'opacity-0': !(manual || startingRide) },
          ]"
        >
          <StartRide
            v-if="startingRide"
            ref="startRide"
            @error="startingRide = false"
            @success="cancel()"
          />
          <div class="p-8" v-else>
            <p class="mb-2 text-primary">{{ msg }}</p>
            <p class="mb-4 text-lg">{{ $t('qr.type-device-code') }}</p>
            <form @submit.self.prevent="startRide(manualInput)">
              <GInputGroup
                :loading="formLoading"
                ref="input"
                v-model="manualInput"
                type="tel"
                v-mask="'###-###'"
                @submit="startRide(manualInput)"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ 'padding-bottom': active ? safeArea.bottom : '' }">
      <GBtn
        :circle="true"
        :loadingOverlay="loading"
        :class="[
          'pointer-events-auto  relative w-fit',
          manual || startingRide ? ' text-alert' : 'shadow-lg',
          { 'z-20': active },
        ]"
        :large="true"
        gradient-to="b"
        :ghost="manual || startingRide"
        @click="active ? cancel() : startScan()"
      >
        <div :class="{ 'flex items-center px-2': cta && !active }">
          <div
            :class="{
              'scan-frame p-1 inline-flex items-center justify-center': !active,
              block: 'active',
            }"
          >
            <FaIcon
              v-if="!(manual || startingRide)"
              :icon="active ? 'xmark' : 'qrcode'"
              class="shrink-0 transition-[transform]"
              fixed-width
              :class="{
                'rotate-90': active,
                'h-10 w-10 block': !cta || active,
                'h-6 w-6 block': cta && !active,
              }"
            />
          </div>
          <span v-if="manual || startingRide" class="text-md">
            {{ $t('global.cancel') }}
          </span>
          <span v-else-if="cta && !active" class="text-md pl-2 font-bold">{{
            userActiveReservations.length ? $t('ride.rent-more') : $t('ride.scan-to-ride')
          }}</span>
        </div>
      </GBtn>
      <div
        class="absolute z-10 left-20 ml-2 top-0 h-full flex items-center columns-2"
        v-if="tooltip && !(loading || active)"
      >
        <FaIcon icon="caret-right" class="rotate-180 mr-2 text-secondary" />
        <div>
          <span class="text-xs text-secondary block whitespace-nowrap">{{
            $t('qr.scan-text1')
          }}</span>
          <span class="text-xs text-secondary block whitespace-nowrap">{{
            $t('qr.scan-text2')
          }}</span>
        </div>
      </div>

      <div
        class="fixed bg-white rounded-lg bottom-0 transition-[height] overflow-hidden ml-auto inset-center v-absolute-center z-0 shadow-lg w-screen flex justify-center items-center"
        :class="{ 'h-[126px]': scanning, 'h-0': !scanning }"
      >
        <div class="mx-6 w-6"></div>
        <div class="mx-6 w-14"></div>
        <GBtn
          class="mx-6 pointer-events-auto shadow-lg relative"
          :class="{ 'z-20': active }"
          gradient-to="b"
          @click="startManual"
        >
          <FaIcon icon="pen" class="block h-4 w-4" fixed-width />
        </GBtn>
      </div>
    </div>
  </div>
</template>
<script>
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import { mapActions } from 'vuex';
import StartRide from './StartRide';

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  inject: ['userActiveReservations', 'safeArea'],

  data() {
    return {
      loading: false,
      manual: false,
      scanning: false,
      formLoading: false,
      msg: '',
      manualInput: '',
      startingRide: false,
    };
  },
  computed: {
    active() {
      return this.scanning || this.manual || this.startingRide;
    },
  },
  props: {
    tooltip: {
      type: Boolean,
      default: false,
    },
    cta: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({ setAppOffset: 'ui/setAppOffset' }),
    startRide(qr, reserve = false) {
      this.cancel();
      this.startingRide = true;
      console.log('starting ride', qr);
      this.$nextTick(() => {
        this.$refs.startRide.startRide(qr.replace('-', ''), reserve);
      });
    },
    async startScan() {
      this.loading = true;
      try {
        const canScan = await this.checkPermissions();
        if (canScan) {
          BarcodeScanner.hideBackground();
          await BarcodeScanner.prepare();
          setTimeout(() => {
            this.loading = false;
            this.scanning = true;
            this.setAppOffset(126);
          }, 100);
          const result = await BarcodeScanner.startScan();
          console.log('scan result', result);
          if (result.hasContent) {
            this.startRide(result.content);
          } else throw new Error(this.$t('qr.scanning-failed'));
          // this.startRide(result)
        } else {
          await timeout(10);
          this.startManual();
        }
      } catch (err) {
        console.log(err);
        alert(err.message);
        this.cancel();
      }
    },
    stopScan() {
      this.setAppOffset(false);
      if (Capacitor.isNativePlatform()) {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
      }
    },
    async checkPermissions() {
      if (!Capacitor.isNativePlatform()) {
        this.msg = this.$t('qr.scanning-not-supported');
        return false;
      }
      // check or request permission
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        // the user granted permission
        return true;
      }
      this.msg = this.$t('qr.we-cant-access-camera');
      return false;
    },
    async startManual() {
      await this.cancel();
      this.manual = true;
      await timeout(600);
      this.$refs.input.focus();
    },
    async cancel() {
      this.manual = false;
      this.scanning = false;
      this.loading = false;
      this.formLoading = false;
      this.startingRide = false;
      await this.setAppOffset(false);
      await timeout(200);
      await this.stopScan();
      await timeout(100);
    },
  },
  deactivated() {
    if (this.active) this.stopScan();
  },
  beforeUnmount() {
    if (this.active) this.stopScan();
  },
  components: { StartRide },
};
</script>
