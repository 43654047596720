<template>
  <div>
    <div
      class="flex justify-stretch items-center py-2 border-b w-full"
      v-for="(record, idx) in records"
      :key="idx"
    >
      <FaIcon
        :icon="record.type === 'expense' ? 'arrow-trend-down' : 'arrow-trend-up'"
        :class="record.type === 'expense' ? 'text-red-500' : 'text-primary'"
      />
      <div class="flex flex-col text-left ml-2 w-full">
        <div class="flex justify-between items-center">
          <span class="text-sm"
            >{{ Math.abs(record.amountInCents / 100) }} zł<span v-if="record.title">
              - {{ record.title }}</span
            ></span
          ><span class="text-xs text-right text-gray-300 ml-2">{{ format(record.createdAt) }}</span>
        </div>
        <span class="text-xs text-gray-500">{{ record.source }}</span>
        <span class="text-gray-300 text-xs">{{ record.additional_info }}</span>
      </div>
    </div>
    <!-- <p class="text-xs">{{ record }}</p> -->
  </div>
</template>
<script>
import { formatDate as format } from '@/utils/formatDate';

export default {
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    format,
  },
};
</script>
