<template>
  <div
    class="flex flex-col bg-white z-10 shadow-upper transition-transform rounded-t-2xl relative"
    :style="{ 'padding-bottom': `${open ? safeArea.bottom : 0}` }"
    :class="{ 'translate-y-full': !open || !show }"
  >
    <div ref="dragger" class="relative" @click="openOrClose">
      <slot name="header"></slot>
      <div
        class="absolute py-2 top-0 left-0 flex justify-center items-center w-full after:h-1 after:w-8 after:rounded after:bg-gray-300 after:mx-auto pointer-events-none"
      ></div>
    </div>
    <GScrollBar
      class="shrink overflow-hidden"
      :class="{ 'transition-[height]': !dragging }"
      :style="{ height: height + 'px!important' }"
      @scrolled="scrollable = true"
      @reachTop="scrollable = false"
    >
      <div ref="content" class="touch-pan-y">
        <slot name="default"></slot>
      </div>
    </GScrollBar>
  </div>
</template>
<script>
import Hammer from 'hammerjs';

export default {
  data() {
    return {
      resizeObserver: null,
      lastPosition: 0,
      delta: 0,
      dragging: false,
      show: false,
      maxContentHeight: 0,
      actualContentHeight: 0,
      scrollable: false,
    };
  },
  inject: ['safeArea'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    height() {
      return Math.min(this.lastPosition - this.delta, this.contentHeight + 80);
    },
    contentHeight() {
      return Math.min(this.maxContentHeight, this.actualContentHeight);
    },
  },
  methods: {
    onMove(e) {
      this.delta = e.deltaY;
      // console.log(e);
      // this.height = delta;
    },
    onStart() {
      this.dragging = true;
      this.delta = 0;
    },
    onEnd(e) {
      this.dragging = false;
      const delta = -e.deltaY;
      if (delta >= this.contentHeight / 3 || (delta < 0 && -delta < this.contentHeight / 3)) {
        this.lastPosition = this.contentHeight;
      } else {
        this.lastPosition = 0;
      }
      this.delta = 0;
    },
    openDrawer() {
      this.lastPosition = this.contentHeight;
    },
    closeDrawer() {
      this.lastPosition = 0;
    },
    openOrClose() {
      this.lastPosition ? this.closeDrawer() : this.openDrawer();
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(event => {
      event.forEach(e => {
        switch (e.target) {
          case this.$refs.dragger:
            this.maxContentHeight = screen.height / 2 - e.contentRect.height;
            break;
          case this.$refs.content:
            this.actualContentHeight = e.contentRect.height;
            if (!this.dragging) {
              if (e.contentRect.height === 0) {
                this.closeDrawer();
              } else if (this.lastPosition > 0) {
                this.openDrawer();
              }
            }
            break;
        }
      });
    });

    this.resizeObserver.observe(this.$refs.dragger);
    this.resizeObserver.observe(this.$refs.content);

    const mc = new Hammer.Manager(this.$refs.dragger);
    // add the recognizer
    mc.add(new Hammer.Pan());
    // subscribe to events
    mc.on('panmove', this.onMove);
    mc.on('panstart', this.onStart);
    mc.on('panend', this.onEnd);

    const mc2 = new Hammer.Manager(this.$refs.content);
    // add the recognizer
    mc2.add(
      new Hammer.Pan({
        enable: () => {
          return !this.scrollable;
        },
      })
    );
    // subscribe to events
    mc2.on('panmove', this.onMove);
    mc2.on('panstart', this.onStart);
    mc2.on('panend', this.onEnd);

    this.show = true;
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
};
</script>
