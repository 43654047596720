<template>
  <div class="text-center">
    <div v-if="step === 0">
      <p class="text-gray-700 text-lg mb-1">{{ $t('ride.end.manual.title') }}</p>
      <p class="text-primary text-md">
        {{ $t('ride.end.manual.subtitle') }}
      </p>
      <div class="">
        <span class="block my-1 text-gray-500 text-xs">
          <input type="checkbox" v-model="accept1" class="align-middle" name="" id="accept1" />
          <label class="align-middle" for="accept1">
            {{ $t('ride.end.manual.checkbox') }}
          </label></span
        >
        <GBtn
          v-if="accept1"
          @click="step = 1"
          class="bg-primary/30 text-primary border-primary/30 border-2 mt-4"
          small
          ghost
          >{{ $t('ride.end.manual.button') }} <FaIcon icon="arrow-right-to-bracket"
        /></GBtn>
      </div>
    </div>
    <div
      v-if="step === 1"
      class="flex flex-col max-h-[80vh] overflow-hidden relative justify-items-stretch"
    >
      <span class="text-gray-500">{{ $t('station.select-station') }}:</span>
      <StationsList :callback="select" class="" small />
    </div>
    <div
      v-if="step === 2"
      class="flex flex-col max-h-[80vh] overflow-hidden relative justify-items-stretch text-center"
    >
      <p class="text-primary text-lg mb-1">{{ $t('ride.end.manual.instruction') }}</p>
      <img
        class="max-h-[30vh] w-auto object-contain mt-8"
        v-if="img"
        :src="`data:image/jpg;base64, ${img}`"
        alt=""
      />
      <p v-else class="text-gray-500 text-md">
        {{ $t('ride.end.manual.instruction2') }}
      </p>
      <span class="w-full flex flex-col items-center">
        <FaIcon
          v-if="loading"
          class="animate-spin my-8 drop-shadow h-12 w-12 text-primary"
          icon="circle-notch"
          fixed-width
        />
        <div class="mt-8" v-else>
          <GBtn v-if="img === null" @click="getImage" circle
            ><span class="px-2">
              <FaIcon class="mr-2" icon="camera" /> {{ $t('ride.end.manual.take-photo') }}</span
            ></GBtn
          >
          <GSlider v-else class="w-fit" @slided="end"> {{ $t('ride.end.manual.lock') }} </GSlider>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import StationsList from '../StationsList';
import { takePhoto, getBlob } from '@/utils/takePhoto';
import gql from 'graphql-tag';

export default {
  inject: ['reservation'],

  data() {
    return {
      step: 0,
      accept1: false,
      station: null,
      loading: false,
      img: null,
    };
  },
  methods: {
    select(id) {
      this.station = id;
      this.step = 2;
    },
    async getImage() {
      this.loading = true;
      this.img = await takePhoto();
      this.loading = false;
    },
    uploadImage() {
      return new Promise((resolve, reject) => {
        if (!this.img) {
          reject();
        }
        const blob = getBlob(this.img);
        console.log('blob', blob);
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($file: Upload!, $id: Int!) {
                uploadPhotoOfScooter(file: $file, reservationId: $id)
              }
            `,
            variables: {
              id: Number(this.reservation.id),
              file: blob,
            },
          })
          .catch(error => {
            console.log('error', error);
            alert(error);
            reject(error);
          })
          .then(() => {
            console.log('success');
            resolve();
          });
      });
    },
    async end() {
      this.loading = true;
      await this.uploadImage();
      console.log('uploaded');
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int!, $stationId: Int!) {
              endScooterReservationWithoutPuttingScooterInSlot(
                input: { reservationId: $id, scooterParkingStationId: $stationId }
              ) {
                id
              }
            }
          `,
          variables: {
            id: Number(this.reservation.id),
            stationId: Number(this.station),
          },
        })
        .catch(error => {
          alert(error);
          this.loading = false;

          throw new Error(error);
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.$emit('ended');
          }, 500);
        });
    },
  },
  components: { StationsList },
};
</script>
