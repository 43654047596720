import { ApolloClient, /* createHttpLink, */ InMemoryCache, from } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { setContext } from '@apollo/client/link/context';
import store from '@/store';
import { createUploadLink } from 'apollo-upload-client';
import config from '@config';

import errorLink from './errorLink';

// HTTP connection to the API
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: config.api.baseUrl + '/graphql',
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.getters['getToken'];
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    ScooterReservationWithCount: {
      keyArgs: false,
      merge(existing = { data: [] }, incoming, { args }) {
        const merged = existing.data ? existing.data.slice() : [];
        const end = (args.skip || 0) + Math.min(args.take || Infinity, incoming.data.length);
        for (let i = args.skip || 0; i < end; ++i) {
          merged[i] = incoming.data[i - (args.skip || 0)];
        }
        const obj = { count: incoming.count, data: merged.slice(0, incoming.count) };
        return obj;
      },

      read(existing = { data: [] } /* , { args } */) {
        return existing;
      },
    },
  },
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export { apolloClient, apolloProvider };
