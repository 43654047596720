import notifications from './notifications';

export default {
  state: { appOffset: false, transition: 'slide-left', kbHeight: 0 },
  mutations: {
    setAppOffset(state, value) {
      state.appOffset = value;
    },
    setTransition(state, value) {
      state.transition = value;
    },
    setKbHeight(state, value) {
      state.kbHeight = value;
    },
  },
  actions: {
    setAppOffset({ commit }, value) {
      commit('setAppOffset', value);
    },
    setKbHeight({ commit }, value) {
      commit('setKbHeight', value);
    },
  },
  getters: {
    getAppOffset: state => state.appOffset,
    getTransition: state => state.transition,
    getKbHeight: state => state.kbHeight,
  },
  namespaced: true,
  modules: { notifications },
};
