<template>
  <button v-if="arrowOnly" type="button" class="rounded-full p-2" @click="$router.back()">
    <FaIcon icon="arrow-left" class="relative block h-full" fixed-width />
  </button>
  <GButton
    v-else
    :gradient-to="ghost ? '' : 'b'"
    class="px-4"
    @click="$router.back()"
    :ghost="ghost"
  >
    <FaIcon icon="caret-right" class="rotate-180 mr-2" />
    {{ $t('global.go-back') }}
  </GButton>
</template>
<script>
import { GButton } from '.';
export default {
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
  components: {
    GButton,
  },
  props: {
    arrowOnly: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
