<template>
  <div
    class="flex justify-center items-center flex-col text-center"
    :class="{
      'text-primary': getState(reservation.status) === 'active',
      'text-red-500': getState(reservation.status) === 'error',
    }"
  >
    <span class="first-letter:uppercase mb-1" :class="small ? 'text-sm' : 'text-xl'">
      {{ getStateTitle(reservation.status) }}
    </span>
    <span class="text-gray-500" :class="small ? 'text-sm' : 'text-lg'">
      {{ getStateMsg(reservation.status) }}
    </span>
    <div
      class="mt-6"
      v-if="
        reservation.status === 'error_beginning_scooter_remaining_in_stand' &&
        reservation.statusErrorCountScooterAtBeginningRemainsInStand > 0
      "
    >
      <GBtn class="px-3" circle small :loading="unlockLoading" @click="retryUnlock()">{{
        $t('global.try-again')
      }}</GBtn>
    </div>
  </div>
</template>
<script>
import { getState, getStateMsg, getStateTitle } from '@/constants/rideStatuses';
import gql from 'graphql-tag';

export default {
  data() {
    return {
      unlockLoading: false,
    };
  },
  inject: ['reservation'],
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getState,
    getStateMsg,
    getStateTitle,
    retryUnlock() {
      if (this.unlockLoading) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int!) {
              scooterIsStuckRetryScooterReservation(input: { reservationId: $id }) {
                id
              }
            }
          `,
          variables: {
            id: Number(this.reservation.id),
          },
        })
        .catch(error => {
          alert(error);
          throw new Error(error);
        })
        .then(() => {
          setTimeout(() => {
            this.unlockLoading = false;
          }, 1500);
        });
    },
  },
};
</script>
