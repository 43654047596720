import store from '@/store';

export default async function roleGuard(to) {
  const role = store.getters['getUserRole'];
  if (to.meta.requiresRole && !to.meta.requiresRole.includes(role)) {
    console.log(
      `Unauthorized, this page requires roles: ${to.meta.requiresRole}, Your role is: ${role} `
    );
    return { path: '/' };
  }
  return true;
}
