<template>
  <SimpleModal :open="open" @close="open = false" cover>
    <div class="p-8 grid grid-flow-row gap-4 justify-center items-center text-center">
      <div class="flex justify-center items-center w-full">
        <GBtn
          v-for="value in values"
          :key="value"
          @click="
            selected = value;
            alert = '';
          "
          :loading="loading"
          :disabled="loading"
          class="text-primary border-2 mx-1"
          ghost
          >{{ value }} zł</GBtn
        >
      </div>
      <div v-if="alert" class="font-thin text-sm text-red-500">
        <span class="">{{ alert }}</span>
      </div>
      <GInput
        v-model="selected"
        type="number"
        min="0"
        step="0.1"
        :loading="loading"
        :disabled="loading"
        class="h-8 rounded"
        ref="input"
        @change="alert = ''"
      />
      <GBtn :loading="loading" :disabled="loading" @click="makePayment" class="w-fit px-4" circle
        >{{ $t('wallet.charge-account') }} <FaIcon icon="chevron-right"
      /></GBtn>
      <img class="h-8 opacity-50" src="/assets/img/stripe.svg" alt="" />
    </div>
  </SimpleModal>
</template>
<script>
import SimpleModal from '@/components/ui/SimpleModal';
import { makePayment } from '@/stripe';

export default {
  data() {
    return {
      loading: false,
      selected: null,
      values: [20, 50, 100],
      open: false,
      alert: '',
    };
  },
  methods: {
    openModal() {
      this.selected = null;
      this.open = true;
    },
    async makePayment() {
      if (this.selected < 20 || isNaN(this.selected)) {
        this.alert = this.$t('wallet.minimal-charge-amount');
        this.$refs.input.shake();
        return;
      }
      this.loading = true;
      await makePayment(this.selected * 100);
      this.loading = false;
      this.open = false;
      this.$emit('finished');
    },
  },
  components: { SimpleModal },
};
</script>
