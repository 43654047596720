<template>
  <div class="h-full w-full bg-gray-50 flex flex-col justify-stretch">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <p class="text-primary text-lg">Logi systemowe</p>
    </TopNav>
    <div class="grow shrink overflow-hidden z-0">
      <GScrollBar>
        <div class="py-4 h-full">
          <div
            v-for="(log, idx) in logs"
            class="border-b cursor-pointer p-4"
            :class="{
              'bg-red-100 border-red-200': !log.acceptedAt,
              'animate-pulse': clicked.includes(log._id),
            }"
            @click="readLog(log._id)"
            :key="idx"
          >
            <p>
              <span class="uppercase">{{ log.type }}</span>
              <span class="text-gray-500 ml-4">{{ log.module }}</span>
            </p>
            <p class="text-sm my-2">{{ log.message }}</p>
            <p class="text-gray-500 text-sm">{{ log.additional_info }}</p>
            <p class="text-gray-500 text-sm">{{ format(log.createdAt) }}</p>
            <p v-if="log.acceptedAt" class="text-gray-500 text-xs">
              przeczytano: {{ format(log.acceptedAt) }}
            </p>
          </div>
        </div>
      </GScrollBar>
    </div>
  </div>
</template>
<script>
import TopNav from '@/components/ui/partials/TopNav';
import gql from 'graphql-tag';
import { formatDate as format } from '@/utils/formatDate';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      logs: [],
      manualLoading: false,
      clicked: [],
    };
  },
  components: {
    TopNav,
  },
  computed: {
    ...mapGetters({ userId: 'getUserId' }),
    loading() {
      return this.$apollo.queries.logs.loading || this.manualLoading;
    },
  },
  methods: {
    format,
    async reload() {
      console.log('reloading');
      await this.$apollo.queries.logs.refetch();
    },
    readLog(id) {
      this.clicked.push(id);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: String!) {
              acceptLog(id: $id) {
                _id
              }
            }
          `,
          variables: {
            id,
          },
        })

        .catch(error => {
          alert(error);
          throw new Error(error);
        });
    },
  },
  apollo: {
    logs: {
      query: gql`
        query {
          logs {
            _id
            module
            moduleId
            type
            message
            additional_info
            createdAt
            acceptedAt
          }
        }
      `,
      pollInterval: 1 * 1000,
      result() {
        this.clicked = [];
      },
    },
  },
};
</script>
