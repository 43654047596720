<template>
  <div
    :style="{ padding: `${!noSafeArea && safeArea.bottom}` }"
    :class="{
      'bg-white w-full  shadow-upper': solid,
    }"
  >
    <div class="grid gap-2 grid-cols-[1fr_max-content_1fr] pointer-events-none">
      <div
        class="grid gap-2 pointer-events-none"
        :class="[
          between ? 'justify-start' : 'justify-end',
          vertical ? 'grid-flow-row items-start' : 'items-center grid-flow-col',
        ]"
      >
        <GGoBack v-if="goBack" class="pointer-events-auto shadow-lg mx-3 h-10" :small="true" />
        <GBtn
          v-for="(item, idx) in left"
          :key="idx"
          :loadingOverlay="item.loading"
          class="pointer-events-auto shadow-lg"
          :class="buttonClass"
          @click="$emit(`clicked`, idx)"
          gradient-to="b"
          :circle="item.circle"
          :large="item.circle"
          :ghost="!!buttonClass"
        >
          <FaIcon :icon="item.icon" class="block h-4 w-4" fixed-width />
        </GBtn>
      </div>
      <slot class="pointer-events-auto"></slot>
      <div
        class="grid gap-2 pointer-events-none"
        :class="[
          between ? 'justify-end' : 'justify-start',
          vertical ? 'grid-flow-row items-end' : 'items-center grid-flow-col',
        ]"
      >
        <GBtn
          v-for="(item, idx) in right"
          :key="idx"
          :loadingOverlay="item.loading"
          class="pointer-events-auto shadow-lg"
          :class="buttonClass"
          @click="$emit(`clicked`, left.length + idx)"
          gradient-to="b"
          :circle="item.circle"
          :large="item.large"
          :ghost="!!buttonClass"
        >
          <FaIcon :icon="item.icon" class="block h-4 w-4" fixed-width />
        </GBtn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['safeArea'],

  data() {
    return {};
  },
  props: {
    goBack: {
      type: Boolean,
      default: false,
    },
    noSafeArea: {
      type: Boolean,
      default: false,
    },
    solid: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    between: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    items: { type: Array, default: () => [] },
  },
  computed: {
    center() {
      return Math.ceil(this.items.length / 2);
    },
    left() {
      if (this.center === 0) return [];
      if (this.center === false) return [];
      return this.items.slice(0, this.center);
    },
    right() {
      if (this.center === 0) return [];
      if (this.center === false) return [];
      return this.items.slice(this.center);
    },
  },
};
</script>
