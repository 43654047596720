import { App as CapacitorApp } from '@capacitor/app';
import { Stripe } from '@capacitor-community/stripe';

function initUrlHandler(router) {
  CapacitorApp.addListener('appUrlOpen', function (event) {
    console.log('app opened from url', event);
    try {
      const slug = event.url.replace(/(.*):\/{2,}(app\/{1,}){0,1}/, '/');
      if (/same-page((\?|#).*)?$/gm.test(slug)) return;
      if (/stripe-callback((\?|#).*)?$/gm.test(slug)) {
        Stripe.handleURLCallback({ url: event.url });
        return;
      }
      const resolved = router.resolve(slug);
      if (slug && (!resolved || resolved.name === '404')) {
        throw new Error('Nieznana ścieżka aplikacji');
      }
      if (slug) router.push(resolved);
    } catch (err) {
      alert(err);
    }
  });
}

export default initUrlHandler;
