<template>
  <div class="flex flex-col items-center w-full h-full">
    <template v-if="!loading && scooter && scooter.id">
      <div class="border-b flex justify-center p-4 shadow-xl w-full">
        <div class="flex flex-col items-center -mt-16 w-fit">
          <img
            src="/assets/img/devices/es4.png"
            class="h-24 w-24 object-contain mr-4 drop-shadow z-50"
            alt=""
          />
          <div class="flex flex-col items-center justify-center text-center text-sm">
            <span class="first-letter:uppercase text-gray-700">{{
              $t('scooter.electric-scooter')
            }}</span>
            <span
              v-if="scooter.device_remaining_mileage"
              class="text-gray-500 first-letter:uppercase"
              >{{ $t('scooter.predicted-milage') }}
              <span class="font-semibold">{{ scooter.device_remaining_mileage }} km</span></span
            >
          </div>
        </div>
      </div>
      <div
        class="w-full flex flex-col items-center grow overflow-y-auto bg-gray-50 pb-14"
        data-scroll-lock-scrollable
      >
          <span class="text-black block text-md relative mx-4 mt-4"
            >{{ !reserve ? $t('offer.select-offer') : $t('scooter.reserve-for', [15]) }}
            <QuestionButton :text="false" class="pl-2 absolute top-0 right-0 translate-x-full"
          /></span>
          <span class="text-gray-500 block mx-4">{{
            $t(!reserve ? 'offer.select-offer-sub' : 'scooter.reserve-for-sub')
          }}</span>
        <SelectOffer :scooter="scooter" v-model="selectedOffer" class="p-4 pb-8" />
        <div
          :class="[
            'p-6  opacity-100 transition-opacity duration-300 ',
            { 'opacity-0 ': selectedOffer === undefined },
          ]"
        >
          <GSlider @slided="createReservation" :bounce="selectedOffer !== undefined" class="w-full">
            {{ $t(!reserve ? 'ride.start-ride' : 'scooter.reserve-now') }}
          </GSlider>
        </div>
      </div>
    </template>
    <FaIcon
      v-else
      class="animate-spin mt-24 drop-shadow h-20 w-20 text-primary"
      icon="circle-notch"
      fixed-width
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SelectOffer from './SelectOffer.vue';
import QuestionButton from './QuestionButton.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      scooter: null,
      loading: false,
      selectedOffer: null,
      reserve: false,
    };
  },
  computed: {
    ...mapGetters({
      userId: 'getUserId',
    }),
  },
  methods: {
    async createReservation() {
      this.loading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
            mutation{ createScooterReservation(input:{
              scooterId: ${this.scooter.id},
              startReservationImmediately: ${!this.reserve},
              offerId: ${this.selectedOffer},
            }){
              id
            }}
          `,
        });
        console.log('response2', response);
        setTimeout(() => {
          this.loading = false;

          this.$router.push({
            path: '/',
          });
          this.$emit('success');
        }, 1800);

        // this.$router.push('/ride/' + response.data.createScooterReservation.id);
      } catch (err) {
        alert(err);
        this.scooter = null;
        this.$emit('error', err);
        this.loading = false;
      }
    },
    async startRide(qr, reserve = false) {
      this.loading = true;
      this.reserve = reserve;
      try {
        const data = await this.fetchScooter(qr);
        console.log('data', data);
        this.scooter = data.scooter;
        if (
          this.scooter.currentScooterReservation &&
          this.scooter.currentScooterReservation.user.id !== this.userId
        )
          throw new Error(this.$t('scooter.scooter-is-taken'));
        if (!this.scooter.isEnabled) throw new Error(this.$t('scooter.scooter-is-unavaliable'));
      } catch (err) {
        alert(err);
        this.scooter = null;
        this.$emit('error', err);
      }
      this.loading = false;
    },
    async fetchScooter(qr) {
      const response = await this.$apollo.query({
        query: gql`
            query{
              scooter(unique_id_for_qr: "${qr}"){
                isEnabled
                id
                rfid_id
                unique_id_for_qr
                device_remaining_mileage
                costPerStart
                costPerMinuteInCents
                costPer1000MetersInCents
                currentScooterReservation{
                  id
                  user{
                    id
                  }
                }
              }
            }
          `,
        fetchPolicy: 'network-only',
      });
      console.log('response', response);
      return response.data;
    },
  },
  components: {
    SelectOffer,
    QuestionButton,
  },
};
</script>
