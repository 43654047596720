import store from '@/store';

export default async function authGuard(to) {
  const token = store.getters['getToken'];

  if (to.meta.requiresAuth) {
    if (!token) {
      console.log('no tokens');
      return {
        path: '/login',
        query: {
          redirect: to.path,
        },
      };
    }
  }

  return true;
}
