<template>
  <div class="h-full w-full bg-gray-100 flex flex-col justify-stretch">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <p class="text-primary text-lg">Sloty</p>
    </TopNav>
    <div class="grow shrink overflow-hidden z-0">
      <GScrollBar>
        <div class="p-4 h-full">
          <div
            v-for="offer in sorted"
            :class="['my-2 rounded', { 'opacity-50': !offer.isEnabled }]"
            :key="offer.id"
          >
            <OfferCard :offer="offer">
              <div>
                <div class="flex justify-between items-center">
                  <FaIcon :class="offer.isActive ? 'text-yellow' : 'text-gray-300'" icon="clock" />
                  <GSwitch
                    :val="offer.isEnabled"
                    @click.prevent="setEnabled(offer.id, !offer.isEnabled)"
                    :disabled="loading"
                  />
                </div>
                <span class="text-2xs text-gray-600"
                  >utworzono: {{ formatDate(offer.createdAt) }}</span
                >
              </div>
            </OfferCard>
          </div>
        </div>
      </GScrollBar>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import TopNav from '@/components/ui/partials/TopNav';
import gql from 'graphql-tag';
import { formatDate } from '@/utils/formatDate';
import OfferCard from '@/components/OfferCard';

export default {
  data() {
    return {
      offers: [],
      manualLoading: false,
    };
  },
  components: {
    TopNav,
    OfferCard,
  },
  computed: {
    loading() {
      return this.manualLoading;
    },
    sorted() {
      // sort by multiple params
      return [...this.offers].sort((a, b) => {
        return (
          b.isEnabled - a.isEnabled ||
          b.isActive - a.isActive ||
          new Date(b.createdAt) - new Date(a.createdAt)
        );
      });
    },
  },
  methods: {
    formatDate,
    setEnabled(id, enabled) {
      this.manualLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Float!, $enabled: Boolean!) {
              updateOffer(input: { offerId: $id, isEnabled: $enabled }) {
                id
              }
            }
          `,
          variables: {
            id: Number(id),
            enabled: enabled,
          },
        })
        .then(() => {
          alert('command sent');
          this.$apollo.queries.offers.refetch();
          this.manualLoading = false;
        })
        .catch(error => {
          alert(error);
          this.manualLoading = false;

          throw new Error(error);
        });
    },
  },
  apollo: {
    offers: {
      query: gql`
        query offers {
          offers(input: { showEnabledAndDisabled: true, showActiveAndInactive: true }) {
            id
            name
            description
            dailyRepeatingEndTimeForThisOffer
            dailyRepeatingStartTimeForThisOffer
            weeklyRepeatingEndDateForThisOffer
            weeklyRepeatingStartDateForThisOffer
            isActive
            isEnabled
            costPer1km
            costPerMinute
            costPerMinuteWhilePausedInCents
            startCost
            refundableDepositAmount
            createdAt
          }
        }
      `,
      pollInterval: 6 * 1000,
    },
  },
};
</script>
