<template>
  <div
    class="text-center flex flex-col justify-center items-center"
    v-if="reservation.status === 'end' && !reservation.userRating"
  >
    <span class="text-sm mb-1">{{ $t('ride.rate') }}</span>
    <RateStars class="text-xl" v-model="stars" clickable @set="open = true" />
    <SimpleModal
      :open="open"
      @close="
        open = false;
        stars = -1;
      "
      class="z-50"
    >
      <div class="p-4 flex flex-col items-center pt-6">
        <RateStars class="text-xl" v-model="stars" clickable />
        <GInputArea
          class="resize-y my-4"
          v-model="comment"
          :placeholder="`${$t('global.add-comment')}(${$t('global.optional')})`"
          :disabled="loading"
        />
        <div class="">
          <GBtn class="px-4" :loading="loading" @click="rate" circle small
            >{{ $t('global.send') }} <FaIcon class="ml-2" icon="paper-plane"
          /></GBtn>
        </div>
      </div>
    </SimpleModal>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import RateStars from './RateStars';
import SimpleModal from '@/components/ui/SimpleModal';

export default {
  name: 'RideRate',
  data() {
    return {
      stars: -1,
      comment: '',
      loading: false,
      open: false,
    };
  },
  methods: {
    rate() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int!, $rating: Int!, $feedback: String) {
              userFeedbackForReservation(
                input: { reservationId: $id, userRating: $rating, userFeedback: $feedback }
              ) {
                id
              }
            }
          `,
          variables: {
            id: Number(this.reservation.id),
            rating: this.stars,
            feedback: this.comment,
          },
        })
        .catch(error => {
          setTimeout(() => {
            alert(error);
            this.loading = false;
          }, 1500);
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        });
    },
  },
  inject: ['reservation'],
  components: { RateStars, SimpleModal },
};
</script>
