<template>
  <div>
    <p class="grow-0 text-lg text-primary bg-white relative my-4">Zaloguj się:</p>
    <div class="flex justify-center">
      <button
        class="rounded-lg mx-3 mb-4 shadow-lg bg-yellow flex justify-center items-center w-[65px] h-[65px]"
        @click="loginSpaceOS"
      >
        <img src="/assets/img/officeme.png" class="w-14" alt="" />
      </button>
      <button
        class="rounded-lg mx-3 mb-4 shadow-lg bg-gray-200 flex flex-col justify-center items-center w-[65px] h-[65px]"
        @click="$router.push('/login/email')"
      >
        <FaIcon icon="envelope" class="block h-8 w-8 text-gray-500 drop-shadow" fixed-width />
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import gql from 'graphql-tag';
import config from '@config';

export default {
  name: 'WelcomePage',
  data() {
    return {
      currentUrl: encodeURIComponent(
        (Capacitor.isNativePlatform()
          ? `${config.product.bundleId}.local://app/`
          : window.location.origin.toString()) + window.location.pathname.toString()
      ),
      login_url: config.api.baseUrl,
    };
  },
  computed: {
    tokens() {
      return {
        access_token: this.$route.query.token,
        refresh_token: this.$route.query.refresh_token,
      };
    },
  },
  watch: {
    tokens() {
      this.onTokenChange();
    },
  },
  methods: {
    ...mapMutations(['setUserId', 'setUserRole']),
    ...mapActions(['setTokens']),
    async openBrowser(url) {
      if (Capacitor.isNativePlatform()) {
        await Browser.open({
          url,
        });
      } else {
        window.location.href = url;
      }
      return true;
    },
    async closeBrowser() {
      if (Capacitor.getPlatform() !== 'android') {
        await Browser.close();
      }
      return true;
    },
    async loginSpaceOS() {
      await this.openBrowser(
        this.login_url + '/auth/loginSpaceos?url_to_redirect=' + this.currentUrl
      );
    },
    async onTokenChange() {
      console.log('checking tokens', this.tokens);
      if (this.tokens.access_token && this.tokens.refresh_token) {
        console.log('token detected');
        await this.closeBrowser();
        console.log(`token: ${this.tokens}`);
        await this.setTokens(this.tokens);
        this.$apollo
          .query({
            // Query
            query: gql`
              query {
                whoAmI {
                  user {
                    id
                    role
                  }
                }
              }
            `,
          })
          .then(({ data }) => {
            // Result
            this.setUserId(data.whoAmI.user.id);
            this.setUserRole(data.whoAmI.user.role);
            this.$apollo.provider.defaultClient.resetStore();
            this.$router.replace('/');
          })
          .catch(error => {
            // Error
            alert(error);
            throw new Error(error);
            // We restore the initial user input
          });
      }
    },
  },
  async mounted() {
    await this.onTokenChange();
  },
};
</script>
