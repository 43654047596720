const FULL_RESERVATION_PARAMS = ` id
            startDate
            endDate
            status
            totalCostInCents
            costPerMinuteInCents
            costPerStart
            costPer1000MetersInCents
            totalDistanceOfReservationInMeters
            totalReservationTimeInSeconds
            scooterParamsAtEndOfReservation
            scooterParamsAtStartOfReservation
            statusErrorCountScooterAtBeginningRemainsInStand
            reservationTrackHistoryJson
            predictedTotalCostInCents
            userRating
            scooter {
              id
              unique_id_for_qr
              flespi_id
              device_remaining_mileage
            }
            startingScooterParkingSlot {
              scooterParkingStation {
                address
                lat
                lng
              }
            }
            endingScooterParkingSlot {
              scooterParkingStation {
                address
                lat
                lng
              }
            }`;

export { FULL_RESERVATION_PARAMS };
