import { play } from '@/audio';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export default {
  state: { notifications: {}, increment: 0 },
  mutations: {
    addNotification(state, { notification, id }) {
      state.notifications[id] = notification;
      play('ping');
      Haptics.impact({ style: ImpactStyle.Medium });
    },
    increment(state) {
      state.increment += 1;
    },
    removeNotification(state, id) {
      if (state.notifications[id]) {
        delete state.notifications[id];
      }
    },
  },

  getters: {
    getNotifications: state => state.notifications,
  },
  actions: {
    addNotification({ commit, state }, notification) {
      const id = state.increment;
      commit('increment');
      commit('addNotification', { notification, id });
      if (notification.timeout) {
        setTimeout(() => {
          commit('removeNotification', id);
        }, notification.timeout);
      }
    },
  },
  namespaced: true,
};
