<template>
  <div class="h-full w-full bg-gray-50 flex flex-col justify-stretch">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <p class="text-primary text-lg">Wszystkie przejazdy</p>
    </TopNav>
    <RidesList admin />
  </div>
</template>
<script>
// @ is an alias to /src
import TopNav from '@/components/ui/partials/TopNav';
import RidesList from '@/components/ride/RidesList';
export default {
  components: {
    TopNav,
    RidesList,
  },
};
</script>
