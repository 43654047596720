<template>
  <div
    class="flex justify-around items-center"
    v-if="
      reservation.totalDistanceOfReservationInMeters ||
      reservation.totalReservationTimeInSeconds ||
      (reservation.startDate && reservation.status !== 'end')
    "
  >
    <div
      v-if="reservation.totalDistanceOfReservationInMeters"
      class="flex flex-col justify-center items-center text-gray-500"
    >
      <span class="text-primary text-sm">
        <span class="text-md">{{ reservation.totalDistanceOfReservationInMeters / 1000 }}</span>
        km
      </span>
      <span class="text-xs"
        ><FaIcon icon="route" fixed-width class="" /> {{ $t('global.distance') }}</span
      >
    </div>
    <div
      v-if="
        reservation.totalReservationTimeInSeconds ||
        (reservation.startDate &&
          (reservation.status !== 'end' ||
            reservation.status !== 'cancelled_reservation' ||
            reservation.status !== 'cancelled'))
      "
      class="flex flex-col justify-center items-center text-gray-500"
    >
      <span
        class="text-primary text-md"
        v-html="
          formatDuration(
            reservation.totalReservationTimeInSeconds || (now - reservation.startDate) / 1000
          )
        "
      ></span>
      <span class="text-xs"
        ><FaIcon icon="stopwatch" fixed-width class="" /> {{ $t('global.duration') }}</span
      >
    </div>
  </div>
</template>
<script>
import { formatDuration } from '@/utils/formatDate';

export default {
  inject: ['reservation', 'now'],
  methods: {
    formatDuration,
  },
};
</script>
