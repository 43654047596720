<template>
  <div class="h-full w-full bg-gray-50 flex flex-col justify-stretch">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <template #default>
        <p class="text-primary text-lg">{{ station.address }}</p>
        <p v-if="station.address2" class="text-gray-700 text-sm">
          {{ station.address2 }}
          <span class="text-xs text-gray-500 relative whitespace-nowrap" v-if="distance">
            ({{ $t('global.from-you', [distance]) }})
          </span>
        </p>
        <p v-if="station.description" class="text-gray-700 text-xs">
          {{ station.description }}
        </p>
      </template>
      <template #right>
        <div
          class="h-full grow bg-cover bg-bottom keep-square"
          :style="{
            'background-image': 'url(/assets/img/stations/' + station.id + '.jpg)',
          }"
        ></div>
      </template>
    </TopNav>
    <div class="grow shrink overflow-hidden z-0">
      <GScrollBar>
        <div class="p-4 h-full">
          <div v-for="idx in 1" :key="idx" class="bg-white rounded-xl mb-4 shadow">
            <div class="flex items-center p-4 shadow-sm">
              <div
                class="h-24 w-24 bg-no-repeat bg-contain bg-bottom drop-shadow-2xl"
                :style="{
                  'background-image': 'url(/assets/img/devices/es4.png)',
                }"
              ></div>
              <div class="ml-6">
                <p class="text-gray-500">{{ $t('scooter.electric-scooter') }}</p>
                <p class="text-primary">
                  {{ slotsCount - emptySlotsCount }}/{{ slotsCount }}
                  <span class="text-gray-400 text-sm ml-1"
                    >({{ $t('station.empty-slots-count', [emptySlotsCount]) }})</span
                  >
                </p>
              </div>
            </div>
            <div class="p-4 text-sm">
              <SlotsList :devices="station.slots" />
            </div>
          </div>
        </div>
      </GScrollBar>
    </div>
    <BottomNav class="pointer-events-auto grow-0 shrink-0 z-10" solid>
      <QrButton tooltip />
    </BottomNav>
  </div>
</template>

<script>
import TopNav from '@/components/ui/partials/TopNav';
import BottomNav from '@/components/ui/partials/BottomNav';
import QrButton from '@/components/QrButton';
import SlotsList from '@/components/SlotsList';
import gql from 'graphql-tag';
import { calcDistance } from '@/utils/calcDistance';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      test: this.$route.params.id,
      station: {},
    };
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    station: {
      query: gql`
        query scooterParkingStation2($id: Int!) {
          station: scooterParkingStation(id: $id) {
            id
            lat
            lng
            address
            address2
            description
            slots: scooterParkingSlots {
              id
              isTemporary
              scooter: currentlyDockedScooter {
                isEnabled
                flespi_id
                id
                unique_id_for_qr
                device_remaining_mileage
                currentScooterReservation {
                  id
                  user {
                    id
                  }
                }
              }
              status
            }
          }
        }
      `,
      variables() {
        return {
          id: Number(this.$route.params.id),
        };
      },
      pollInterval: 2 * 1000,
    },
  },
  computed: {
    ...mapGetters({ currentPosition: 'getPosition' }),
    distance() {
      const km = calcDistance(this.station, this.currentPosition);
      if (isNaN(km)) return false;
      const m = Math.round(km * 1000);
      return m >= 1000 ? (m / 1000).toPrecision(2) + ' km' : m + ' m';
    },
    slotsCount() {
      return !this.station.slots ? 0 : this.station.slots.length;
    },
    emptySlotsCount() {
      return !this.station.slots ? 0 : this.station.slots.filter(e => e.status === 'empty').length;
    },
  },
  components: {
    TopNav,
    BottomNav,
    QrButton,
    SlotsList,
  },
};
</script>
