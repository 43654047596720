import { t } from '@/i18n';

const statuses = {
  beginning: {
    state: 'active',
  },
  error_beginning_scooter_remaining_in_stand: {
    state: 'error',
    audio: 'error',
  },
  active: {
    state: 'active',
    audio: 'success',
    pausable: true,
    returnable: true,
  },
  reserved: {
    state: 'active',
    audio: 'success',
    pausable: false,
    returnable: false,
  },
  cancelled_reservation: {
    state: 'normal',
    audio: 'success',
  },
  paused: {
    state: 'active',
    resumable: true,
  },
  ending: {
    state: 'active',
  },
  error_ending_incorrectly_placed_scooter: {
    state: 'error',
    audio: 'error',
  },
  cancelled_with_error: {
    state: 'normal',
    audio: 'error',
  },
  end: {
    state: 'normal',
  },
};
const getState = status => {
  return statuses[status] && statuses[status].state ? statuses[status].state : status;
};
const getAudio = status => {
  return statuses[status] && statuses[status].audio ? statuses[status].audio : 'ping';
};
const isResumable = status => {
  return (statuses[status] && statuses[status].resumable) || false;
};
const isReturnable = status => {
  return (statuses[status] && statuses[status].returnable) || false;
};
const isPausable = status => {
  return (statuses[status] && statuses[status].pausable) || false;
};
const getStateName = status => {
  return t(`ride.statuses.${status}.name`) || getState(status);
};
const getStateStatusChangedName = status => {
  return t(`ride.statuses.${status}.statusChangeName`) || getStateName(status);
};
const getStateTitle = status => {
  return t(`ride.statuses.${status}.title`) || '';
};
const getStateMsg = status => {
  return t(`ride.statuses.${status}.msg`) || getStateName(status);
};
export {
  getState,
  getStateName,
  getStateTitle,
  getStateMsg,
  getAudio,
  isPausable,
  isResumable,
  isReturnable,
  getStateStatusChangedName,
};
