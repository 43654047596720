<template>
  <SimpleModal
    :open="
      accepts.accepts &&
      token &&
      !(accepts.accepts.isPrivacyPolicyValid && accepts.accepts.isTermOfServiceValid)
    "
    class="top-0 left-0"
    :closable="false"
    solid
    fixed
  >
    <div class="flex flex-col justify-center items-center p-4 text-center">
      <span class="grow-0 text-lg text-primary bg-white relative mb-2">{{ $t('tos.title') }}</span>
      <span class="block my-1 text-gray-500"
        ><input type="checkbox" v-model="accept1" class="" name="" id="accept1" />
        <label for="accept1"> {{ $t('tos.im-over-18') }} </label></span
      >
      <span class="block my-1 text-gray-500">
        <input type="checkbox" v-model="accept2" class="" name="" id="accept2" />
        <label for="accept2">
          {{ $t('tos.ive-readed-and-accept') }}
          <a class="text-primary" :href="`${apiUrl}/user/latestTermsOfService`">{{
            $t('tos.rules')
          }}</a>
          {{ $t('tos.and') }}
          <a class="text-primary" :href="`${apiUrl}/user/latestPrivacyPolicy`">{{
            $t('tos.privacy-policy')
          }}</a>
        </label>
      </span>
      <GBtn
        class="pl-6 pr-4 my-4 w-fit"
        :circle="true"
        @click="accept"
        :disabled="!(accept1 && accept2)"
        :loading="loading"
        >{{ $t('global.save') }}<FaIcon icon="caret-right" class="ml-4"
      /></GBtn>
    </div>
  </SimpleModal>
</template>
<script>
import gql from 'graphql-tag';
import SimpleModal from '@/components/ui/SimpleModal';
import { mapGetters } from 'vuex';
import { apolloClient } from '@/apollo';
import config from '@config';
export default {
  data() {
    return {
      accepts: {},
      accept1: false,
      accept2: false,
      loading: false,
    };
  },
  computed: { ...mapGetters({ token: 'getToken' }), apiUrl: () => config.api.baseUrl },
  components: {
    SimpleModal,
  },
  methods: {
    accept() {
      if (this.accept1 && this.accept2) {
        this.loading = true;
        apolloClient
          .mutate({
            mutation: gql`
              mutation {
                acceptTermsOfService
                acceptPrivacyPolicy
              }
            `,
          })
          .catch(error => {
            alert(error);
            this.loading = false;
          })
          .then(() => {
            this.$apollo.queries.accepts.refetch();
            this.loading = false;
          });
      }
    },
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    accepts: {
      query: gql`
        query {
          isPrivacyPolicyValid
          isTermOfServiceValid
        }
      `,
      pollInterval: 30 * 60 * 1000,
      update: data => {
        return { accepts: data };
      },
    },
  },
};
</script>
