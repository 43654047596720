import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import ui from './ui';
import { Storage } from '@capacitor/storage';
import { promptForNotifications } from '@/notifications';
import { startLocating } from '@/location';
import { Device } from '@capacitor/device';
import { i18n } from '@/i18n';

let storageToUse;
try {
  // storage = window.localStorage;
  storageToUse = Storage;
} catch (error) {
  console.log('localstorage not avaliable');
  // Sentry.captureException(error);
}
const vuexLocal = new VuexPersistence({
  storageToUse,
  reducer: state => ({
    access_token: state.access_token,
    refresh_token: state.refresh_token,
    userId: state.userId,
    userRole: state.userRole,
    lang: state.lang,
  }),
});

export default createStore({
  state: {
    access_token: null,
    refresh_token: null,
    userId: null,
    userRole: null,
    fcmToken: null,
    position: {},
    locationInterval: null,
    lang: null,
  },
  mutations: {
    setTokens(state, { access_token, refresh_token }) {
      state.access_token = access_token;
      state.refresh_token = refresh_token;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserRole(state, userRole) {
      state.userRole = userRole;
    },
    setPosition(state, position) {
      state.position = position;
    },
    setLocationInterval(state, interval) {
      state.locationInterval = interval;
    },
    clearTokens(state) {
      state.access_token = null;
      state.refresh_token = null;
      state.userId = null;
      state.userRole = null;
    },
    setLang(state, lang) {
      i18n.global.locale = lang;
      state.lang = i18n.global.locale;
    },
  },
  getters: {
    getToken(state) {
      return state.access_token;
    },
    getRefreshToken(state) {
      return state.refresh_token;
    },
    getPosition(state) {
      return state.position;
    },
    getUserId(state) {
      return state.userId;
    },
    getUserRole(state) {
      return state.userRole;
    },
    getLang(state) {
      return state.lang;
    },
  },
  actions: {
    async setTokens({ commit }, { access_token, refresh_token }) {
      commit('setTokens', { access_token, refresh_token });
      await promptForNotifications();
    },
    startLocating({ commit }) {
      console.log('starting locating');
      const interval = startLocating(coords => {
        commit('setPosition', coords);
      });
      commit('setLocationInterval', interval);
    },
    stopLocating({ commit, state }) {
      console.log('stoping locating');

      clearInterval(state.locationInterval);
      commit('setLocationInterval', null);
    },
    async setDeviceLang({ commit, state }) {
      if (!state.lang) {
        const lang = await Device.getLanguageCode();
        commit('setLang', lang.value);
      } else {
        commit('setLang', state.lang);
      }
    },
  },
  modules: { ui },
  plugins: [vuexLocal.plugin],
});
