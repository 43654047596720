<template>
  <div class="h-full w-full bg-gray-50 flex flex-col justify-stretch overflow-hidden">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <p class="text-primary text-lg">Użytkownicy</p>
    </TopNav>
    <GScrollBar>
      <UserOverview
        v-for="user in users.data"
        :key="user.id"
        class="p-4 my-2 border-b cursor-pointer"
        :user="user"
        @click="$router.push('/admin/users/' + user.email)"
      />
    </GScrollBar>
  </div>
</template>
<script>
import TopNav from '@/components/ui/partials/TopNav';
import gql from 'graphql-tag';
import UserOverview from '@/components/UserOverview';
export default {
  name: 'UsersList',
  data: function () {
    return {
      users: [],
    };
  },
  components: {
    TopNav,
    UserOverview,
  },

  apollo: {
    // Simple query that will update the 'hello' vue property
    users: {
      query: gql`
        query {
          users: listUsers {
            data {
              id
              email
              accountBalanceInCents
              socialAccounts {
                providerType
              }
              role
              name
            }
            count
          }
        }
      `,
      pollInterval: 3 * 1000,
    },
  },
};
</script>
