<template>
  <div
    class="h-full w-full flex flex-col justify-stretch align-stretch"
    :style="{ 'padding-top': safeArea.top, 'padding-bottom': safeArea.bottom }"
  >
    <router-link class="flex flex-col items-center" to="/wallet" @click="closeMenu()">
      <UserImage class="shadow" />
      <UserName class="text-xl mt-2 text-primary text-center" />
      <span class="text-gray-700 text-xs text-center">
        {{ $t('menu.balance') }}: <UserBalance
      /></span>
    </router-link>
    <div class="grow shrink overflow-hidden relative mt-8">
      <GScrollBar>
        <div class="min-h-full flex flex-col items-center justify-stretch relative">
          <div
            class="p-4 grid grid-cols-2 grid-rows-[repeat(auto-fit,minmax(0,min-content))minmax(0,auto)] gap-4 grow-0 -mb-8"
          ></div>
          <div class="grow flex flex-col items-center justify-between w-fit">
            <div
              class="flex flex-col w-full"
              :class="{ grow: idx === 0 }"
              v-for="(group, idx) in menuItems"
              :key="`group-${idx}`"
            >
              <template v-for="(item, idx) in group" :key="idx">
                <MenuItem :item="item" />
              </template>
            </div>
          </div>
          <div
            class="text-xs text-center text-gray-200 w-full pt-2 absolute bottom-0 left-0 translate-y-[100%]"
          >
            <span class="w-full block">v{{ info.version }} ({{ info.build }})</span>
            <span v-if="localUrl" class="w-full block">{{ localUrl }}</span>
          </div>
        </div>
      </GScrollBar>
    </div>
  </div>
</template>
<script>
import UserName from './UserName';
import UserBalance from './UserBalance';
import { mapGetters, mapMutations } from 'vuex';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import UserImage from '@/components/ui/partials/UserImage';
import MenuItem from './MenuItem';
import config from '@config';

export default {
  inject: ['userActiveReservations', 'safeArea'],
  data() {
    return {
      info: {},
      localUrl: config.dev?.localUrl,
    };
  },

  computed: {
    ...mapGetters({ userId: 'getUserId', userRole: 'getUserRole' }),
    menuItems() {
      return [
        [
          {
            icon: 'clock-rotate-left',
            text: this.$t('menu.history'),
            callback: () => {
              this.$router.push('/rides');
              this.closeMenu();
            },
          },
          {
            icon: ['far', 'credit-card'],
            text: this.$t('menu.wallet'),
            callback: () => {
              this.$router.push('/wallet');
              this.closeMenu();
            },
          },
          {
            icon: ['far', 'circle-question'],
            text: this.$t('menu.help'),
            callback: () => {
              this.$router.push('/help/center');
              this.closeMenu();
            },
          },
          {
            icon: 'terminal',
            text: this.$t('menu.administration'),
            needRole: ['superadmin', 'admin'],
            children: [
              {
                icon: 'terminal',
                text: this.$t('menu.system-logs'),
                needRole: ['superadmin', 'admin'],
                callback: () => {
                  this.$router.push('/admin/logs');
                  this.closeMenu();
                },
              },
              {
                icon: 'terminal',
                text: this.$t('menu.slots'),
                needRole: ['superadmin', 'admin'],
                callback: () => {
                  this.$router.push('/admin/slots');
                  this.closeMenu();
                },
              },
              {
                icon: 'terminal',
                text: this.$t('menu.rides'),
                needRole: ['superadmin', 'admin'],
                callback: () => {
                  this.$router.push('/admin/rides');
                  this.closeMenu();
                },
              },
              {
                icon: 'terminal',
                text: this.$t('menu.users'),
                needRole: ['superadmin', 'admin'],
                callback: () => {
                  this.$router.push('/admin/users');
                  this.closeMenu();
                },
              },
              {
                icon: 'terminal',
                text: this.$t('menu.offers'),
                needRole: ['superadmin', 'admin'],
                callback: () => {
                  this.$router.push('/admin/offers');
                  this.closeMenu();
                },
              },
            ],
          },
        ],

        [
          {
            icon: 'wrench',
            text: this.$t('menu.settings'),
            callback: () => {
              this.$router.push('/settings');
              this.closeMenu();
            },
          },
          {
            icon: 'power-off',
            alert: true,
            text: this.$t('menu.log-out'),
            callback: this.logout,
          },
        ],
      ];
    },
  },
  methods: {
    ...mapMutations(['clearTokens']),
    logout() {
      this.clearTokens();
      this.$apollo.getClient().resetStore();
      this.closeMenu();
      this.$router.push('/login');
    },
    closeMenu() {
      this.$emit('close');
    },
  },
  async mounted() {
    if (Capacitor.isNativePlatform()) {
      this.info = await App.getInfo();
    }
  },
  components: {
    UserName,
    UserBalance,
    MenuItem,
    UserImage,
  },
};
</script>
