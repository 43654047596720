<template>
  <div class="flex flex-col justify-stretch items-center h-full bg-white">
    <div
      class="grow min-h-20 w-full bg-cover bg-bottom relative after:absolute after:top-[1px] after:h-full after:w-full after:bg-gradient-to-b after:from-transparent after:to-white"
      :style="{
        'background-image': 'url(/assets/img/welcome.jpg)',
      }"
    ></div>
    <div
      :style="{
        'margin-bottom': getKbHeight + 'px',
      }"
    >
      <router-view class="grow-0 text-center -mt-2 py-4 px-4 bg-white"> </router-view>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SplashScreen } from '@capacitor/splash-screen';

export default {
  name: 'LoginWrapper',
  computed: {
    ...mapGetters({ getKbHeight: 'ui/getKbHeight' }),
  },
  mounted() {
    SplashScreen.hide();
  },
};
</script>
