<template>
  <SimpleModal
    v-if="checkEnabled && (!enabled || !isOperative || (!isNewest && !closed))"
    :open="true"
    class="z-[999]"
    :closable="isOperative && enabled"
    @close="closed = true"
    cover
  >
    <div class="flex flex-col p-4 pt-6 justify-center items-center text-center">
      <div v-if="!enabled" class="mb-4">
        <h2 class="text-bold mb-4">{{ $t('version.unavaliable') }}</h2>
        <span class="text-gray-500 text-sm">{{ $t('version.try-later') }}</span>
      </div>
      <div v-else class="">
        <h2 v-if="!isNewest" class="text-bold">{{ $t('version.new-version-avaliable') }}</h2>
        <span v-if="!isOperative" class="text-gray-500 text-sm mb-4">{{
          $t('version.update-needed')
        }}</span>
        <div v-if="!isNewest">
          <GBtn v-if="platformVersion?.url" class="px-4" circle :href="platformVersion?.message">{{
            platformVersion?.message || $t('tos.download-new-version')
          }}</GBtn>
          <span v-else class="text-gray-500 text-sm">{{
            platformVersion?.message || $t('tos.download-new-version')
          }}</span>
        </div>
      </div>
    </div>
  </SimpleModal>
</template>
<script>
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import SimpleModal from '@/components/ui/SimpleModal';
import { getBoolean, getString } from '@/remoteConfig';

import config from '@config';

export default {
  data: function () {
    return {
      versionObj: null,
      enabledObj: null,
      info: null,
      closed: false,
    };
  },
  computed: {
    checkEnabled() {
      return config.firebase?.versionCheck?.enabled;
    },
    version() {
      return this.versionObj?.value ? JSON.parse(this.versionObj?.value) : {};
    },
    platform() {
      return Capacitor.getPlatform();
    },
    platformVersion() {
      return this.platform ? this.version[this.platform] : {};
    },
    isOperative() {
      return !(
        this.platformVersion?.min_operative &&
        this.platformVersion?.min_operative > Number(this.info.build)
      );
    },
    isNewest() {
      return !(
        this.platformVersion?.newest &&
        this.platformVersion?.newest > Number(this.info ? this.info.build : 0)
      );
    },
    enabled() {
      // return this.enabledObj.value || true;
      return this.enabledObj?.value || true;
    },
  },
  methods: {
    async fetch() {
      this.versionObj = await getString({ key: 'version' });
      this.enabledObj = await getBoolean({ key: 'enabled' });
      this.info = await App.getInfo();
      console.log('this.version', this.version);
      console.log('this.enabledObj', this.enabledObj);
      console.log('this.enabled', this.enabled);
    },
  },
  components: {
    SimpleModal,
  },
  async mounted() {
    await this.fetch();
    setInterval(this.fetch, Number(config.firebase?.rcInterval || 3600) * 1000);
  },
};
</script>
