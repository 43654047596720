<template>
  <div
    :class="{ 'opacity-60': scooter.id && !scooter.isEnabled }"
    class="flex items-center justify-stretch w-full"
  >
    <div class="flex items-center grow">
      <img src="/assets/img/devices/es4.png" class="inline h-12 mr-2" alt="" />
      <div class="flex items-center">
        <div class="flex flex-col ml-2">
          <div class="text-gray-700 uppercase">
            <span
              class="w-2 h-2 rounded-full inline-block mr-1"
              :class="
                scooter.id && !scooter.isEnabled
                  ? 'bg-gray-500'
                  : scooterSlot.status === 'empty'
                  ? 'bg-green-500'
                  : scooter.device_remaining_mileage > 25 ||
                    scooter.device_remaining_mileage === null
                  ? 'bg-primary'
                  : scooter.device_remaining_mileage > 10
                  ? 'bg-yellow'
                  : scooter.device_remaining_mileage <= 10 || scooter.currentScooterReservation
                  ? 'bg-red-400'
                  : ''
              "
            ></span>
            {{
              scooter.id
                ? `#${scooter.unique_id_for_qr}`
                : scooterSlot.status === 'empty'
                ? $t('slot.empty-slot')
                : scooterSlot.status
            }}
            <small v-if="scooter.currentScooterReservation" class="text-xs align-middle"
              >{{
                scooter.currentScooterReservation.user.id === userId
                  ? `(${$t('scooter.status.reserved')})`
                  : `(${$t('scooter.status.unavaliable')})`
              }}
            </small>
            <div class="align-middle text-xs" v-if="scooter.id && !scooter.isEnabled">
              ({{ $t('global.unavaliable') }})
            </div>
            <div class="align-middle text-xs" v-if="scooterSlot && scooterSlot.isTemporary">
              ({{ $t('slot.not-docked') }})
            </div>
          </div>
          <span
            v-if="scooter.device_remaining_mileage"
            class=""
            :class="{
              'text-red-400': scooter.device_remaining_mileage <= 10,
              'text-yellow': scooter.device_remaining_mileage > 10,
              'text-primary': scooter.device_remaining_mileage > 25,
            }"
          >
            {{ $t('scooter.range') }}: {{ Math.floor(scooter.device_remaining_mileage) }} km
          </span>
        </div>
      </div>
    </div>
    <GBtn
      v-if="scooter.id"
      class="text-sm px-2 py-1 mt-2 mr-2 bg-white shadow"
      @click.stop="lookForScooter(scooter.id)"
      :disabled="manualLoading || !scooter.flespi_id"
      :loadingOverlay="manualLoading"
      ghost
    >
      <FaIcon icon="bell" />
    </GBtn>
    <GBtn
      v-if="scooter.id && !scooter.currentScooterReservation"
      class="text-sm px-2 py-1 mt-2 mr-2 bg-white shadow"
      @click.stop="reserveScooter(scooter.unique_id_for_qr)"
      :disabled="manualLoading"
      :loadingOverlay="manualLoading"
      ghost
    >
      <FaIcon icon="clock" />
    </GBtn>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import gql from 'graphql-tag';
export default {
  data() {
    return {
      manualLoading: false,
    };
  },
  props: {
    scooterSlot: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userId: 'getUserId',
    }),
    scooter() {
      return this.scooterSlot.scooter || {};
    },
  },
  methods: {
    lookForScooter(id) {
      this.manualLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($id: Int!) {
              lookForScooterUsingFlespi(scooterId: $id)
            }
          `,
          variables: {
            id: Number(id),
          },
        })
        .then(() => {
          this.manualLoading = false;
        })
        .catch(error => {
          alert(error);
          this.manualLoading = false;

          throw new Error(error);
        });
    },
    reserveScooter(qr) {
      this.manualLoading = true;
      this.$router.push({
        path: '/',
        query: {
          action: encodeURIComponent(
            JSON.stringify({
              type: 'reserve',
              qr,
            })
          ),
        },
      });
      setTimeout(() => {
        this.manualLoading = false;
      }, 1000);
    },
  },
};
</script>
