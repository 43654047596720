<template>
  <div>
    <!-- <RideOverviewContent
      :reservation="reservation"
      :hide-arrow="true"
      class="text-sm p-0 px-4 py-4"
    /> -->
    <div class="text-sm text-gray-500 bg-gray-100 px-4 py-4 w-full border-t-2 border-dashed">
      <p v-if="reservation.startingScooterParkingSlot">
        <span class="opacity-75">{{ $t('ride.rented-from') }} </span>
        <span class="">
          {{ reservation.startingScooterParkingSlot.scooterParkingStation.address }}</span
        >
      </p>
      <p v-if="reservation.endingScooterParkingSlot">
        <span class="opacity-75">{{ $t('ride.returned-to') }} </span>
        <span class="">
          {{ reservation.endingScooterParkingSlot.scooterParkingStation.address }}</span
        >
      </p>
      <!-- <p>
        <span class="opacity-75">{{ $t('ride.price-for-start') }} </span>
        PLN {{ reservation.costPerStart / 100 }}
      </p>
      <p>
        <span class="opacity-75">{{ $t('ride.price-per-minute') }} </span>
        PLN {{ reservation.costPerMinuteInCents / 100 }}
      </p>
      <p>
        <span class="opacity-75">{{ $t('ride.price-per-kilometer') }} </span>
        PLN {{ reservation.costPer1000MetersInCents / 100 }}
      </p> -->
      <p v-if="reservation.endDate">
        <span class="opacity-75">{{ $t('ride.ended-at') }}</span>
        {{ formatDate(reservation.endDate) }}
      </p>
    </div>
  </div>
</template>
<script>
import { formatDate } from '@/utils/formatDate';
// import RideOverviewContent from './RideOverview/RideOverviewContent';

export default {
  inject: ['reservation'],

  methods: {
    formatDate,
  },
  // components: { RideOverviewContent },
};
</script>
