<template>
  <div class="h-full w-full flex flex-col justify-stretch bg-gray-50">
    <TopNav class="pointer-events-auto grow-0 shrink-0" :go-back="true">
      <template #default>O Aplikacji</template>
    </TopNav>
    <div class="grow shrink overflow-hidden">
      <GScrollBar>
        <div class="flex flex-col justify-between p-4">
          <div
            class="flex justify-center items-center flex-col text-center my-2 py-12 shadow rounded-lg bg-white text-gray-500 px-4"
          >
            <span class="mb-2">Legal info: </span>
            <a href="https://www.freepik.com/vectors/personal-logo"
              >Personal logo vector created by freepik - www.freepik.com</a
            >
            <p class="text-xs whitespace-pre-wrap w-full">{{ attribution }}</p>
          </div>
        </div>
      </GScrollBar>
    </div>
  </div>
</template>
<script>
import TopNav from '@/components/ui/partials/TopNav';

export default {
  name: 'AboutPage',
  data() {
    return {
      attribution: '',
    };
  },
  methods: {
    alert(text) {
      alert(text);
    },
  },
  components: {
    TopNav,
  },
  async mounted() {
    this.attribution = await (await fetch('/attribution.txt')).text();
  },
};
</script>
