import { createRouter, createWebHistory } from 'vue-router';
import MasterView from '@/views/MasterView';
import MapPage from '@/views/MapPage';
import HelpPage from '@/views/help/HelpPage';
import StationPage from '@/views/StationPage';
import LoginWrapper from '@/views/login';
import WelcomePage from '@/views/login/WelcomePage';
import EmailLogin from '@/views/login/EmailLogin';
import SelectLogin from '@/views/login/SelectLogin';
import StationsPage from '@/views/StationsPage';
import RidesPage from '@/views/RidesPage';
import RidePage from '@/views/RidePage';
import WalletPage from '@/views/wallet';
import TransactionsPage from '@/views/wallet/TransactionsPage';
import SettingsPage from '@/views/settings';
import TestPage from '@/views/settings/TestPage';
import AboutPage from '@/views/AboutPage';
import LogsPage from '@/views/admin/LogsPage';
import SlotsPage from '@/views/admin/SlotsPage';
import UsersPage from '@/views/admin/users';
import UserPage from '@/views/admin/users/:email';
import AdminRidesPage from '@/views/admin/RidesPage';
import OffersPage from '@/views/admin/OffersPage';
import authGuard from './guards/authGuard';
import roleGuard from './guards/roleGuard';
import transitionGuard from './guards/transitionGuard';

const routes = [
  {
    path: '',
    component: MasterView,
    children: [
      {
        path: 'login',
        component: LoginWrapper,
        children: [
          {
            path: '',
            component: WelcomePage,
            meta: {
              noBack: true,
            },
          },
          {
            path: 'select',
            component: SelectLogin,
          },
          {
            path: 'email',
            component: EmailLogin,
          },
        ],
      },
      {
        path: '',
        component: MapPage,
        meta: {
          noBack: true,
          requiresAuth: true,
        },
      },
      {
        path: 'help/center',
        component: HelpPage,
      },
      {
        path: 'stations',
        component: StationsPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'station/:id',
        component: StationPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'wallet',
        component: WalletPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'wallet/transactions',
        component: TransactionsPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'rides',
        component: RidesPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'ride/:id',
        component: RidePage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings',
        component: SettingsPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings/test',
        component: TestPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: 'about',
        component: AboutPage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'admin/logs',
        component: LogsPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: 'admin/slots',
        component: SlotsPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: 'admin/rides',
        component: AdminRidesPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: 'admin/users',
        component: UsersPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: 'admin/users/:email',
        component: UserPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: 'admin/offers',
        component: OffersPage,
        meta: {
          requiresAuth: true,
          requiresRole: ['superadmin', 'admin'],
        },
      },
      {
        path: ':pathmatch(.*)*',
        name: '404',
        component: () => import('@/views/404'),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(authGuard);
router.beforeEach(roleGuard);
router.beforeEach(transitionGuard);
export default router;
